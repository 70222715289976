<template>
    <div class="teamWrapper">
        <card-content :cardContentData="cardData" class="aboutCard"></card-content>
        <div class="timelineWrapper">
            <div class="timelineWrapper-title">发展历程</div>
            <div class="timelineWrapper-content">
                <div class="timeline-list" :class="{pc:isPc,mb:!isPc}">
                    <div class="timeline" v-for="(item,i) in timeEvents" :key="i">
                        <div class="timeline-blank"></div>
                        <div class="timeline-content" :class="{firstContent:i==0}">
                            <div class="title">{{item.title}}</div>
                            <div class="content">
                                <template v-if="Array.isArray(item.content)">
                                    <span v-for="(it,ii) in item.content" :key="ii" class="span">{{it}}</span>
                                </template>
                                <div v-else>{{item.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="hounourWrapper">
            <div class="hounourWrapper-title">社会荣誉</div>
            <card-content :cardContentData="hounourData" class="hounourWrapper-content">
                <template slot="contentExtraContent" slot-scope="scope">
                    <div class="headerWrapper">
                        <img :src="scope.row.img" alt="hounourPic">
                    </div>
                </template>
            </card-content>
        </div>
        <!-- <consultative></consultative> -->
    </div>
</template>

<script>
import Card from '../components/Card.vue'
import CardContent from '../components/CardContent.vue'
import Consultative from '../components/Consultative.vue'
export default {
    components: { CardContent, Card, Consultative },
    data() {
      return {
            cardData:[
                {   
                    title:'关于有信科技',
                    content:[
                        '有信科技为客户提供全渠道数字化解决方案，以BBC商业逻辑为基础，通过打通线上线下渠道，为企业构建私域流量运营平台，实现零售一体化及全渠道消费者价值的有效管理，从而助力企业完成产业互联网升级目标。',
                        '有信提供的SaaS产品基于对商业模式的深刻洞察，并且产品交付更快，价格更优，运行更稳定，并且系统具有可弹性、可拓展的性能，希望在有信长期主义陪伴式服务下可以为企业的持续增效提供帮助。'
                    ],
                },
                {   
                    title:'有信云',
                    content:'有信云是基于aPaaS低代码平台，提供全渠道数字化解决方案，包括了全渠道链条中的营销数字化、商品数字化、订单数字化、结算数字化和客户数字化，可帮助你打通品牌、渠道商和消费者三个环节，赋能你的渠道商更好的服务消费者。',
                },
                {   
                    title:'服务理念',
                    content:'研而有信，奉行长期主义，陪伴式成长',
                },
                {   
                    title:'公司使命',
                    content:'让技术驱动产业互联',
                },
            ],
            timeEvents:[
                {   
                    title:'2020年04月',
                    content:['获金沙江创投数','千万元A轮融资'],
                },
                {   
                    title:'2020年04月',
                    content:['服务代理商超200万，月','交易流水突破1亿'],
                },
                {   
                    title:'2020年01月',
                    content:'有信云3.0上线'
                },
                {   
                    title:'2019年12月',
                    content:'合作亿级品牌逾十家'
                },
                {   
                    title:'2019年05月',
                    content:'有信云2.0上线'
                },
                {   
                    title:'2019年01月',
                    content:'与颜如玉达成战略合作',
                },
                {   
                    title:'2018年11月',
                    content:'有信云1.0上线',
                },
                {   
                    title:'2018年03月',
                    content:'公司成立',
                },
            ],
            hounourData:[
                {
                    title:'2020年12月29日',
                    content:'私域电商标杆服务商',
                    alt:'私域电商标杆服务商图',
                    img:'https://static.rongxintech.cn/oryx/tmp/20210309/16152723923169902.png',
                },
                {
                    title:'2020年12月26日',
                    content:'连锁企业优选供应商',
                    alt:'连锁企业优选供应商图',
                    img:'https://static.rongxintech.cn/oryx/tmp/20210309/16152723970105502.png',
                },
                {
                    title:'2020年10月23日',
                    content:'十大私域流量服务商',
                    alt:'十大私域流量服务商图',
                     img:'https://static.rongxintech.cn/oryx/tmp/20210309/16152724015190428.png',
                },
                {
                    title:'2020年8月25日',
                    content:['最具商业价值','数字营销公司机构奖'],
                    alt:'最具商业价值数字营销公司机构奖图',
                    img:'https://static.rongxintech.cn/oryx/tmp/20210309/16152724057201056.png',
                },
                {
                    title:'2020年8月25日',
                    content:'数字营销实战金奖',
                    alt:'数字营销实战金奖图',
                    img:'https://static.rongxintech.cn/oryx/tmp/20210309/16152724102588695.png',
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
$fontFam: "Helvetica", "Tahoma", "Arial", "PingFang-SC-Medium", "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei";
$title-mini: normal normal 16px/24px $fontFam;
$title-small: normal bold 24px/32px $fontFam;
$title-regular: normal bold 24px/33px $fontFam;
$title-large: normal bold 38px/53px $fontFam;
$content-small: normal normal 16px/32px $fontFam;
$content-regular: normal normal 18px/32px $fontFam;
$content-large: normal normal 24px/38px $fontFam;
.teamWrapper { 
    margin-left: vwDecimal(244); 
    padding-bottom: 120px;
}
.aboutCard /deep/ {
    position: relative;
    top: vwDecimal(-60);
    width: 956px;
    height: 724px;
    background: #FFFFFF;
    padding: 60px 40px;
    box-sizing: border-box;
    background-image: url('https://static.rongxintech.cn/yx/team/team-about.png');
    background-repeat: no-repeat;
    background-position: center;
    .content-item {
        & + .content-item {
            margin-top: 40px;
        }
        &:nth-child(1) .content-item_title {
            font:$title-large;
            & + .content-item_contentWrapper { margin-top: 20px; }
        }
    }
    .content-item_title {
        font:$title-small;
        & + .content-item_content {
            margin-top: 10px;
        }
    }
    .content-item_content {
        font: $content-regular;
        color: #5E6B70;
    }
}
.timelineWrapper {
    margin: vwDecimal(120) 0;
    background-image: url('https://static.rongxintech.cn/oryx/tmp/20210309/16152780222876767.png');
    background-repeat: no-repeat;
    background-position: right bottom;
}
.timelineWrapper-title , .hounourWrapper-title{
    font: $title-large;
    color: #1A2C33;
    & + .timelineWrapper-content, & + .hounourWrapper-content {
        margin-top: vwDecimal(40);
    }
}
.timelineWrapper-content {
    position: relative;
    height: auto;
    background-color: transparent;
    padding: vwDecimal(54) 0 vwDecimal(24) 0 ;
    .timeline-list {
        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            height: 100%;
            width: 2px;
            background: rgba(226, 231, 233, 1);
            top: vwDecimal(40);
            display:block;
            bottom: 40px;
        }
    }
    .timeline {
        display: flex;
        align-items: flex-start;
        .title {
            font: $title-regular ;
            color: #00A1E1;
            white-space: nowrap;
        }
        .content {
            font: $content-regular;
            padding-top: vwDecimal(10);
            padding-bottom: vwDecimal(20);
            color: #1A2C33;
            white-space: nowrap;
        }
        .timeline-blank { width: 50%; }
        .timeline-content {
            width: 50%;
            padding-left: vwDecimal(30);
            box-sizing: border-box;
            position: relative;
            &::after {
                content: '';
                display: block;
                position: absolute;
                left: -14px;
                top: 0;
                width: 28px;
                height: 24px;
                background-image: url('https://static.rongxintech.cn/oryx/tmp/20210309/16152727695641718.png');
                background-repeat: no-repeat;
                z-index: 100;
                background-position-x: -30px;
            }
        }
        &:nth-child(odd) {
            flex-direction: row-reverse;
            text-align: right;
            .timeline-content {
                padding-right: vwDecimal(30);
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: unset;
                    right: -14px;
                    top: 0;
                    width: 28px;
                    height: 24px;
                    background-image: url('https://static.rongxintech.cn/oryx/tmp/20210309/16152727695641718.png');
                    background-repeat: no-repeat;
                    background-position-x: -30px;
                    z-index: 100;
                }
                &.firstContent {
                    &::after {
                        background-position-x: 0;
                    }
                }
            }
        }
    }
    .timeline-list.pc {
        width: 600px;
        &::after {
            left: 300px;
            right: unset;
        }
    }
}
.hounourWrapper-content /deep/ {
    .content {
        display: grid;
        grid-template-columns: repeat(3, vwDecimal(216));
        grid-template-rows: auto;
        grid-column-gap: vwDecimal(134);
        grid-row-gap: vwDecimal(40);
        place-items: center;
        box-sizing: border-box;
        .content-item {
            width: 216px;
            display: flex;
            flex-direction: column;
            // background-color: #fff;
        }
        .content-item_title {
            font: $title-mini;
            color:#5E6B70;
            text-align: center;
            & + .content-item_content {
                margin-top: 5px;
            }
            &::before {
                content: '';
                display: block;
                width: 70px;
                height: 2px;
                background: #00A1E1;
                border-radius: 1px;
                margin: 20px auto;
            }
        }
        .content-item_content {
            text-align: center;
            font: $content-large;
            color: #333333;
        }
        .headerWrapper {
            width: auto;
            height: 95px;
            order: -1;
            img {
                height: 87px;
                object-fit: cover;
                display: block;
                margin: 0 auto;
            }
        }
    }
}


@media screen and (max-width: 414px) {
$titleVW:px2Mb(38);
$subTitleVW:px2Mb(28);
$contentVW:px2Mb(24);

$titleVH:px2Mb(57);
$subTitleVH:px2Mb(40);
$contentVH:px2Mb(38);
// $fontFam: "Helvetica", "Tahoma", "Arial", "PingFang-SC-Medium", "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei";
$Color:(
    header-title:#1A2C33,
    header-content:#5E6B70,
    content-title: #1A2C33,
    content-content:#5E6B70,
);
$Font: (
    header-title: normal bold #{$titleVW}/#{$titleVH} $fontFam,
    header-content: normal normal #{$contentVW}/#{$contentVH} $fontFam,
    content-title: normal bold #{$subTitleVW}/#{$subTitleVH} $fontFam,
    content-content: normal normal #{$contentVW}/#{$contentVH} $fontFam,
);

$titleFont-1: normal normal #{px2Mb(38)}/#{px2Mb(57)} $fontFam;
$titleFont-2: normal bold #{px2Mb(28)}/#{px2Mb(40)} $fontFam;
$titleFont-3: normal normal #{px2Mb(16)}/#{px2Mb(24)} $fontFam;
$contentFont: normal normal #{px2Mb(24)}/#{px2Mb(38)} $fontFam;
.teamWrapper {
    margin-left: 0;
    padding: px2Mb(60) px2Mb(30);
}
.aboutCard /deep/ {
    position: relative;
    top: 0;
    width: 100%;
    height: px2Mb(1000);
    background: #FFFFFF;
    padding: px2Mb(40);
    box-sizing: border-box;
    background-image: unset;
    background-repeat: no-repeat;
    background-position: center;
    .content-item {
        & + .content-item {
            margin-top: px2Mb(40);
        }
        &:nth-child(1) .content-item_title {
            font: $titleFont-1;
            & + .content-item_contentWrapper { margin-top: px2Mb(20); }
        }
    }
    .content-item_title {
        font: $titleFont-2;
        // $titleFont-1
        color: #1A2C33;
        & + .content-item_content {
            margin-top: px2Mb(10);
        }
    }
    .content-item_content {
        font: $contentFont;
        color: #5E6B70;
    }
}
.timelineWrapper {
    margin: px2Mb(120) 0;
    background-image: none;
}
.timelineWrapper-title {
    font-size: px2Mb(38);
    line-height: px2Mb(53);
    & + .timelineWrapper-content {
        margin-top: px2Mb(40);
    }
}
.timelineWrapper-content {
    position: relative;
    overflow: visible;
    height: auto;
    background-color: unset;
    padding: 0 ;
    top:px2Mb(20);
    background-image: unset;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        height: 100%;
        width: px2Mb(5);
        background: rgba(226, 231, 233, 1);
        top: 0;
        display:block;
    }
    .timeline-list::after { display: none; }
    .timeline {
        // #E2E7E9
        &:nth-child(odd) {
            flex-direction: row-reverse;
            text-align: right;
            .timeline-content {
                padding-right: px2Mb(28);
                border-width: 0;
            }
        }
        &:nth-child(even) .timeline-content {
            border-width: 0;
        }
        .title {
            white-space: nowrap;
            font-size: px2Mb(36);
            font-size: px2Mb(50);
        }
        .content {
            font-size: px2Mb(24);
            line-height: px2Mb(33);
            padding: px2Mb(20) 0;
            white-space: unset;
            display: flex;
            flex-direction: column;
        }
        .timeline-content {
            width: 50%;
            padding-left: px2Mb(28);
            box-sizing: border-box;
            position: relative;
        }
    }
}
.hounourWrapper-content /deep/ {
    .content {
        display: grid;
        grid-template-columns: repeat(2, px2Mb(216));
        grid-template-rows: auto;
        grid-column-gap: px2Mb(134);
        grid-row-gap: px2Mb(60);
        place-items: center;
        box-sizing: border-box;
        .content-item {
            width: px2Mb(216);
            display: flex;
            flex-direction: column;
            // background-color: #fff;
        }
        .content-item_title {
            font: $titleFont-3;
            color:#5E6B70;
            text-align: center;
            & + .content-item_content {
                margin-top: 5px;
            }
            &::before {
                content: '';
                display: block;
                width: px2Mb(70);
                height: px2Mb(2);
                background: #00A1E1;
                border-radius: 1px;
                margin: 20px auto;
            }
        }
        .content-item_content {
            text-align: center;
            font: $contentFont;
            color: #333333;
        }
        .headerWrapper {
            width: px2Mb(216);
            height: px2Mb(240);
            order: -1;
            img {
                width: px2Mb(216);
                height: px2Mb(240);
                object-fit: contain;
                display: block;
                margin: 0 auto;
            }
        }
    }
}
}
</style>