<template>
    <div class="contentWrapper">
        <div class="bgTop">
            <div class="mask" :class="{divider}" :style="{backgroundColor:mask||'unset'}">
                <div class="dataListWrapper" :class="{divider}" v-if="Array.isArray(maskData) && isPc">
                    <div class="dataList" v-for="(d,i) in maskData" :key="i">{{d}}</div>
                </div>
                <div class="dataList" :class="{divider}" v-if="!Array.isArray(maskData) && isPc">{{maskData}}</div>
                <div class="dataList mb" v-if="!isPc && maskMbData">{{maskMbData}}</div>
                <div class="dataList subTitle mb" v-if="!isPc">{{Array.isArray(maskData) ? maskData.join('') : maskData}}</div>
            </div>
            <img :src="img" alt="" class="bg">
        </div>
    </div>
</template>

<script>
export default {
    name:'contentFrame',
    props:{
        maskData:[Array,String],
        maskMbData:String,
        divider:{
            type:Boolean,
            default:false,
        },
        mask:String,
        img:{
            type:String,
            default:'https://static.rongxintech.cn/oryx/tmp/20210223/16140710377475246.jpg'
        },
    },
    created(){
        console.log(this.maskData,'maskData');
    }
}
</script>

<style lang="scss" scoped>
$marginLeftWidth: calc(50vw - #{vwDecimal(360)});
$fontFam: "Helvetica", "Tahoma", "Arial", "PingFang-SC-Medium", "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei";
$Color:(
    title:#1A2C33,
    relate: #ffffff,
    content:#00A1E1,
    question:#808080,
);
$titleVW:vwDecimal(24);
$contentVW:vwDecimal(16);
$titleVH:vwDecimal(33);
$contentVH:vwDecimal(22);
$Font: (
    title: normal normal #{$titleVW}/#{$titleVH} $fontFam,
    relate: normal normal #{$contentVW}/#{$contentVH} $fontFam,
    content: normal normal #{$contentVW}/#{$contentVH} $fontFam,
    question: normal normal #{$contentVW}/#{$contentVH} $fontFam,
);
@each $key, $value in $Font {
    .navBar-#{$key} {
        font: $value;
        color:map-get($map: $Color, $key: $key);
        @if ($key != 'title' and $key != 'question') {
            cursor: pointer;
        }
    }
}
.contentWrapper {
    width: 100vw;
    position: relative;
    top: 0;
    right: 0;
    left: 50%;
    transform: translateX(-50vw);
    .bgTop {
        height: vwDecimal(450);
        position: relative;
        img {
            height: vwDecimal(450);
            object-fit: cover;
            z-index: -20;
            width: 100%;
        }
        .mask {
            width: 100%;
            position: absolute;
            bottom: 0;
            display: flex;
            align-items: center;
            .dataListWrapper,   .dataList {
                display: flex;
                flex-direction: column;
                font-size: vwDecimal(36);
                font-weight: 500;
                color: #FFFFFF;
                line-height: vwDecimal(64);
            }
            .dataListWrapper, .dataList {
                margin: vwDecimal(20) 0 vwDecimal(20) $marginLeftWidth;
                .dataList { margin: 0; }
            }
            .divider {
                border-left: vwDecimal(8) solid #ffffff;
                padding-left: vwDecimal(40);
            }
            &.divider {
                top: vwDecimal(100);
                bottom: unset;
            }
        }
    }
}
@media screen and (max-width: 414px) {
    .contentWrapper {
        .bgTop {
            height: px2Mb(375);
            img {
                height: px2Mb(375);
                object-fit: cover;
            }
            .mask {
                align-items: flex-start;
                flex-direction: column;
                .dataListWrapper,  .dataList {
                    font-size: px2Mb(36);
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: px2Mb(64);
                    margin: px2Mb(20) 0 px2Mb(20) px2Mb(30);
                }
                .dataList.subTitle {
                    font-size: px2Mb(24);
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: px2Mb(33);
                    margin-top: px2Mb(-20);
                    padding-right: px2Mb(30);
                }
                .divider {
                    border-left: 0;
                    padding-left: 0;
                }
                &.divider {
                    top: unset;
                    bottom: 0;
                }
            }
        }
    }
}
</style>