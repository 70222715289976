<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    components: {
    },
    data() {
      return {
      }
    },
    computed: {
    },
    created() {
    },
    mounted() {
    },
    methods: {
    },
    watch: {
    }
}
</script>


<style lang="scss">
@import url("./assets/css/yx-base.css");
@import url("./assets/css/yx-icon.css");
@import '../node_modules/element-ui/lib/theme-chalk/index.css'; 
@import '../node_modules/swiper/dist/css/swiper.css';
@import './static/main.scss';
</style>
