import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import { Loading } from 'element-ui';
import {
	Form,
	FormItem,
	Input,
	Radio,
	RadioGroup,
} from 'element-ui'

import utils from './utils'
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.config.productionTip = false

import {ajax} from './utils/ajax'
Vue.prototype.$ajax = ajax

Vue.mixin({
	data() {
		return {
			isPc: utils.isPc,
			isAndroid: utils.isAndroid()
		}
	},
	methods: {
		goPage(url) {
			if (!url) return;
			if (url.indexOf('http') === 0) {
				window.open(url)
			} else {
				this.$router.push(url);
			}
		}
	}
})

new Vue({
	router,
	store,
	render: function (h) { return h(App) }
}).$mount('#app')


// 调整加loading
var loading, 
	hasLoadHash = {};
if (router.currentRoute.name) {
	hasLoadHash[router.currentRoute.name] = true;
}
router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	if (!hasLoadHash[to.name]) {
		loading = Loading.service()
	}
	next()
})
router.afterEach((to) => {
	hasLoadHash[to.name] = true;
	loading && loading.close()
})
router.onError(() => {
	loading && loading.close()
})