<template>
    <div id="app" class="app" :class="{bgWhite}">
        <div class="wrapper">
          <Header></Header>
          <div class="wrapper_inner" :class="{overflow: !isRouterAlive}">
            <router-view name="navBar" key="navBar"></router-view>
            <router-view v-if="isRouterAlive" key="content"></router-view>
            <router-view name="content" key="contentFrame"></router-view>
          </div>
          <Footer :related="related"></Footer>
          <div class="contactFloat mb" @click="goPage('/consultative')">
            <img src="https://static.rongxintech.cn/oryx/tmp/20210309/16152835931825152.png" alt="">
          </div>
          <div class="companyWechat pc">
            <img src="https://dev-rx.oss-cn-hangzhou.aliyuncs.com/test/tmp/20210426/16194205022947694.png" alt="">
            <div class="text">在线咨询</div>
          </div>
        </div>
    </div>
</template>

<script>
import Top from '../containers/Top'
import Bottom from '../containers/Bottom'
import Advisory from './Advisory'              // 固定的咨询栏
import Header from '../containers/Header'
import Footer from '../containers/Footer.vue'
import NavBar from './NavBar.vue'
export default {
    components: {
        // Top,
        // Bottom,
        Header,
		    // Advisory,
        Footer,
        // NavBar
    },
    data() {
      return {
        isRouterAlive: false,
        related:true,
        bgWhite:false,
      }
    },
    computed: {
    },
    created() {
      console.log(this.$route.path);
      this.related = this.$route.path !== '/consultative'
      this.bgWhite = this.$route.path === '/'
      let cls = []
      if (this.isPc) {
        cls.push('pc1')
      } else {
        cls.push('mobile1')
        cls.push(this.isAndroid ? 'android1' : 'ios1')
      }
      let htmlElement = document.documentElement;
      ['pc1', 'mobile1', 'ios1', 'android1'].map(v => {
        htmlElement.classList.remove(v)
      })
      cls.map(v => {
        htmlElement.classList.add(v)
      })
    },
    mounted() {
      this.isRouterAlive = true;
    },
    methods: {
      reload() {
        this.isRouterAlive = false;
        this.$nextTick(function() {
          this.isRouterAlive = true;
        });
      },
    },
    watch: {
      $route: function(to, from) {
        this.related = to.path !== '/consultative'
        this.bgWhite = to.path === '/'
      }
    }
}
</script>


<style lang="scss">

.app {
  // position: relative;
  background: #F0F2F5;
  .wrapper {
    
    .mb {
      display: none;
    }
    .companyWechat.pc {
      width: 84px;
      display: block;
      position: fixed;
      top: 50%;
      right: 20px;
      text-align: center;
      color: #0046AD;
      font-size: 14px;
      line-height: 24px;
    }
  }
  &.bgWhite {
    background-color: #fff;
  }
  .wrapper_inner {
    width: 1200px;background: #F0F2F5;position: relative;
    margin: 0 auto;
    &.overflow {overflow: hidden;}
  }
}
@media screen and (max-width: 414px) {
  .app .wrapper {
    .wrapper_inner {width: unset;}
    .mb {
      display: block;
    }
    .companyWechat.pc {
      display: none;
    }
    .contactFloat.mb {
      display: block;
      bottom: 20px;
      position: fixed;
      right: 20px;
       img {
        width: px2Mb(138);
        height: px2Mb(138);
      }
    }
  }
  
}
// .mobile {
// }
// .ios {
// }
// .android {
// }
</style>
