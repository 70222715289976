<template>
    <div class="pageWrapper healthWrapper">
        <div class="module" v-for="(item,i) in moduleData" :key="i">
            <div class="title" v-if="Array.isArray(item.title)">
                <span class="titleItem" v-for="(it,i) in item.title" :key="i">
                    {{it}}
                </span>
            </div>
            <div class="title" v-else>{{item.title}}</div>
            <div class="content">
                <card-content :cardContentData="item.children" v-if="item.type == 'card'"></card-content>
                <div class="imgContent" v-if="item.type == 'img'">
                    <img :src="item.img" alt="">
                </div>
            </div>
        </div>
        <solution-expert :content="solutionExpertContent"></solution-expert>
    </div>
</template>

<script>
import CardContent from '../../../components/CardContent.vue'
import SolutionExpert from '../../../components/SolutionExpert.vue'
export default {
    name:'Health',
    components: { CardContent, SolutionExpert },
    data() {
        return {
            solutionExpertContent:'如果您的商业正处于数字化转型阶段，尽快享受数字化带来的商业机会吧，我们将帮您实现商业持续增长。联系我们深入了解大健康行业的客制化解决方案',
            moduleData:[
                {title:'终端数据、消费渠道、售后服务、供应链',
                children:[
                {
                    title:'终端数据不可控  ',
                    content:'终端数据无法汇总、获取并分析，无法掌握市场动态',
                },          {
                    title:'消费者渠道变化  ',
                    content:'新零售带来的消费方式变化，多渠道竞争',
                },          {
                    title:'售后服务不便  ',
                    content:'大健康行业消费者对产品的了解及使用需要便捷的售后支持',
                },          {
                    title:'供应链灵活性差  ',
                    content:'供应链无法及时响应外界市场的需求变化，做出及时调整',
                },
                ],
                type:'card',},
                {title:'场景升级、渠道变革及服务能力提升是竞争聚焦点',
                children:[],
                img: 'https://static.rongxintech.cn/yx/solution/health-pic1.png',
                type:'img',},
                {title:'以解决您的商业问题为目的，反向输出客制化解决方案',
                children:[
                    {
                        title:'医疗大数据平台  ',
                        content:'健康生命周期管理，会员信息管理，数据精准粉细',
                    },          {
                        title:'客户关系管理  ',
                        content:'客户对接数据管理，销售行为管理，费用管理等',
                    },          {
                        title:'采购解决方案  ',
                        content:'供应商资源整合，系统化，增加市场应变能力，多层级降低成本',
                    },          {
                        title:'全渠道解决方案  ',
                        content:'打通多层级渠道，形成由供应链到消费者的多方数据闭环',
                    },
                ],
                type:'card',}
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.healthWrapper .module {
    .imgContent img {
        width: vwDecimal(953);
        height: vwDecimal(1012)!important;
        object-fit: contain;
    }
    .content .card-content /deep/ .content {
        display: grid;
        grid-template-rows: vwDecimal(85) vwDecimal(117);
        .content-item_content {
            white-space: unset;
        }
    }
    &:nth-child(3) /deep/ {
        .card-content .content {
            display: grid;
            grid-row-gap:0;
            grid-template-columns: unset;
            grid-template-rows: repeat(4, auto);
            .content-item_content {
                white-space: unset;
            }
        }
    }
}
@media screen and (max-width: 414px) {
    .healthWrapper .module .imgContent {
        height: unset;
        img {
        width: px2Mb(690);
        height: px2Mb(800)!important;
        object-fit: contain;}
    }
}

</style>