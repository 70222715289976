import axios from 'axios';
import { Message } from 'element-ui';
import Defer from './defer.js';


function getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
}

axios.defaults.headers.post['Content-Type'] = 'application/json';

function ajax(url, jsonParam, obj) {
    var CancelToken = axios.CancelToken;
    var source = CancelToken.source();
    var isCancle = false
    var defer = new Defer()

    

    var option = {};
    obj = obj || {};

    let opt = {
        headers: {}
    }

    if (obj.flag === 'help') {
        // 有信帮助中心
        if (getQueryString('test')) {
            option.url = 'http://oryx-cgi.test1.rongxin.tech' + url
            opt.headers['x-platform'] = 'TESTMALL'
        } else {
            option.url = 'https://api.youxin.cloud' + url
            opt.headers['x-host'] = 'yx4.mall.youxin.cloud'
        }   
    } else {
        option.url = process.env.NODE_ENV === 'production' ? url : ('https://www.youxin.cloud' +  url)
    }

    option.type = obj.type || "get";
    jsonParam = jsonParam || {};

    // let opt = {
    //     url: option.url,
    //     method : option.type,
    // };
    opt.url = option.url
    opt.method = option.type

    if (opt.method === "get") {
        opt.params = jsonParam;
    } else {
        opt.data = JSON.stringify(jsonParam);
    }

    opt.cancelToken = source.token

    let success = function(res) {
        if (res.status === 200 && res.data.code === 0) {
            defer.resolve(res.data)
        } else {
            !obj.noAlert && Message({message: res.data.msg || "网络错误" ,type:"error"})
            defer.reject();
        }
    }
    let err = function (e) {
        !isCancle && !obj.noAlert && Message({message: "网络错误" ,type:"error"})
        defer.reject();
    };

    axios(opt).then(success).catch(err);


    defer.abort = () => {
        isCancle = true
        source.cancel('cancel')
    }
    return defer

}

export {ajax}
