<template>
    <div class="article_list bg-white flex-1">
        <!-- 文章标题 -->
        <div class="art_title_area" v-for="(item, index) in articleList" :key="index" @click="clickArticle(item)">
            <span class="title">{{item.article.title}}</span>
            <div class="art_date_area d-flex">
                <span class="flex-1">{{item.created_at}}</span>
                <div class="eye_area d-flex a-center">
                    <img class="eye" src="https://static.rongxintech.cn/oryx/tmp/20210323/16164818612981714.png">
                    <span class="number">{{item.watch_num}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            nowAjax: null
        }
    },
    mounted() {
        const id = this.$route.query.id
        if (id !== 'article') {
            this.getData(this.$route.params.id)
        }
    },
    computed: {
        ...mapState({
            articleList: state => state.article.articleList
        })
    },
    watch: {
        $route: {
            handler(newVal) {
                this.getData(newVal.params.id)
            }
        }
    },
    methods: {
        getData(tagId) {
            if (this.nowAjax) {
                this.nowAjax.abort()
            }
            const param = {
                tab_id: tagId,
                page: '1',
                num: '500'
            }
            this.nowAjax = this.$ajax('/api/moment/college/list', param, { flag: 'help' }).done(res => {
                this.$store.commit('setArticleList', res.data)
                // 根据 文章对应的 tagId 找到对应的分类信息
                this.$store.commit('setCurrClassifyInfo', { where: 'Article', currArticleTagId: tagId })
            })
        },
        clickArticle(item) {
            this.$store.commit('setCurrClassifyInfo', { where: 'ArticleList', currArticleTagId: item.tab_id })
            // 判断是否有外链
            if (item.article.url !== '') {
                item.watch_num ++
                this.$ajax('/api/moment/college/read', { moment_id: item.id }, { flag: 'help', type: 'post' })
                window.open(item.article.url)
                return
            }
            // 跳转到 文章内容页面
            this.$router.push({
                path: `/helpCenter/article/${item.id}`
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .article_list { padding: 32px 32px 32px 61px; }
    .art_title_area {
        cursor: pointer;
        .title { height: 33px; font-size: 24px; font-weight: 400; color: #333333; line-height: 33px; }
        .title:hover { color: #007EFF; cursor: pointer; }
        .art_date_area {
            margin-top: 12px; color: #666666; font-weight: 400; height: 20px; line-height: 20px; font-size: 14px;
            .eye_area {
                .eye { width: 16px; height: 18px; }
                .number { margin-left: 6px; }
            }
        }
    }
    .art_title_area:not(:first-child) {
        margin-top: 12px;
    }
    .art_title_area:not(:last-child) {
        border-bottom: 1px solid #EBEEF5; padding-bottom: 16px;
    }
</style>