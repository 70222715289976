import { render, staticRenderFns } from "./Advisory.vue?vue&type=template&id=68a1d592&scoped=true&"
import script from "./Advisory.vue?vue&type=script&lang=js&"
export * from "./Advisory.vue?vue&type=script&lang=js&"
import style0 from "./Advisory.vue?vue&type=style&index=0&id=68a1d592&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68a1d592",
  null
  
)

export default component.exports