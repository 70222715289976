<template>
    <div class="customWrapper">
        <div class="page-title mb">客户案例</div>
        <div class="cardList">
            <card v-for="(item,i) in moduleData" :key="i" :titleImg="item.img" :footerLink="footerLink">
                <card-content :cardContentData="item.content">
                    <div slot="title">
                        <p class="title-small">{{item.title}}</p>
                        <p class="title-normal">{{item.subTitle}}</p>
                    </div>
                </card-content>
                <div slot="footer"></div>
            </card>
        </div>
        <!-- <div class="learnMore pc" @click="goPage('/consultative')">了解更多案例 ></div> -->
        <solution-expert :title="solutionExpertTitle" :content="solutionExpertContent"></solution-expert>
    </div>
</template>

<script>
import Card from '../components/Card.vue'
import CardContent from '../components/CardContent.vue'
import SolutionExpert from '../components/SolutionExpert.vue'
export default {
    components:{Card, CardContent, SolutionExpert},
    data() {
        return {
            footerLink:"查看案例 >",
            solutionExpertTitle:['更多行业案例，', '我们的解决方案专家将为您分享'],
            solutionExpertContent:'如果您的商业正处于数字化转型阶段，尽快享受数字化带来的商业机会吧，我们将帮您实现商业持续增长。联系我们深入了解更多行业案例的客制化解决方案。',
            moduleData:[
                {
                    title:'三草两木（SASELOMO）',
                    subTitle:'全渠道业务中台，实现全渠道消费者运营',
                    content:'三草两木是一个年销售额数十亿的护肤品牌，其销售渠道多样，包括零售、公域流量渠道、私域流量渠道、经销渠道和社交渠道。实现全渠道数字化，建立消费者唯一ID，提升消费体验，这是品牌构筑坚实壁垒、实现持续领先的关键。',
                    img: 'https://static.rongxintech.cn/yx/custom/custom-sancao.png',
                },
                {
                    title:'美浮特（MEIFUTE）',
                    subTitle:'订单数字化融合多渠道降本增效',
                    content:'美浮特专注于健康皮肤管理，目前拥有零售渠道、经销渠道、分销渠道，产品的经营模式包括BBC和BC两种模式，通过订单数字化、商品数字化和营销数字化解决方案，帮助美浮特打通多渠道，降低50%的物流成本，分权设置提升渠道商和消费者体验。',
                    img: 'https://static.rongxintech.cn/yx/custom/custom-meifute.png',
                },
                {
                    title:'格物（21DAYS）',
                    subTitle:'沉淀3W私域流量，管理10万+门店',
                    content:'格物定位于大健康领域创新发展，着力于产业链的研发和营销两大核心环节，致力于成为中国益生菌品牌号召者。通过零售渠道数字化解决方案，提升总部对门店管理能力的同时，打通线上线下渠道，实现智能结算，智能营销。',
                    img: 'https://static.rongxintech.cn/yx/custom/custom-gewu.png',
                },
                {
                    title:'颜如玉（YOROYAL）',
                    subTitle:'赋能千万代理提高转化率',
                    content:'颜如玉与多家先进科研机构长期保持战略合作关系，积极开发高新技术健康产品。借助有信探客宝提升代理商的裂变转化率，借助有信云播增加互动场景与提高沟通效率，借助经销系统实现数量庞大的渠道商管理。',
                    img: 'https://static.rongxintech.cn/yx/custom/custom-yanruyu.png',
                },
                {
                    title:'中消云（NOFIRE CLOUD）',
                    subTitle:'渠道数字化升级，变革B2B关系，从博弈到共赢',
                    content:'中消云是一家基于物联网、云计算、大数据的智慧城市安全应急服务项目投资建设与运营的企业。通过与有信科技的合作，打造自身的渠道数字化系统，实现对渠道的管理、运营、营销赋能，变革品牌方与渠道的博弈关系为共生共赢。',
                    img: 'https://static.rongxintech.cn/yx/custom/custom-zhongxiaoyun.png',
                },
                {
                    title:'好想你（HAOXIANGNI）',
                    subTitle:'零售行业突围社交新零售，实现私域流量池建设',
                    content:'好想你主要从事红枣、坚果、果干等健康食品的研发、采购、生产和销售。公司希望打造一县一品一店的商业模式，不同地域的门店会售卖当地的特色农产品。通过有信社交商城，不但实现线上线下购买体验升级，还成为重要的蓄客拓新触点。',
                    img: 'https://static.rongxintech.cn/yx/custom/custom-haoxiangni.png',
                },
                {
                    title:'蝴蝶猫（BUTTERFLY CAT）',
                    subTitle:'数据复用，3天实现20万会员平移导入',
                    content:'蝴蝶猫一个独立的电子商务平台，为了顺应新零售市场发展的主流趋势，公司开启第二产业版块，推出全新社交电商品牌蝴蝶猫。有信通过打造蝴蝶猫BBC社交商城，助力蝴蝶猫通过数据复用实现消费者RFM的有效管理。',
                    img: 'https://static.rongxintech.cn/yx/custom/custom-hudiemao.png',
                },
                {
                    title:'福客满（FUKEMAN）',
                    subTitle:'数字化赋能，业务提效，业绩增效',
                    content:'福客满是一个倡导健康品质生活的社交新零售平台，联手国内各大优质供应链，以满足每一个消费者向往美好健康生活的新时代需要为愿景。通过有信云的渠道数字化系统更换原来的系统，使用客制化营销工具有效管理渠道、激活会员，实现会员数据高度复用。',
                    img: 'https://static.rongxintech.cn/yx/custom/custom-fuke.png',
                },
                {
                    title:'呦氧（U.YOUNG）',
                    subTitle:'经销云仓数字化商城联合动销',
                    content:'呦氧致力于为大众提供更高性价比的天然健康品质生活，引领国产品牌的口碑先锋。借助客制化商品数字化、订单数字化系统赋能渠道商，提高客户体验；借助有信云播多渠道引流，直播赋能代理，探客有道高效裂变，IP打造增加消费者黏性。',
                    img: 'https://static.rongxintech.cn/yx/custom/custom-youyang.png',
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.customWrapper {
    margin-left: vwDecimal(244);
    padding: vwDecimal(60) 0;
    .mb {
        display: none;
    }
    .module {
        margin-top: vwDecimal(312);
    }
    .learnMore {
        font-size: vwDecimal(24);
        font-weight: 500;
        color: #1A2C33;
        line-height: vwDecimal(33);
        margin-top: vwDecimal(60);
        margin-left: vwDecimal(789);
        cursor: pointer;
    }
    .cardList {
        display: grid;
        grid-template-columns: repeat(3, vwDecimal(300));
        grid-template-rows: repeat(3, vwDecimal(833));
        grid-column-gap: vwDecimal(28);
        grid-row-gap: vwDecimal(60);
    }
    .card {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        /deep/ .card-header {
            width: vwDecimal(300);
            height: vwDecimal(150);
            .titleImg {
                width: 100%;
                object-fit: cover;
                height: vwDecimal(150);
            }
        }
        /deep/ .card-title {
            width: vwDecimal(300);
                height: vwDecimal(150);
                object-fit: cover;
            .titleImg {
                width: vwDecimal(300);
                height: vwDecimal(150);
                object-fit: cover;
            }
        }
        /deep/ .card-content {
            padding: vwDecimal(40) vwDecimal(20) 0 vwDecimal(20);
            flex: 1;
            .title2 {
                .title-small {
                    font-size: vwDecimal(12);
                    font-weight: 400;
                    color: #00A1E1;
                    line-height: vwDecimal(17);
                    & + .title-normal {
                        margin-top: vwDecimal(10);
                    }
                }
                .title-normal {
                    font-size: vwDecimal(18);
                    font-weight: 500;
                    color: #1A2C33;
                    line-height: vwDecimal(25);
                }
                &::after {
                    content:'';
                    display: block;
                    width: vwDecimal(120);
                    height: 1px;
                    background-color: #D3D3D3;
                    margin: vwDecimal(40) 0;
                }
            }
            .content-item_content {
                font-size: vwDecimal(16);
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #5E6B70;
                line-height: vwDecimal(32);
            }
        }
        /deep/ .card-footer {
            padding: vwDecimal(40) vwDecimal(20);
            font-size: vwDecimal(16);
            line-height: vwDecimal(22);
            color: #00A1E1;
            font-weight: 400;
        }
    }
}
@media screen and (max-width: 414px) {
    @mixin brandName {
        font-size: px2Mb(20);
        font-weight: 400;
        color: #00A1E1;
        line-height: px2Mb(28);
    }
    @mixin title {
        font-size: px2Mb(28);
        color: #1A2C33;
        font-weight: bold;
        line-height: px2Mb(40);
    }
    @mixin content {
        font-size: px2Mb(24);
        font-weight: 400;
        color: #1A2C33;
        line-height: px2Mb(38);
    }
    @mixin footerLink {
        font-size: px2Mb(24);
        font-weight: 400;
        color: #00A1E1;
        line-height: px2Mb(38);
    }

    .customWrapper {
        margin-left: 0;
        position: relative;
        top:0;
        padding: px2Mb(60) px2Mb(30);
        .pc {
            display: none;
        }
        .mb {
            display: block;
        }
        .page-title {
            font-size: px2Mb(38);
            font-weight: 400;
            color: #1A2C33;
            line-height: px2Mb(57);
            margin-bottom: px2Mb(40);
        }
        .cardList {
            grid-template-columns: unset;
            grid-template-rows: unset;
            grid-column-gap: unset;
            grid-row-gap: px2Mb(60);
        }
        .card {
            background-color: #fff;
            display: flex;
            flex-direction: column;
            /deep/ .card-title {
                width: unset;
                height: px2Mb(230);
                .titleImg {
                    width: 100%;
                    height: px2Mb(230);
                    object-fit: cover;
                }
            }
            /deep/ .card-content {
                padding: px2Mb(40);
                flex: 1;
                .title2 {
                    .title-small {
                        @include brandName;
                        & + .title-normal {
                            margin-top: px2Mb(10);
                        }
                    }
                    .title-normal {
                        @include title;
                    }
                    &::after {
                        display: none;
                    }
                    & + .content {
                        margin-top: px2Mb(40);
                    }
                }
                .content-item_content {
                    @include content;
                }
            }
            /deep/ .card-footer {
                @include footerLink;
                padding-right: px2Mb(40);
                padding-bottom: px2Mb(40);
            }
        }
    }
}

</style>