<template>
    <div class="article bg-white">
        <!-- 导航横条 -->
        <div class="nav">
            <div v-if="currClassifyInfo.oneTagName && article.title">
                <span class="button-skip" @click="clickClassify('one')">{{currClassifyInfo.oneTagName}}</span>
                <span class="px-5">/</span>
            </div>
            <div v-if="currClassifyInfo.twoTagName">
                <span class="button-skip" @click="clickClassify('two')">{{currClassifyInfo.twoTagName}}</span>
                <span class="px-5">/</span>
            </div>
            <span class="on" v-if="article.title">{{article.title}}</span>
        </div>

        <div class="article-area" v-if="Object.keys(article).length > 0">
            <!-- 文章标题 -->
            <div class="art-title-area">
                <span class="art-title">{{article.title}}</span>
                <div class="d-flex title-date-area mt-12">
                    <span>{{article.createdAt | deteHandle}}</span>
                    <div class="eye-area d-flex">
                        <img class="eye" src="https://static.rongxintech.cn/oryx/tmp/20210323/16164818612981714.png">
                        <span class="number">{{article.nread}}</span>
                    </div>
                </div>
            </div>

            <!-- 分割线 -->
            <div class="dividing-line"></div>

            <!-- 正文区（富文本） -->
            <div v-html="article.body" class="article-body"></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            article: {}
        }
    },
    filters: {
        deteHandle(dateStr) {
            dateStr = '' + dateStr
            let arrs = dateStr.split(" ")
            return arrs[0]
        }
    },
    created() {
        this.loadData()
    },
    computed: {
        ...mapState({
            currClassifyInfo: state => state.classify.currClassifyInfo,
            classifyInfo: state => state.classify.classifyInfo
        })
    },
    methods: {
        loadData() {
            // 加载文章信息
            const id = this.$route.params.id
            this.$ajax('/api/moment/college/detail', {
                id
            }, { flag: 'help' }).done(res => {
                this.article = res.data
                this.article.nread ++
                // 根据 文章对应的 tagId 找到对应的分类信息
                this.$store.commit('setCurrClassifyInfo', { where: 'Article', currArticleTagId: this.article.tabId })
                // 更新查看数
                this.$ajax('/api/moment/college/read', { moment_id: id }, { flag: 'help', type: 'post' })
            })
        },
        clickClassify(layer) {
            let tagId = layer === 'one' ? this.currClassifyInfo.firstId : this.currClassifyInfo.twoTagId
            this.$router.push({
                path: `/helpCenter/articleList/${tagId}`
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .article {
        padding: 32px 32px 32px 61px;
        .nav {
            color: #909399; font-size: 12px; font-weight: 400; height: 18px; line-height: 18px;
            .on { color: #606266; }
        }
    }
    .art-title-area {
        margin-top: 24px;
        .art-title {
            width: 256px; height: 45px; font-size: 32px; font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500; color: #333333; line-height: 45px;
        }
        .title-date-area {
            color: #666666; font-size: 14px;
            .eye-area {
                margin-left: 26px;
                .eye { width: 16px; height: 18px; }
                .number { margin-left: 6px; }
            }
        }
    }
    .dividing-line { margin-top: 24px; margin-bottom: 23px; border-top: 1px solid #EBEEF5; }
    .article-body {
        /deep/ img {
            max-width: 100%;
        }
    }
    ::-webkit-scrollbar {
        display: none;
        width: 0 !important;
        height: 0 !important;
        -webkit-appearance: none;
        background: transparent;
    }
</style>