<template>
    <div class="card-content">
        <div class="title1" :class="{divider}" v-if="title">{{ title }}</div>
        <div class="title2" :class="{divider}"  v-if="$slots.title">
            <slot name="title"></slot>
        </div>
        <div class="content">
            <div class="content-item_content" v-if="!Array.isArray(cardContentData)"> {{ cardContentData }} </div>
            <div class="content-item" v-for="(item, idx) in cardContentData" :key="idx" v-else>
                <div class="content-item_title" v-if="item.title" @click="item.route && goPage(item.route)"> {{item.title}}</div>
                <div class="content-item_content" :class="{btn}" v-if="item.content && !Array.isArray(item.content)" @click="item.route && goPage(item.route + (item.hash || ''))"> {{item.content}} </div>
                <div class="content-item_contentWrapper" v-if="item.content && Array.isArray(item.content)">
                    <div class="content-item_content" :class="{btn}" v-for="(it,i) in item.content" :key="i">{{it}}</div>
                </div>
                <div class="childrernWrapper">
                    <div class="content-item_children" :class="{'double-line': header && btn && it.content.length == 2 }" v-for="(it,i) in item.children" :key="i" @click="it.route && goPage(it.route)">
                        <template v-if="it && typeof (it) == 'object'">
                            <span class="content-item_children_title row" v-if="it.title && it.row"> {{it.title}} </span>
                            <div class="content-item_children_title" v-if="it.title && !it.row"> {{it.title}} </div>
                            <span class="content-item_children_content" :class="{btn:btn, active:isActive(it)}" @click="it.route && goPage(it.route+(it.hash||''))"> {{it.content}} </span>
                        </template>
                        <div class="content-item_children_title" v-else> {{it}} </div>
                    </div>
                </div>
                <slot name="contentExtraContent" :row="item"></slot>
            </div>
            <slot name="contentExtra"></slot>
        </div>
        <slot name="contentExtraMb"></slot>
    </div>
</template>

<script>
const reg = new RegExp( /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/ )
export default {
    name:'cardContent',
    props:{
        title: String,
        cardContentData: [Array,String],
        divider: {
            type: Boolean,
            default: false
        },
        header: {
            type: Boolean,
            default: false
        },
        'divider-direction': {
            type: String,
            default: 'center',
            validator(val) {
                return ['center','start','all'].indexOf(val) > -1
            }
        },
        // todo 左对齐 右对齐 顶部对齐
        'label-position': {
            type: String,
            default: 'right',
            validator(val) {
                return ['left','right','top'].indexOf(val) > -1
            }
        },
        stripe:{
            type: Boolean,
            default: false
        },
        marker:{
            type: String,
            default: 'circle',
            validator(val) {
                return ['circle','decimal','disc'].indexOf(val) > -1
            }
        },
        bgTop:{
            type: Boolean,
            default: false
        },
        bgImg:String,
        btn:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            reg
            // cardContentData:[],
        }
    },
    methods:{
        isActive(li) {
            var now = this.$route.path + this.$route.hash;
            if (li.route === now) {
                return true
            }
        },
        // goPage(v){
        //     console.log(v,'v');
        //     this.$router.push(v);
        // }
    },
    created(){
        // todo 背景颜色，背景图片
        // this.cardContentData = [
        //     {
        //         title:'大数据赋能：',
        //         content:'用户大数据，实现数据资产沉淀与管理',
        //         format:'text',
        //     },
        //     {
        //         title:'AI赋能：',
        //         content: '结合人工智能，实现客户精细化管理',
        //         format:'text',
        //     },
        //     {
        //         title: '动销赋能：',
        //         content: '数字技术赋能，实现最低成本转换',
        //         format:'text',
        //     }
        // ]
        // this.title = '解决方案'
        // this.cardContentData = [
        //     {
        //         content:'大数据赋能',
        //         format:'text',
        //     },
        //     {
        //         content: '线上线下多渠道',
        //         format:'text',
        //     },
        //     {
        //         content: '实现了不同渠道商品的云仓合并发货',
        //         format:'text',
        //     }
        // ]
    }
}
</script>


<style lang="scss" scope>
.btn {
    cursor: pointer;
}
</style>