<template>
    <div class="top" @click="closeNav">
        <div class="box">
            <div class="main">
                <div class="logo_box">
                    <div class="logo">
                        <router-link to="/index">
                            <img class="default" src="https://pro-rx.oss-cn-hangzhou.aliyuncs.com/oryx/tmp/20191015/1571124250211.png" alt="">
                            <img class="widthbg" src="https://pro-rx.oss-cn-hangzhou.aliyuncs.com/oryx/tmp/20191015/1571124406894.png" alt="">
                        </router-link>
                    </div>
                </div>
                <ul class="nav" :class="{show:showNav}">
                    <li v-for="(li, i) in nav" :key="i" class="nav_li" :class="{showChild:li.showChild}" @click.stop="goPageNav(li)">
                        <a :class="{ active : isActive(li) }">
                            <img :src="li.logo" v-if="!isPc && li.logo" class="navlogo">
                            <span>{{li.name}}</span>
                            <span v-if="li.child" class="icon_caret"></span>
                        </a>
                        <ul class="subnav" v-if="li.child">
                            <li class="subnav_li"  @click.stop="goPageNav(it)" :class="{ active : isActive(it) }" v-for="(it, j) in li.child" :key="j">
                                <a >
                                    <div class="title">{{it.title}}</div>
                                    <div class="intro">{{it.intro}}</div>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul class="shortcut_entry">
                    <li>
                        <button class="entry m_btn small ghost" @click="goPage('https://admin.youxin.cloud')">
                            商家登录
                        </button>
                    </li>
                    <!-- <li>
                        <button class="entry m_btn small ghost" @click="goPage('/building?page=way')">
                            渠道中心
                        </button>
                    </li> -->
                </ul>
                <!-- <div class="mobile_menu" :class="{on:showNav}" @click.stop="toggleNav"><div class="icon"></div></div> -->
                <!-- <div class="mb_munu"></div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            // logo: 'https://pro-rx.oss-cn-hangzhou.aliyuncs.com/tanke/logo.png', 
            showNav: false,
            nav: [
                {
                    url: '/',
                    name: '首页',
                    showChild: false,
                    child: null,
                    logo: 'https://pro-rx.oss-cn-hangzhou.aliyuncs.com/oryx/tmp/20191023/1571818010304.png?x-oss-process=image/resize,w_750,h_750/quality,q_80'
                },
                {
                    url: '/solution',
                    name: '解决方案',
                    showChild: false,
                    child: null
                },
                {
                    url: '',
                    name: '产品',
                    showChild: false,
                    child: [
                        {
                            title: '有信探客宝',
                            intro: '一款全员社交营销小程序',
                            url: '/takepal'
                        },
                        {
                            title: '经销管理系统',
                            intro: '人货钱新零售管理系统',
                            url: '/manage'
                        },
                        {
                            title: '社交商城平台系统',
                            intro: '每个品牌都有自己的私域流量',
                            url: '/mall'
                        }
                    ]
                },
                {
                    url: '/case',
                    name: '案例',
                    showChild: false,
                    child: null
                },
                {
                    url: '/team',
                    name: '关于有信',
                    showChild: false,
                    child: null
                    // },
                    // {
                    //     url: '/x3',
                    //     name: '动态',
                    //     child: null
                }
            ]
        }
    },
    methods: {
        isActive(li) {
            var now = this.$route.path;
            if (li.url === '/') {
                return li.url === now;
            }
            if (!li.child) {
                return now.indexOf(li.url) === 0
            } else {
                return !!li.child.filter(v => now.indexOf(v.url) === 0)[0]
            }
        },
        goPageNav(item){
            if (item.url) {
                this.showNav = false;
                this.nav.forEach(v => {
                    v.showChild = false;
                })
                this.goPage(item.url);
            } else {
                item.showChild = !item.showChild;
            }
        },
        // go(url,par){
        //     if(par === "shop"){
        //         this.$router.push({ path: '/building', query: { page: par }});
        //     }
        // },
        toggleNav(){
            this.showNav = !this.showNav;
        },
        closeNav(){
            this.showNav = false;
        }
    }
}
</script>

<style lang="scss">
$orangeTheme:#e3660c;
$blueTheme:#11A2FF;

    .top {
        position: fixed;
        top:0;
        right:0;
        left:0;
        width: 100%;
        height: 64px;
        box-shadow: 0 3px 6px rgba($color: #000, $alpha: 0.16);
        z-index: 11;
        .navlogo {width:16px;height: 16px; margin-right: 4px; vertical-align: middle;}
        &.bg {
            background-color: #fff;
            .default {
                display: none;
            }
            .widthbg {
                display: inline-block;
            }

            .icon_caret {
                border-color: #1a1a1a;
            }

            a {
                cursor: pointer;
                color: #1a1a1a;
                &.active {
                    color: $blueTheme;
                }

                &:hover {
                    color: $blueTheme;
                }
            }
            .entry {
                border: 1px solid #006bff;
                color: #006bff;
            }

            .subnav {
                background-color: #fff;
            }
            
            .subnav_li {
                &:hover, &.active {
                    background-color: #E7F5FF;
                }
            }
            .title {
                color: #333;
            }

            .intro {
                color: #999;
            }
        }

        .box {
            width: 1100px;
            height: 100%;
            margin: 0 auto;
        }

        .main {
            display: flex;
            height: 100%;
            padding: 0 10px;
            justify-content: space-between;
            align-items: center;
        }
        .logo_box {
            flex: 1;
        }
        .logo {
            text-align: left;
            img {
                width: 190px;
                height: 40px;
            }
        }
        .default {
            display: inline-block;
        }
        .widthbg {
            display: none;
        }
    }
    .nav {
        display: flex;
        padding: 0 30px;
        font-size: 16px;
        .nav_li {
            line-height: 64px;
            margin: 0 30px;
            &:hover {
                .icon_caret {
                    transform: rotate(225deg);
                    margin-bottom: -4px;
                }
                .subnav {
                    // box-shadow: inset 0 4px 4px 0px #d4d4d4;
                    height: 117px;
                    // display: none;
                }
            }
        }

        a {
            cursor: pointer;
            color: rgba(#fff, 0.6);
            // &:hover{
            //     color: #fff;
            // }
            &.active {
                color: #fff;
				font-size: 18px;
				font-weight: 500;
            }
            &:hover {
                color: #fff;
            }
            span  {vertical-align: middle;}
        }
        .icon_caret {
            display: inline-block;
            width: 6px;
            height: 6px;
            margin-left: 6px;
            border-right: 1.5px solid rgba(#fff, 0.6);
            border-bottom: 1.5px solid rgba(#fff, 0.6);
            transform: rotate(45deg);
            transition: all ease-in 300ms;
            vertical-align: 4px;
        }
        .subnav {
            position: absolute;
            top: 64px;
            right: 0;
            left: 0;
            display: flex;
            width: 100%;
            background-color: transparent;
            justify-content: center;
            line-height: 1.7;
            height: 0;
            overflow: hidden;
            transition-duration: 500ms;
        }

        .subnav_li {
            width: 240px;
            font-size: 14px;
            text-align: left;
            color: #000;
            margin: 20px 0;
            &:hover, &.active {
                background-color: rgba(#fff, 0.1);
            }
            a {display: block;padding: 1px 20px;}
            ~ .subnav_li {
                margin-left: 70px;
            }
        }

        .title {
            margin-top: 10px;
            font-size: 20px;
            color: #fff;
        }

        .intro {
            margin-bottom: 10px;
            font-size: 14px;
            color: rgba(#fff, 0.6);
        }
    }
    .shortcut_entry {
        display: flex;
        color: #fff;

        li {
            ~ li {
                margin-left: 10px;
            }
        }
        a {
            color: inherit;
        }
        .entry {
            width: 86px;
            border-radius: 4px;
            border-color: rgba(#fff, 0.6);
            font-size: 14px;
            color: inherit;
        }
    }
    .mobile_menu {
        position: absolute;right: 0;top:0;display: none;line-height: 64px;width: 64px;
        .icon, .icon:before, .icon:after {    
            height: 2px; background-color: #999;border-radius: 2px;position: absolute; 
        }
        .icon:before {content:"";top:-10px;left:0px;right: 0; }
        .icon {top:32px;left: 20px; right:20px;transition: .25s ease-in-out;}
        .icon:after {content:"";top:10px;left:0px;right: 0; }
        &.on {
            .icon {transform: rotate(-90deg);}
        }
    }
</style>
