<template>
    <div class="pageWrapper makeUpWrapper">
        <div class="module" v-for="(item,i) in moduleData" :key="i">
            <div class="title" v-if="Array.isArray(item.title)">
                <span class="titleItem" v-for="(it,i) in item.title" :key="i">
                    {{it}}
                </span>
            </div>
            <div class="title" v-else>{{item.title}}</div>
            <div class="content">
                <card-content :cardContentData="item.children" v-if="item.type == 'card'"></card-content>
                <div class="imgContent" v-if="item.type == 'img'">
                    <img :src="item.img" alt="">
                </div>
            </div>
        </div>
        <solution-expert :content="solutionExpertContent"></solution-expert>
    </div>
</template>

<script>
import CardContent from '../../../components/CardContent.vue'
import SolutionExpert from '../../../components/SolutionExpert.vue'
export default {
  components: { CardContent, SolutionExpert },
    name:'MakeUp',
    data() {
        return {
            solutionExpertContent:'如果您的商业正处于数字化转型阶段，尽快享受数字化带来的商业机会吧，我们将帮您实现商业持续增长。联系我们深入了解美妆行业的客制化解决方案',
            moduleData:[
                {title:['经营遇到瓶颈，','美妆行业发展面临四大难题'],
                children:[
                    {title:'获客难', 
                    content:'媒介投放成本高，流量少且贵，商品销售难',},   
                    {title:'复购难', 
                    content:'产品同质化严重、缺乏竞争力，复购率低，复购周期长',}, 
                    {title:'留客难', 
                    content:'客户营销困难，种子客户积累难，信任度和忠诚度低',},   
                    {title:'运营难', 
                    content:'商业模式老化，数据分析不足，对用户群体洞察欠缺',}
                ],
                type:'card'},
                {title:'私域电商成为增长新引擎',
                children:[],
                type:'img',
                img: 'https://static.rongxintech.cn/yx/solution/makeUp-pic1.png',
                },
                {title:'以解决您的商业问题为目的，反向输出客制化解决方案',
                children:[
                    {title:'流量获取', 
                content:'“内容+传播+引流”三位一体，全方位运营全渠道消费者',}, 
                {title:'私域流量运营', 
                content:'会员社群运营，撬动活跃指数；消费者生命周期管理，纵向拓宽个体消费域',},   
                {title:'大数据驱动', 
                content:'商业智能，大数据优化商业决策力',}
                ],
                type:'card'}
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
</style>