<template>
    <div class="g-header-container">
        <div class="g-header-content-container">
            <div class="logo" @click="goPage('/')">
                <img src="https://static.rongxintech.cn/oryx/tmp/20210309/16152566009696694.png" alt=""  class="pc">
                <img src="https://static.rongxintech.cn/oryx/tmp/20210309/16152620340606325.png" alt=""  class="mb">
            </div>
            <div class="navBar">
                <div class="navItem" v-for="(item,idx) in navData" :class="{ active : isActive(item) }" :key="idx" @click="item.route && goPage(item.route)">
                    <button class="navItem-title" :class="{noChild:!item.children.length}">
                        {{item.title}}
                        <div class="go" v-if="item.children.length"></div>
                    </button>
                    <card-content btn header :cardContentData="item.children" v-if="item.children.length" class="navItem-children"></card-content>
                </div>
            </div>
            <div class="advisory pc">
                <div class="contact">
                    <div class="phone">咨询热线：400-003-1009</div>
                    <!-- <div class="divide">|</div>
                    <div class="related" @click="goPage('/consultative')">联系我们</div> -->
                </div>
                <div class="trial">
                    <button class="btn" @click="goPage('/consultative')">马上体验</button>
                </div>
            </div>
            <!-- <div class="advisory mb">
                <div class="trial">
                    <button class="btn" @click="handleMbGo">马上体验</button>
                </div>
            </div> -->
            <div class="nav-icon" @click="handleShowMenu(show)"><span class="nav-icon-line"></span></div>
            <div class="navBar mb" :class="{show}" @click="show=false">
                <div class="navBarList">
                    <template v-for="(item,index) in navData" > 
                        <ul :key="index" class="navBarRoute"> 
                            <li class="navBar-title level1-title"  :class="{ active : isActive(item) }" @click.stop="handleRoute(item)" v-if="index != 0">
                                <div>{{item.title}}</div>
                                <div class="go"></div>
                            </li>
                            <template v-if="curItem==item.id || parseInt(curItem/10)==item.id">
                                <ul v-for="(subItem,i) in item.children" :key="i" class="bg"> 
                                    <li class="navBar-title level2-title" @click.stop="handleRoute(subItem)">
                                            <div >{{subItem.title}}</div>
                                            <div class="go"></div>
                                    </li> 
                                    <template v-if="curItem==subItem.id">
                                        <ul v-for="(sub,ii) in subItem.children" :key="ii" >
                                            <router-link tag="li" class="navBar-title level3-title" :class="{ active : isActive(sub) }" v-if="!reg.test(sub.route) && sub.hash" :to="sub.route+sub.hash">
                                                <div >{{sub.content}}</div>
                                                <div class="go"></div>
                                            </router-link>
                                            <li class="navBar-title level3-title" :class="{ active : isActive(sub) }" @click="handleRoute(sub)" v-else>
                                                <div >{{sub.content}}</div>
                                                <div class="go"></div>
                                            </li> 
                                        </ul>
                                    </template>
                                </ul>
                            </template>
                        </ul> 
                    </template> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardContent from '../components/CardContent.vue'
const reg = new RegExp( /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/ )
export default {
    components: { CardContent },
    data(){
        return {
            reg,
            curItem: 2,
            show:false,
            navData:[
                {
                    id:1,
                    title:'首页',
                    route:'/',
                    children:[],
                },
                {   id:2,
                    title:'产品',route:'',
                    children:[
                        {
                            id:21,
                            title:'概述',
                            route:'',
                            children:[
                                {   content:'什么是云计算？',
                                    route:'https://zhuanlan.zhihu.com/p/354477430',
                                },
                                {   content:'全渠道是什么？',
                                    route:'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA',
                                },
                                {   content:'什么是客制化？',
                                    route:'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ',
                                },
                            ]
                        },
                        {   
                            id:22,
                            title:'按全渠道场景分',
                            route:'',
                            children:[
                                {   content:'X·OCD-全渠道营销',route:'/product/frame2/marketing'    },
                                {   content:'X·OCD-全渠道商品',route:'/product/frame2/goods'    },
                                {   content:'X·OCD-全渠道订单',route:'/product/frame2/order'    },
                                {   content:'X·OCD-全渠道结算',route:'/product/frame2/settleAccount'    },
                                {   content:'X·OCD-全渠道客户',route:'/product/frame2/custom'    },
                            ],
                        },
                        {   
                            id:23,
                            title:'按渠道性质分',
                            route:'',
                            children: [
                                {  content:'X·OCD-经销渠道',route:'/product/frame1/selldistribute' },
                                {  content:'X·OCD-门店渠道',route:'/product/frame1/retail'   },
                                {  content:'X·OCD-社交渠道',route:'/product/frame1/distribution' },
                                {  content:'X·OCD-公域私域',route:'/product/frame1/fields'    },
                            ],
                        },
                    ]
                },
                {   id:3,
                    title:'行业',route:'',
                    children:[
                        {
                            id:31,
                            title:'按行业',
                            route:'',
                            children:[
                                {   content:'美妆行业解决方案',
                                    route:'/solution/industry/makeUp',
                                },
                                {   content:'日化行业解决方案',
                                    route:'/solution/industry/dailyUse',
                                },
                                {   content:'大健康行业解决方案',
                                    route:'/solution/industry/health',
                                },
                                {   content:'酒水行业解决方案',
                                    route:'/solution/industry/alcohol',
                                },
                            ]
                        },
                        {
                            id:32,
                            title:'按渠道',
                            route:'',
                            children:[
                                {   content:'单渠道解决方案',
                                    route:'/solution/channel/single',
                                },
                                {   content:'多渠道解决方案',
                                    route:'/solution/channel/multiple',
                                },
                                {   content:'全渠道解决方案',
                                    route:'/solution/channel/all',
                                },
                            ]
                        },
                        {
                            id:33,
                            title:'按商业应用场景',
                            route:'',
                            children:[
                                {   content:'直播',
                                    route:'/solution/scene/scene#live',
                                    // hash:'#live',
                                },
                                {   content:'物流',
                                    route:'/solution/scene/scene#logistics',
                                    // hash:'#logistics'
                                },
                                {   content:'营销',
                                    route:'/solution/scene/scene#marketing',
                                    // hash:'#marketing',
                                },
                                {   content:'支付',
                                    route:'/solution/scene/scene#pay',
                                    // hash:'#pay',
                                },
                                {   content:'客服',
                                    route:'/solution/scene/scene#customerService',
                                    // hash:'#customerService'
                                },
                                {   content:'社群',
                                    route:'/solution/scene/scene#association',
                                    // hash:'#association',
                                },
                                {   content:'税务',
                                    route:'/solution/scene/scene#taxation',
                                    // hash:'#taxation'
                                },
                                {   content:'仓库',
                                    route:'/solution/scene/scene#storehouse',
                                    // hash:'#storehouse'
                                },
                                {   content:'发票',
                                    route:'/solution/scene/scene#bill',
                                    // hash:'#bill'
                                },
                                {   content:'追踪',
                                    route:'/solution/scene/scene#origin',
                                    // hash:'#origin',
                                },
                            ]
                        }
                    ]
                },
                {   id:4,
                    title:'服务与支持',
                    route:'',
                    children:[
                        {
                            id:41,
                            title:'有信理念',
                            children:[
                                {   
                                    content:'客制化解决方案',
                                    route:'/service#custom',
                                    // hash:'#custom'  
                                },
                                {   
                                    content:'长期主义服务方式',
                                    route:'/service#longterm',
                                    // hash:'#longterm'    
                                },
                            ],
                        },
                        {
                            id:42,
                            title:'客户成功',
                            route:'',
                            children:[
                                    {   content:'客户成功规划',route:'/service#schematization',hash:''    },
                            ],
                        },
                        {
                            id:43,
                            title:'资源',
                            route:'',
                            children:[
                                {   content:'信·资讯（月刊下载）',route:'/consultative', },
                            ],
                        }
                    ]
                },
                {   
                    id:5,
                    title:'客户',
                    route:'/custom',
                    children:[]
                },
                {   
                    id:6,
                    title:'新闻动态',
                    route:'/news',
                    children:[]
                },
                {   id:7,
                    title:'关于我们',
                    route:'',
                    children:[
                        {
                            id:71,
                            title:'关于有信',
                            route:'/us',
                            children:[
                                {   content:'关于我们',route:'/team',    },
                                {   content:'有信团队',route:'/us',    },
                                {   content:'加入我们',route:'https://www.lagou.com/gongsi/474323.html',   },
                            ],
                        }

                    ]
                },
            ]
        }
    },
    watch:{
        show(newV){
            newV && document.body.classList.add('overflow-hidden');
            !newV && document.body.classList.remove('overflow-hidden');
        }
    },
    methods:{
        isActive(li) {
            var now = this.$route.path + this.$route.hash;
            if (li.route === now) {
                return true
            }
            if (li.children) {
                return li.children.some(v => {
                    if ( v.route === now ) {
                        return true
                    }
                    if (v.children) {
                        return v.children.some(k => {
                            return k.route == now
                        })
                    }
                })
            }
            // if (li.children) {
            //     return now.indexOf(li.route) === 0
            // } else {
            //     return !!li.children.filter(v => now.indexOf(v.route) === 0)[0]
            // }
        },
        handleRoute(v){
            // id,route
            if(this.curItem == v.id) {
                if(v.id > 10){
                    this.curItem = parseInt(this.curItem/10)
                } else{
                    this.curItem = -1
                }
                return
            }
            // if (id < 10) {
            //     this.curItem = id * 10 + 1
            // } else {
            //     this.curItem = id
            // }
            this.curItem = v.id
            if (v.children) {
                !v.children.length && this.goPage(v.route)
                if (!v.children.length && v.route) {
                    this.goPage(v.route)
                    this.show = false
                }
            } else {
                this.goPage(v.route)
                this.show = false
            }
        },
        handleMbGo(){
            this.goPage('/consultative')
            this.show = false
        },
        handleShowMenu(v) {
            this.show = !v
            if (this.show) {
                document.body.classList.add('overflow-hidden');
            } else {
                document.body.classList.remove('overflow-hidden');
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.g-header-container {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #fff;
    border-bottom: 3px solid #00a1e1;
    box-sizing: border-box;
}
.g-header-content-container {
    display: flex;
    width: vwDecimal(1200);
    height: vwDecimal(80);
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    .logo {
        width: vwDecimal(187);
        height: vwDecimal(47);
        cursor: pointer;
        img {
            width: vwDecimal(187);
            height: vwDecimal(47);
            object-fit: contain;
        }
        & + .navBar {
            margin-left: vwDecimal(47);
        }
    }
    .navBar {
        display: flex; justify-content: space-between; 
        align-self: flex-end;
        .navItem {
            position: relative; 
            padding-bottom: 20px;
            & + .navItem {
                padding-left: 30px;
            }
            .navItem-children { position: absolute; display: none; }
            .navItem-title {
                padding-bottom: 5px;
                border-bottom: 3px solid transparent;
                font-size: vwDecimal(18);
                line-height: vwDecimal(25);
                font-weight: 400; color: #00A1E1; 
                white-space: nowrap;
                display: flex; background: #fff;
                align-items: center;
                position: relative;
            }
            .go {
                width: 7px;
                height: 7px;
                border-top: 2px solid #00A1E1; 
                border-right: 2px solid #00A1E1; 
                transform: rotate(135deg);
                margin-left: 5px;
            }
            &.active {
                .navItem-title {
                    border-bottom-color: #00A1E1; 
                }
            }
            &:hover {
                .navItem-title.noChild { 
                    border-bottom-color: #00A1E1; 
                }
                &:nth-child(3) .navItem-children {
                    .content .content-item:last-child {
                        width: vwDecimal(126);
                        max-width: vwDecimal(152);
                        .content-item_children {
                            display: grid; grid-template-columns: 1fr 1fr;
                            grid-column-gap: vwDecimal(10);
                            .content-item_content {
                                white-space: nowrap;
                                & + .content-item_content {
                                    margin-top: vwDecimal(0);
                                }
                            }
                        }
                    }
                }
                .navItem-children {
                    top: 53px;
                    left: 20px;
                    display: block; position: absolute; background-color: #EDEDED; border: 10px solid #EDEDED; z-index: 101;
                    & /deep/ .content {
                        // display: flex; background: #ffffff;
                        padding: vwDecimal(30) 0 vwDecimal(30) vwDecimal(20);
                        display: flex; background: #ffffff; position: relative;
                        &::before {
                            content:'';
                            display: block;
                            position: absolute;
                            top: -20px;
                            left: 10px;
                            font-size: 0;
                            line-height: 0;
                            border-width: 10px;
                            border-color: #EDEDED;
                            border-top-width: 0;
                            border-style: dashed;
                            border-bottom-style: solid;
                            border-left-color: transparent;
                            border-right-color: transparent;
                        }

                        .content-item {
                            margin-right: vwDecimal(80);
                            .content-item_title {
                                font-size: vwDecimal(18);
                                font-weight: 500;
                                color: #1A2C33;
                                white-space: nowrap;
                                & + .content-item_children {
                                    margin-top: vwDecimal(20);
                                } 
                                & + .childrernWrapper {
                                    margin-top: vwDecimal(20);
                                }
                            }
                            .content-item_children {
                                & + .content-item_children {
                                    margin-top: vwDecimal(10);
                                }
                                &.double-line {
                                    display: inline-block; vertical-align: top; width: 48%;
                                    margin-top: 0;
                                    margin-bottom: vwDecimal(10);
                                }
                            }
                            .content-item_content , .content-item_children_content{
                                font-size: vwDecimal(16);
                                font-weight: 400;
                                color: #9a9a9a;
                                white-space: nowrap;
                                &:hover {
                                    color: #00A1E1;
                                    font-weight: 500;
                                }
                                &.active { font-weight: bold; }
                            }
                        }
                    }
                }
            }
            
        }
    }
    .advisory {
        display: flex;
        flex-direction: column;
        .contact {
            display: flex;
            justify-content: flex-end;
            font-weight: 400;
            color: #333333;
            white-space: nowrap;
            margin-bottom: vwDecimal(5);
            // margin-top: vwDecimal(20);
            font-size: vwDecimal(14);
            line-height: vwDecimal(20);
            .divide {
                margin: 0 px2Mb(20);
            }
            .related {
                color: #00A1E1;
                cursor: pointer;
            }
        }
        .trial {
            display: flex;
            justify-content: flex-end;
            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                // width: vwDecimal(124);
                // height: vwDecimal(42);
                width: 96px;
                height: 30px;
                border-radius: vwDecimal(4);
                font-size: vwDecimal(14);
                line-height: vwDecimal(22);
                font-weight: 400;
                color: #FFFFFF;
                background-color: #6EB04D;
            }
        }
    }
    .mb { display: none; }
}


.pc {
    .g-header-container {
        position:sticky;
        width: auto;
        height: vwDecimal(80);
        border-bottom-width: 0;
        transform: none;
        background-color: transparent;
        &::before, &::after {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            content: ' ';
            width: 100vw;
            pointer-events: none;
            z-index: 0;
        }
        &::before { top: 0; left: 50%; transform: translateX(-50%); background-color: #fff; }
        &::after { left: 50%; transform: translateX(-50%); height: 0; border-bottom: 3px solid #00a1e1; }
    }
    .g-header-content-container { position: relative; z-index: 101; }
}


@media screen and (max-width: 414px) {
    .g-header-container {
        position: sticky;
        left: 0;
        transform: unset;
        background-color: #00A1E1;
    }
    .g-header-content-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: px2Mb(100);
        padding: px2Mb(15) px2Mb(30);
        box-sizing: border-box;
        width: unset;
        margin-left: 0;
        .mb {
            display: block;
        }
        .pc {
            display: none;
        }
        .logo {
            width: px2Mb(222);
            height: px2Mb(56);
            img.mb{
                width: px2Mb(222);
            height: px2Mb(56);
                object-fit: cover;
            }
            // background-size: auto 100%;
            // background-image: url('https://static.rongxin.tech/test/tmp/20210304/16148717818631666.png');
        }
        .navBar {
            display: none;
        }
        .pc {
            display: none;
        }
        .advisory.mb {
            display: block;
            .trial {
                border-radius: px2Mb(6);
                border: px2Mb(1) solid #00A1E1;
                .btn {
                    width: px2Mb(208);
                    height: px2Mb(60);
                    font-size: px2Mb(28);
                    font-weight: 400;
                    color: #00A1E1;
                    line-height: px2Mb(40);
                    background-color: #fff;
                }
            }
        }
        .navBar.mb {
            display: none;
        }
        .navBar.mb.show {
            display: block; position: fixed; top: px2Mb(98); left: 0; bottom: 0; right: 0; z-index: 20; width: 100%;
            height: 100%; text-align: right; background-color: rgba(0,0,0,.7);
            .go {
                width: 7px;
                height: 7px;
                border-top: 1px solid #b1b1b1;
                border-right: 1px solid #b1b1b1;
                transform: rotate(45deg);
            }
            .navBarList {
                display: inline-block;
                width: px2Mb(600);
                height: 100%;
                overflow-y: auto;
                z-index: 99;
                position: relative;
                background-color: #fff;
                border-top: px2Mb(1) solid #979797;
                padding-bottom: px2Mb(72);
                box-sizing: border-box;
                & > li {
                    border-bottom: px2Mb(1) solid #979797;
                    &.on {
                        border-bottom-color: transparent;
                        .level1-title {
                            border-bottom: px2Mb(1) solid #979797;
                        }
                    }
                }
                .level1-title {
                    border-bottom: px2Mb(1) solid #979797;
                }
                .bg:last-child {
                    margin-bottom: px2Mb(60);
                }
            }
            .navBar-title {
                height: px2Mb(72); font-size: px2Mb(24); font-weight: 400; display: flex; align-items: center; 
                justify-content: space-between;
                &.level3-title {
                    padding: 0 px2Mb(40);
                    line-height: px2Mb(56);
                    height: px2Mb(56);
                    margin-left: px2Mb(20);
                    // color: #00A1E1;
                    // .go {
                    //     border-top: 2px solid #00A1E1;
                    //     border-right: 2px solid #00A1E1;
                    // }
                }
                &.active {
                    font-weight: bold;
                }
            }
            .level1-title {
                background: #EDEDED;
                padding: 0 px2Mb(40);
                color: #1A2C33;
            }
            .nav-children {
                padding-top: px2Mb(8);
                margin-left: px2Mb(40);
            }
            .level2-title {
                margin-left: px2Mb(40);
                padding-right: px2Mb(40);
                background-color: #fff;
                border-bottom: px2Mb(1) solid #979797;
            }
        }
        .nav-icon {
            width: px2Mb(46);
            height: px2Mb(11);
            border-top: px2Mb(6) solid #ffffff;
            border-bottom: px2Mb(6) solid #ffffff;
            &::after {
                content: '';
                display: block;
                width: 100%;
                height: px2Mb(6);
                margin-top: px2Mb(28);
                bottom: 0;
                border-top: px2Mb(6) solid #ffffff;
            }
        }
    }
}

</style>