<template>
    <div class="pageWrapper multipleWrapper">
        <div class="module" v-for="(item,i) in moduleData" :key="i">
            <div class="title" v-if="Array.isArray(item.title)">
                <span class="titleItem" v-for="(it,i) in item.title" :key="i">
                    {{it}}
                </span>
            </div>
            <div class="title" v-else>{{item.title}}</div>
            <div class="content">
                <card-content :cardContentData="item.children" v-if="item.type == 'card'"></card-content>
                <div class="imgContent" v-if="item.type == 'img'">
                    <img :src="item.img" alt="">
                </div>
            </div>
        </div>
        <solution-expert :content="solutionExpertContent"></solution-expert>
    </div>
</template>

<script>
import CardContent from '../../../components/CardContent.vue'
import SolutionExpert from '../../../components/SolutionExpert.vue'
export default {
    name:'multiple',
    components: { CardContent, SolutionExpert },
    data() {
        return {
            solutionExpertContent:'如果您的商业正处于数字化转型阶段，尽快享受数字化带来的商业机会吧，我们将帮您实现商业持续增长。联系我们深入了解多渠道的客制化解决方案',
            moduleData:[
                {   title:'渠道间壁垒带来的运营、成本、业绩问题',
                    children:[
                        {
                            title:'渠道之间相互独立  ',
                            content:'无法实现跨渠道之间的数据共享，影响客户体验值；',
                        },
                        {
                            title:'渠道之间相互竞争  ',
                            content:'渠道之间相互竞争明显，若运营不善，易导致渠道混乱，业绩不理想；',
                        },
                        {
                            title:'多渠道开发维护成本高  ',
                            content:'多个渠道的开发维护成本过高，无法在短期内实现成本分摊。',
                        },
                    ],
                    type:'card',
                },
                {   title:'跨渠道趋势、渠道多样化、渠道功能化',
                    children:[

                    ],
                    img: 'https://static.rongxintech.cn/yx/solution/multiple-pic1.png',
                    type:'img',
                },
                {   title:'以解决您的商业问题为目的，反向输出客制化解决方案',
                    children:[
                        {
                            title:'全渠道数字化升级',
                            content:'打破渠道间壁垒，融合多个渠道效能，为企业降本增效；',
                        },
                        {
                            title:'全渠道角色',
                            content:'让不同的角色都有自己的管理权限',
                        },
                        {
                            title:'全渠道终端',
                            content:'数字化管理的全面移动化',
                        },
                        {
                            title:'全渠道商品',
                            content:'库存共享、弹性分配、库存预测',
                        },
                        {
                            title:'全渠道订单',
                            content:'路由管理，多维度组合，智能拆单',
                        },
                    ],
                    type:'card',
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.multipleWrapper .module {
    .content .imgContent img {
        width: vwDecimal(953);
        height: vwDecimal(972);
        object-fit: contain;
    }
    .content .card-content /deep/ .content {
        grid-template-rows: repeat(2, vwDecimal(117));
        .content-item_content {
            white-space: unset;
        }
    }
    &:nth-child(3) /deep/ {
        .card-content .content {
            display: grid;
            grid-row-gap:0;
            grid-template-columns: unset;
            grid-template-rows: repeat(5, auto);
            .content-item_content {
                white-space: unset;
            }
        }
    }
}
@media screen and (max-width: 414px) {
    .multipleWrapper .module {
        .imgContent {
            height: unset;
        }
        .imgContent img {
            width: px2Mb(690)!important;
            height: px2Mb(770)!important;
            object-fit: contain;
        }
    }
}
</style>