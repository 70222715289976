<template>
    <div class="frame2Wrapper goodsWrapper">
        <div class="module" v-for="(item,i) in moduleData" :key="i">
            <div class="module-top">
                <div class="title">{{item.title}}</div>
                <template v-if="Array.isArray(item.subTitle)">
                    <div class="subTitle" v-for="(it,ii) in item.subTitle" :key="ii">{{it}}</div>
                </template>
                <div class="subTitle" v-else></div>
            </div>
            <div class="content goodsContent">
                <card-content :cardContentData="item.children" v-if="item.children"></card-content>
                 <!-- v-if="item.img" -->
                <div class="imgContent">
                    <img :src="item.img" alt="">
                </div>
            </div>
        </div>
        <!-- <consultative></consultative> -->
    </div>
</template>

<script>
import CardContent from '../../../components/CardContent.vue'
import Consultative from '../../../components/Consultative.vue'
export default {
  components: { CardContent, Consultative },
    data() {
        return {
            moduleData:[
                {   
                    title:'全渠道商品-有形货架和电子货架',
                    children:[
                        {content:'丰富商品种类，扩容终端销售力，提升了市场占有率',},
                        {content:'千人千面，个性化匹配不同终端的不同需求，实现精准布局商品',},
                        {content:'使销售好的渠道具有更多的商品可以销售，使销售差的渠道降低货品积压，柔性适应渠道销售能力',},
                    ],
                    img:'https://static.rongxintech.cn/yx/product/frame2/goods/product-goods-pic2.png'
                },
                {   
                    title:'全渠道库存-本地、云仓和总部履约',
                    children:[
                        {content:'通过设定履约标准，使用离消费者最近的方式履约，尽量缩短配送时间，缩短“定单完成提前期”',},
                        {content:'由于云仓之间的反应效率提升，降低了安全库存，以及安全库存量，从而降低了供应中的库存成本',},
                        {content:'库存量下降，库存资金占用减少，能提高企业资金利用效率，从而增加净资产周转率',},
                    ],
                    img:'https://static.rongxintech.cn/yx/product/frame2/goods/product-goods-pic2(1).png'
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
$navBarWidth: vwDecimal(204);
$offsetLeft: vwDecimal(360);
$wrapperPaddingLeftWidth: vwDecimal(40);
$marginLeftWidth: calc(#{$offsetLeft} + #{$navBarWidth} + #{$wrapperPaddingLeftWidth});
.goodsWrapper {
    /deep/ .module {
        .goodsContent {
            .card-content + .imgContent {
                margin-top: 0;
            }
            display: flex;
            .imgContent {
                width: vwDecimal(556);
                height: vwDecimal(323);
                margin-left: vwDecimal(80);
                margin-right: 0;
                margin-top: 0;
                img {
                    width: unset;
                }
            }
        }
        .goodsContent .card-content {
            width: vwDecimal(312);
            .content {
                width: 100%;
                .content-item {
                    .content-item_content {
                        font-size: vwDecimal(16);
                        font-weight: 400;
                        color: #1A2C33;
                        line-height: vwDecimal(32);
                    }
                    & + .content-item::before{
                        background-color: #C3E3F0;
                        display: block;
                        content: '';
                        height: 1px;
                        width: 100%;
                        margin-top: vwDecimal(30);
                        margin-bottom: vwDecimal(30);
                    }
                }
            } 
        }
        &:nth-child(even) .goodsContent {
            flex-direction: row-reverse;
            .imgContent { 
                margin-right: vwDecimal(40); 
                margin-left: 0;
                margin-top: 0;
                img {
                    width: vwDecimal(556);
                    height: vwDecimal(323);
                }
            }
        }
    }
}
@media screen and (max-width: 414px) {
    .goodsWrapper {
        /deep/ .module {
            .goodsContent {
                width: 100%;
                .card-content + .imgContent {
                    margin-top: 0;
                }
                display: flex;
                .imgContent {
                    display: none;
                }
            }
            .goodsContent .card-content {
                width: 100%;
                left: 0;
                margin-left: 0;
                .content {
                    width: 100%;
                    counter-reset: item;
                    .content-item {
                        counter-increment: item;
                        & + .content-item {
                            margin-top: px2Mb(40);
                        }
                        .content-item_content {
                            font-size: px2Mb(24);
                            font-weight: 400;
                            color: #1A2C33;
                            line-height: px2Mb(38);
                            &::before {
                                content:counter(item) '.';
                            }
                        }
                        & + .content-item::before{
                            display: none;
                        }
                    }
                } 
            }
            &:nth-child(even) .goodsContent {
                flex-direction: unset;
                .imgContent { 
                    margin-right: 0; 
                    margin-left: 0;
                    margin-top: 0;
                }
            }
        }
    }
}

</style>