export default {
    state: {
        classifyInfo: [],    // 该权限下的所有分类信息
        currClassifyInfo: {} // 点击分类后设置的当前分类信息
    },
    mutations: {
        setClassifyInfo(state, value) {
            state.classifyInfo = value
        },
        setCurrClassifyInfo(state, value) {
            if (value.where === 'clickClass') {
                // 点击分类 触发
                state.currClassifyInfo = value
            } else {
                // 根据当前选中的文章 找出 当前选中的分类信息
                let classInfo = {}
                state.classifyInfo.some(v => {
                    if (v.tagId == value.currArticleTagId) {
                        // 只有一级分类
                        classInfo.oneTagId = v.tagId
                        classInfo.oneTagName = v.tagName
                        classInfo.firstId = v.tagId
                        return true
                    } else {
                        // 一级分类未匹配到则找二级分类
                        v.child.some(c => {
                            if (c.tagId == value.currArticleTagId) {
                                classInfo.oneTagId = v.tagId
                                classInfo.oneTagName = v.tagName
                                classInfo.twoTagId = c.tagId
                                classInfo.twoTagName = c.tagName
                                classInfo.firstId = v.child[0].tagId
                                return true
                            }
                        })
                    }
                })
                state.currClassifyInfo = classInfo
            }
        }
    }
}