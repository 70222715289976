<template>
    <div class="frame1Wrapper distributeWrapper">
        <div class="module" v-for="(item,i) in moduleData" :key="i">
            <div class="title">{{item.title}}</div>
            <div class="subTitle" v-if="item.subTitle">{{item.subTitle}}</div>
            <div class="content">
                <ul class="p_box guide_list" v-if="item.type == 'card'">
                    <li class="equal" v-for="(it, i) in item.children" :key="i">
                        <div class="tit">{{ it.title }}</div>
                        <p class="label">{{ it.content }}</p>
                    </li>
                </ul>
                <div class="p_box panel_body pc" v-if="item.type == 'tab'">
                    <div class="p_box tabs_box">
                        <ul class="tabs_list">
                            <li :class="{on: tabCur == ii}" v-for="(it, ii) in item.children" @click="tabCur = ii;" :key="ii">
                                <div class="tit">{{ it.title }}</div>
                            </li>
                        </ul>
                        <div class="flex1 cont" v-if="item.children[tabCur]">
                            <p v-for="(content, j) in item.children[tabCur].children" :key="j">{{ j + 1 }}、{{ content }}</p>
                        </div>
                    </div>
                    <div class="flex1 img_box">
                        <img :src="item.img" alt="经销系统">
                    </div>
                </div>
                <div class="cardCoreList mb" v-if="item.type == 'tab'">
                    <div class="cardCore" v-for="(it,ii) in item.children" :key="ii">
                        <div class="title">{{it.title}}</div>
                        <div class="childrenList">
                            <div class="content" v-for="(v,iii) in it.children" :key="iii">{{v}}</div>
                        </div>
                    </div>
                </div>
                <div class="advantageList" v-if="item.type == 'advantage'">
                    <div class="advantage" v-for="(it,ii) in item.children" :key="ii">
                        <div class="title">{{it.title}}</div>
                        <div class="content">{{it.content}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <consultative></consultative> -->
    </div>
</template>

<script>
import CardContent from '../../../components/CardContent.vue'
import Consultative from '../../../components/Consultative.vue'
export default {
  components: { CardContent , Consultative},
    name:'distribution',
    data() {
        return {
            moduleData:[
                {
                    title: '问题导向',
                    children: [
                        {title:'公域流量成本高',
                        content:'传统电商品牌竞争大，导致站内公域流量成本直线上升',},
                        {title:'广告信息泛滥',
                        content:'直接面向顾客宣传的广告收益越来越下降，品牌无法精准的触达顾客',},
                        {title:'传统分销机制增速缓慢',
                        content:'传统的分销机制无法实现代理与顾客的有效裂变',},
                    ],
                    type:'card'
                },
                {
                    title: '核心功能',
                    children: [
                        {title:'流量中心 ',
                        children:['裂变拉新','用户留存','流量变现','闭环推广','深度服务','信任持续',],
                        },
                        {title:'管理中心 ',
                        children:['裂变管理','内容管理','会员关系','客户分组','订单统筹','数字仓库','物流同步',],
                        },
                        {title:'运营中心 ',
                        children:['营销活动','智能宣传','柔性操作','财务对账','经营报表','业绩趋势','数据精细',],
                        },
                        {title:'营销中心 ',
                        children:['拼团/秒杀','优惠券','分享返现','消费积分','满减优惠','限时折扣',],
                        },
                    ],
                    img: 'https://static.rongxintech.cn/yx/product/frame1/social/product-shejiao-pic1.png',
                    // img: 'https://static.rongxintech.cn/yx/product/frame1/selldistribution/product-jingxiao-pic1.png',
                    // img: 'https://static.rongxintech.cn/oryx/tmp/20210226/16143288907046214.png',
                    type:'tab'
                },
                {
                    title: '产品优势',
                    subTitle: '会员制+分销裂变，实现品牌扩张式传播；全渠道资源+多端口数据互通；打造品牌专属流量中枢',
                    children: [
                        {title:'赋能全民裂变，低成本实现指数级用户增长',
                        content:'社交新零售商城系统，赋能消费者参与产品营销传播职能，创造产品附加价值，分享产品增值利润。社交生活无处不电商。',},
                        {title:'多场景营销，刺激消费者购买欲',
                        content:'帮助企业实现低成本的营销触达，有效提升销量，实现高活跃、高客单、高复购，打造智能营销商城。',},
                        {title:'私域流量，助力企业打造流量闭环',
                        content:'系统整合客户的业务数据和行为数据，搭建数字化社交用户管理体系，利用现有流量裂变，实现流量增值，助力打造流量闭环，解决营销难题，转化商机！',},
                        {title:'智能分润，实现自由灵活的分账管理',
                        content:'支持多种商业形态分润机制，后台自动结算，自主提现；全局把控交易和账务，实现自由灵活的分账管理。',},
                    ],
                    type:'advantage'
                }
            ],
            tabCur: 0,
        }
    }
}
</script>

<style lang="scss" scoped>
.content .advantageList {
    /deep/ .title {
        font-size: vwDecimal(24);
        font-weight: 400;
        color: #1A2C33;
        line-height: vwDecimal(33);
        margin-bottom: vwDecimal(20);
    }
    .content {
        font-size: vwDecimal(16);
        font-weight: 400;
        color: #1A2C33;
        line-height: vwDecimal(32);
    }
}
</style>