<template>
    <div class="frame1Wrapper retailWrapper">
        <div class="module" v-for="(item,i) in moduleData" :key="i">
            <div class="title">{{item.title}}</div>
            <div class="subTitle" v-if="item.subTitle">{{item.subTitle}}</div>
            <div class="content">
                <ul class="p_box guide_list" v-if="item.type == 'card'">
                    <li class="equal" v-for="(it, i) in item.children" :key="i">
                        <div class="tit">{{ it.title }}</div>
                        <p class="label">{{ it.content }}</p>
                    </li>
                </ul>
                <div class="p_box panel_body pc" v-if="item.type == 'tab'">
                    <div class="p_box tabs_box">
                        <ul class="tabs_list">
                            <li :class="{on: tabCur == ii}" v-for="(it, ii) in item.children" @click="tabCur = ii;" :key="ii">
                                <div class="tit">{{ it.title }}</div>
                            </li>
                        </ul>
                        <div class="flex1 cont" v-if="item.children[tabCur]">
                            <p v-for="(content, j) in item.children[tabCur].children" :key="j">{{ j + 1 }}、{{ content }}</p>
                        </div>
                    </div>
                    <div class="flex1 img_box">
                        <img :src="item.img" alt="经销系统">
                    </div>
                </div>
                <div class="cardCoreList mb" v-if="item.type == 'tab'">
                    <div class="cardCore" v-for="(it,ii) in item.children" :key="ii">
                        <div class="title">{{it.title}}</div>
                        <div class="childrenList">
                            <div class="content" v-for="(v,iii) in it.children" :key="iii">{{v}}</div>
                        </div>
                    </div>
                </div>
                <div class="advantageList" v-if="item.type == 'advantage'">
                    <div class="advantage" v-for="(it,ii) in item.children" :key="ii">
                        <div class="title">{{it.title}}</div>
                        <div class="content">{{it.content}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <consultative></consultative> -->
    </div>
</template>

<script> 
import CardContent from '../../../components/CardContent.vue'
import Consultative from '../../../components/Consultative.vue'
export default {
  components: { CardContent , Consultative},
name:'retail',
    data() {
        return {
            moduleData:[
                {
                    title: '问题导向',
                    children: [
                        {title:'渠道',
                content:'单一实体终端门店覆盖范围受限，受众群体无法实现裂变',},
                {title:'数据',
                content:'线上门店与对应的终端门店数据无法统一，导致管理运营成本高，难度系数大',},
                {title:'顾客',
                content:'忠诚度低，购物体验差，引发的顾客流失严重、品牌竞争力薄弱等问题',},
                    ],
                    type:'card'
                },
                {
                    title: '核心功能',
                    children: [
                        {title:'门店管理 ',
                children:['多门店管理','经营数据','在线预约','网上商店','服务日志',],
                },
                {title:'会员管理 ',
                children:['会员标签','会员回访','会员画像','会员储值','会员权益',],
                },
                {title:'商品管理 ',
                children:['产品管理','一物一码','商品履约','商品触点','溯源防蹿','在线云仓',],
                },
                {title:'营销中心 ',
                children:['个性化装修','多人拼团','秒杀砍价','积分商城','推荐有礼',],
                },
                {title:'数据中心 ',
                children:['数据分析','数据决策','交易数据','精准推送','数据画像','数据一体化',],
                },
                    ],
                    img: 'https://static.rongxintech.cn/yx/product/frame1/store/product-mendian-pic1.png',
                    type:'tab'
                },
                {
                    title: '产品优势',
                    subTitle: '整合全渠道信息，实现仓储配送高效协同；专注提效降本，人货钱场单经营管理数字化',
                    children: [
                        {title:'端口一体化',
                content:'基于小程序构建门店系统内所有业务通道，将订单、商品、物流、会员等多个业务通过一个端口开放，保证在订单溯源中，人、货、场与订单数据一一对应。',},
                {title:'会员一体化',
                content:'打通全渠道会员数据，支持线上店铺与实体店铺会员唯一ID，形成会员心目中的品牌形象的同时，获取会员全渠道画像，打造品牌、门店的私域流量池。',},
                {title:'导购数字化',
                content:'通过有信探客宝系统助力终端门店二次营销，让实体门店中的每一位导购都能够打造自己的线上客户裂变渠道，持续提升导购的推广能力。',},
                {title:'线上线下数据一体化',
                content:'打通线上订单与线下实体订单的数据池，将每一笔订单与相关数据相匹配，保证消费者、门店、品牌三方在数据查询时，有迹可循。',},
                {title:'数据化决策',
                content:'从品牌商到终端门店的数据闭环，帮助每一个经营主体做数据决策，快速分析经营状况，帮助品牌、门店做数据决策。',},
                {title:'BI与SCRM',
                content:'基于门店消费者数据池，通过大数据智能分析反作用于终端门店，协同SCRM，帮助门店实现客流、销量的爆发式增长。',},
                    ],
                    type:'advantage'
                }
            ],
            guide: {
                title: '问题导向',
                list: [
                    { title: '战略层', label: '以品牌商为核心的供应链中，各个环节无法实现协同工作、信息共享。' },
                    { title: '业务层', label: '经销商的日常运作管理无法数据化，采购、销售、库存、客户等数据不统一。' },
                    { title: '技术层', label: '信息不同步，供应链中各个环节各自为营，信息无法对称，管理成本递增。' }
                ]
            },
            tabCur: 0,
            core: {
                title: '核心功能',
                tabs: [
                    {
                        title: '会员管理',
                        children: ['会员唯一ID','会员全生命周期管理','会员储值','会员权益','会员架构属性管理']
                    },
                    {
                        title: '商品管理',
                        children: ['统一授权','一物一码','溯源防窜','自由供货','商品履约','商品触点']
                    },
                    {
                        title: '财务管理',
                        children: ['空中分账','账户管理','即刻结算','账号安全','灵活充值','交易明细']
                    },
                    {
                        title: '仓库管理',
                        children: ['在线兑货','下级移仓','上级提货','在线退仓','一件代发','库存账目']
                    },
                    {
                        title: '订单管理',
                        children: ['订单路由','会员归属','库存分类','智能拆单','核销订单','订单跟踪']
                    }
                ],
                img: 'https://static.rongxintech.cn/oryx/tmp/20210226/16143288907046214.png'
            },
            advantage: {
                title: '产品优势',
                summary: '整合全渠道信息，实现仓储配送高效协同；专注提效降本，人货钱场单经营管理数字化',
                list: [
                    { title: '统筹管理后台，高效集成管理', label: '围绕企业商业模式和经销体系业务流程设计，一个管理后台、打通会员、商品、订单、仓库、销售等信息数据，让生意更简单、管理更高效！' },
                    { title: '一物一码，商品统一授权管理', label: '对商品和的经销商进行统一授权管理，解决商品溯源防窜货问题，实现自由设置供货模式，提升整体管理效率。' },
                    { title: '在线云仓，掌上操作省时省力更省心', label: '随时随地在线兑仓、提货、移仓、发货，库存数据动态更新、全局同步，帮助企业掌握全网销售和库存实况。' }, 
                    { title: '团队管理，成员属性和动态实时掌握', label: '代理商体系云端同步，在线完成申请、授权、审核等，实时掌握团队架构与动向，整合庞大的代理商资料，轻松实现系统化管理。' },
                    { title: '财务管理，可视化业绩数据细分', label: '所有业绩数据细分、财务自动结算，以日报、周报、月报形式呈现业绩情况，对个人及团队经营现状了然于胸！' },
                    { title: '多政策并行的多引擎系统', label: '通过多引擎系统打造品牌的多个政策并行功能，实现账户内多个余额、多个活动、多个SPU/SKU功能。' }
                ]
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>