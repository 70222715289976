<template>
    <div class="frame1Wrapper fieldsWrapper">
        <div class="module" v-for="(item,i) in moduleData" :key="i">
            <div class="title">{{item.title}}</div>
            <div class="subTitle" v-if="item.subTitle">{{item.subTitle}}</div>
            <div class="content">
                <ul class="p_box guide_list" v-if="item.type == 'card'">
                    <li class="equal" v-for="(it, i) in item.children" :key="i">
                        <div class="tit">{{ it.title }}</div>
                        <p class="label">{{ it.content }}</p>
                    </li>
                </ul>
                <div class="p_box panel_body pc" v-if="item.type == 'tab'">
                    <div class="p_box tabs_box">
                        <ul class="tabs_list">
                            <li :class="{on: tabCur == ii}" v-for="(it, ii) in item.children" @mouseenter="tabCur = ii" :key="ii">
                                <div class="tit">{{ it.title }}</div>
                            </li>
                        </ul>
                        <div class="flex1 cont" v-if="item.children[tabCur]">
                            <p v-for="(content, j) in item.children[tabCur].children" :key="j">{{ j + 1 }}、{{ content }}</p>
                        </div>
                    </div>
                    <div class="flex1 img_box">
                        <img :src="item.img" alt="经销系统">
                    </div>
                </div>
                <div class="cardCoreList mb" v-if="item.type == 'tab'">
                    <div class="cardCore" v-for="(it,ii) in item.children" :key="ii">
                        <div class="title">{{it.title}}</div>
                        <div class="childrenList">
                            <div class="content" v-for="(v,iii) in it.children" :key="iii">{{v}}</div>
                        </div>
                    </div>
                </div>
                <div class="advantageList" v-if="item.type == 'advantage'">
                    <div class="advantage" v-for="(it,ii) in item.children" :key="ii">
                        <div class="title">{{it.title}}</div>
                        <div class="content">{{it.content}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <consultative></consultative> -->
    </div>
</template>

<script> 
import CardContent from '../../../components/CardContent.vue'
import Consultative from '../../../components/Consultative.vue'
export default {
    components: { CardContent , Consultative},
    name:'retail',
    data() {
        return {
            moduleData:[
                {
                    title: '问题导向',
                    children: [
                        {title:'公域',
                        content:'流量获取成本高，受平台、竞争对手、粉丝的影响 ，留存与转化难度系数较大',},
                        {title:'私域',
                        content:'流量裂变受限制，无法借助平台的公域流量实现高效裂变、留存与转化',},
                        {title:'转化',
                        content:'公域与私域的流量流转单向化，无法实现私域流量的公域流转与裂变',},
                    ],
                    type:'card',
                },
                {
                    title: '核心功能',
                    children: [
                        {title:'  直播 ',
                            children:['我播你赚','全渠道跨域直播',],
                            },
                        {title:'  探客 ',
                            children:['探客有道','销售更有数',],
                            },
                        {title:'  社交 ',
                            children:['全渠道分享','全渠道受益',],
                            },
                        {title:'  结算 ',
                            children:['空中分账','及时激励',],
                        },
                    ],
                    img: 'https://static.rongxintech.cn/yx/product/frame1/fields/product-lingyu-pic1.png',
                    // img: 'https://static.rongxintech.cn/oryx/tmp/20210226/16143288907046214.png',
                    type:'tab'
                },
                {
                    title: '产品优势',
                    subTitle: '整合全渠道信息，实现仓储配送高效协同；专注提效降本，人货钱场单经营管理数字化',
                    children: [
                        { title: '打通全域', content: '帮助品牌真正打通公域私域',},
                        { title: '续客转化', content: '实现公域续客转化，私域沉淀运营' },
                        { title: '我播你赚', content: '我播你赚商业模式，全渠道参与的直播'}, 
                        { title: '智能结算', content: '智能结算引擎，及时分账，激活代理积极性'},
                    ],
                    type:'advantage'
                }
            ],
            tabCur: 0,
        }
    }
}
</script>

<style lang="scss" scoped>

</style>