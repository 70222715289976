<template>
    <div class="frame2Wrapper marketingWrapper">
        <div class="module" v-for="(item,i) in moduleData" :key="i">
            <div class="module-top">
                <div class="title">{{item.title}}</div>
                <template v-if="Array.isArray(item.subTitle)">
                    <div class="subTitle" v-for="(it,ii) in item.subTitle" :key="ii">{{it}}</div>
                </template>
                <div class="subTitle" v-if="item.subTitle && !Array.isArray(item.subTitle)">{{item.subTitle}}</div>
            </div>
            <div class="content">
                <card-content :cardContentData="item.children" v-if="item.children"></card-content>
                <!-- v-if="item.img" -->
                <div class="imgContent">
                    <img :src="item.img" alt="">
                </div>
            </div>
        </div>
        <!-- <consultative></consultative> -->
    </div>
</template>

<script>
import CardContent from '../../../components/CardContent.vue'
import Consultative from '../../../components/Consultative.vue'
export default {
  components: { CardContent, Consultative },
    data() {
        return {
            moduleData:[
                {   
                    title:'消费者是谁？完整的消费者画像',
                    img:'https://static.rongxintech.cn/yx/product/frame2/marketing/marketing-pic1.png'
                },
                {   
                    title:'如何触达消费者？丰富消费者触点',
                    subTitle:'建立与消费者沟通的方式，需要依据消费者的行为方式，围绕消费者的需求建立触点。',
                    img:'https://static.rongxintech.cn/yx/product/frame2/marketing/marketing-pic2.png'
                },
                {   
                    title:'与消费者沟通什么内容？智能营销、千人千面',
                    subTitle:'内容云基于客户的访问习惯，以及信息的关注差异，智能自动化向不同的消费者推送不同的内容。',
                    img:'https://static.rongxintech.cn/yx/product/frame2/marketing/marketing-pic3.png'
                },
                {   
                    title:'消费者的反馈如何？实现双向交流，有效沟通',
                    subTitle:'群雷达可以实时反馈发给消费者的内容是否得到正向反馈，既可以观察个体消费者行为，也可以关注消费者的群体行为',
                    img:'https://static.rongxintech.cn/yx/product/frame2/marketing/marketing-pic4.png'
                },
                {   
                    title:'营销数字化全景',
                    img:'https://static.rongxintech.cn/oryx/tmp/20210304/16148573731722787.png'
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
$navBarWidth: vwDecimal(204);
$offsetLeft: vwDecimal(360);
$wrapperPaddingLeftWidth: vwDecimal(40);
$marginLeftWidth: calc(#{$offsetLeft} + #{$navBarWidth} + #{$wrapperPaddingLeftWidth});
.marketingWrapper {
    width: vwDecimal(956);
}
@media screen and (max-width: 414px) {
    .marketingWrapper {
        width: unset;
        margin-left: 0;
    }
}
</style>