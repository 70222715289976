<template>
	<div>
		<a href="#last" name="last" id="apply"></a>
		<apply></apply>
		<div class="bottom">
			<div class="box">
				<div class="main">
					<div class="links">
						<div class="link" v-for="(lis, i) in links" :key="i">
							<div class="link_title">{{lis.title}}</div>
							<ul v-if="lis.link && lis.link.length">
								<li v-for="(li, j) in lis.link" :key="j">
									<router-link v-if="!li.wrap" :to="li.url" @click.native="Reload(li.url)"> {{ li.name }}</router-link>
									<a v-else :href="li.url" target="_blank">{{ li.name }}</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="right">
						<div class="phoneTime">
							<div class="phoneTime-top">
								<div class="left">
									<span class="titleIcon1"></span>
								</div>
								<div class="right">
									<div>联系电话</div>
									<div class="content">{{ tel }}</div>
								</div>
							</div>
							<div class="phoneTime-bottom">
								<div class="left">
									<!-- <span class="titleIcon2"></span> -->
								</div>
								<div class="right">
									<div>服务时间</div>
									<div class="content">每周一至周五 08:00-20:00</div>
									<!-- <div class="content">08:00-20:00</div> -->
								</div>
							</div>
						</div>
						<div class="qrcode">
							<div class="qrcode-left">
								<span class="titleIcon2"></span>
							</div>
							<div class="qrcode-right">
								<div>关注公众号</div>
								<img :src="qrcode" alt="">
							</div>
						</div>
					</div>
					<!-- <div class="qrcode">
						<img :src="qrcode" alt="">
						<div class="tel">{{tel}}</div>
					</div> -->
				</div>
				<div class="tips">
					<div class="tipsTop">{{tipsTop}}</div>
					<div class="tipsBottom">Copyright 2018-{{nowYear}} youxin.cloud All Rights Reserved <a href="javascript:void(0);" @click="goPage('http://www.beian.miit.gov.cn')">粤ICP备18029216号-6</a></div>
				</div>
			</div>
		</div>
		<div class="bottom_mobile">
			<div class="btns">
				<div class="m_btn btn primary" @click="goTry" v-if="!hideTry">
					<img class="img" src="https://static.rongxintech.cn/oryx/tmp/20200611/1591879991864.png" alt="">
					<span>体验</span>
				</div>
				<a class="m_btn btn primary" :href="'tel:' + tel">
					<img class="img" src="https://static.rongxintech.cn/oryx/tmp/20200611/1591880005172.png" alt="">
					<span>咨询</span>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	import Apply from '../components/Apply'
	
export default {
    data() {
        return {
            links: [
                {
                    title: '有信科技',
                    link: [
                        {
                            url: '/team',
                            name: '关于我们'
                        },
                        {
                            url: '/team/intro',
                            name: '团队介绍'
                        }
                    ]
                },
                {
                    title: '产品与服务',
                    link: [
                        {
                            url: '/takepal',
                            name: '有信探客宝'
                        },
                        {
                            url: '/manage',
                            name: '经销管理系统'
                        },
                        {
                            url: '/mall',
                            name: '社交商城平台系统'
                        }
                    ]
                },
                {
                    title: '解决方案',
                    link: [
                        {
                            url: '/solution',
                            name: '解决方案'
                        }
                    ]
                },
                {
                    title: '动态',
                    link: [
                        {
                            url: '/team/news',
                            name: '动态资讯'
                        }
                    ]
                },
                {
                    title: '友情链接',
                    link: [
                        {
                            wrap: true,
                            url: 'https://xiaokefu.com.cn/',
                            name: '芝麻小客服'
                        },
                        {
                            wrap: true,
                            url: 'http://www.bangongyi.com/',
                            name: '办公逸'
                        }
                    ]
                }
            ],
            qrcode: 'https://pro-rx.oss-cn-hangzhou.aliyuncs.com/tanke/qrcode.png',
            tel: '020-85200696',
            phone: '13392666787',     // 冯哥手机号
            tipsTop: '广州有信科技有限公司',
			hideTry: false
            // tipsBottom:'Copyright 2018-2019 youxin.cloud All Rights Reserved 粤ICP备18029216号-6'
        }
    },
	components: {
		Apply
	},
    computed: {
        nowYear() {
            let now = new Date()
            return now.getFullYear()
        }
    },
	created() {
		
	},
	mounted() {
		
	},
	methods: {
		goTry() {
			if (this.isAndroid) {
				let anchor = this.$el.querySelector('#apply')
				if (anchor) {
					document.body.scrollTop = anchor.offsetTop - 48;
				}
			} else {
				window.location.hash = '#last';
			}
			this.hideTry = true;
		},
		Reload(link) {
			if (window.location.href.indexOf('#last') === -1 && window.location.href.indexOf(link) > -1) {
				if (link.indexOf('team') === -1 || link.substr(link.indexOf('/') + 1) === 'team') {
					if (this.isAndroid) {
						document.body.scrollTop = 0;
					} else {
						document.documentElement.scrollTop = 0;
					}
				} else {
					this.$parent.reload();
				}
			}
		}
	},
	watch: {
		'$route'(now, past) {
			if (now.path !== past.path) {
				this.hideTry = false;
			}
		}
	}
}
</script>

<style lang="scss" scoped>

.bottom {
    // background-color: #1a1a1a;
    background: #0C1726;
    line-height: 32px;
    .box {
        margin: 0 auto;
    }
    .main {
        display: flex;
        height: 100%;
        // padding: 52px 168px 14px;
        padding: 32px 120px 22px 120px;
        justify-content: space-between;
        font-size: 13px;
        color: #fff;
        position: relative;
        &::after{
            content: '';
            display: block;
            border-bottom: 1px solid #ffffff;
            opacity: 0.12;
            position: absolute;
            bottom: 0;
            left: 120px;
            right: 120px;
            box-sizing: border-box;
        }
    }

    .links {
        display: flex;
        padding: 0 0;
        justify-content: space-between;
        position: relative;
        &::after{
            content: '';
            display: block;
            height: 135px;
            border-right: 1px solid #ffffff;
            opacity: 0.12;
            position: absolute;
            right: 0;
            box-sizing: border-box;
        }
    }

    .link {
        // width: 184px;
        width: 120px;
        width: 115px;
        text-align: left;
        font-size: 14px;
        color: #FFFFFF;

        ~ .link {
            margin-left: 10px;
        }

        &_title {
            font-size: 16px;
            padding-bottom: 5px;
        }

        a {
            color: #fff;
            opacity: 0.5;
            &:hover {
                // color: #e3650c;
                color: #11A2FF;
            }
        }
    }
    .right{ 
		display: flex; justify-content: space-around; flex: 1;
		padding-top: 3px;
	}
    .phoneTime{
        font-size: 16px; color: #FFFFFF; line-height: 20px;
        // height: 20px; 
        display: flex; flex-direction: column;
        .phoneTime-top{
            display: flex;
            .left{
                .titleIcon1{ background: url(https://pro-rx.oss-cn-hangzhou.aliyuncs.com/oryx/tmp/20191012/1570870206394.png) no-repeat; width: 40px; height: 40px; display: inline-block; }
            }
            .right{
                display: flex; flex-direction: column; margin-left: 24px;
                .content{ font-size: 14px; opacity: 0.5; margin-top: 12px; }
            }
        }
        .phoneTime-bottom{
            display: flex;
            .left { width: 40px; height: 40px; display: block; }
            .right{
                display: flex; flex-direction: column; margin-left: 24px; margin-top: 33px;
                .content{ font-size: 14px; opacity: 0.5; margin-top: 12px; }
            }
        }
    }
    .qrcode {
        font-size: 16px; color: #FFFFFF; line-height: 20px; 
        // height: 20px; 
        display: flex;
        .qrcode-left{
            .titleIcon2{ background: url(https://pro-rx.oss-cn-hangzhou.aliyuncs.com/oryx/tmp/20191012/1570870220403.png) no-repeat; width: 40px; height: 40px; display: inline-block; }
        }
        .qrcode-right{
            margin-left: 24px;
            img { padding-top: 18px; width: 106px; display: block; }
            .content{ font-size: 14px; opacity: 0.5; margin-top: 12px; }
        }
    }

    .tips { 
        padding: 20px 0; opacity: 0.5;  font-size: 14px; color: #FFFFFF; letter-spacing: 0; text-align: center; 
		line-height: 24px; 
        // .tipsBottom{
        //     margin-top: 12px;
        // }
        a {
            color: inherit;
            text-decoration: underline;
        }
    }
}
.bottom_mobile {
    display: none;
    position: fixed;
    right: 12px;
    bottom: 60px; 
    z-index: 20; 
    .btns {
		width: 60px;
		text-align: center;
    }
    .btn {
		margin-bottom: 10px;
        width: 60px;
        height: 60px;
		display: inline-block;
        // padding: 10px 0;
        // line-height: 22px;
        background-color: #fff;
		box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
        font-size: 12px;
        border: none;
		color: #333;
		position: relative;
		border-radius: 100%;
    }

    .img {
        width: 22px;
        vertical-align: middle;
    }
	span { 
		display: block; 
		line-height: 12px;
	}
}
</style>
