<template>
    <div class="pageWrapper dailyUseWrapper">
        <div class="module" v-for="(item,i) in moduleData" :key="i">
            <div class="title" v-if="Array.isArray(item.title)">
                <span class="titleItem" v-for="(it,i) in item.title" :key="i">
                    {{it}}
                </span>
            </div>
            <div class="title" v-else>{{item.title}}</div>
            <div class="content">
                <card-content :cardContentData="item.children" v-if="item.type == 'card'"></card-content>
                <div class="imgContent" v-if="item.type == 'img'">
                    <img :src="item.img" alt="">
                </div>
            </div>
        </div>
        <solution-expert :content="solutionExpertContent"></solution-expert>
    </div>
</template>

<script>
import CardContent from '../../../components/CardContent.vue'
import SolutionExpert from '../../../components/SolutionExpert.vue'
export default {
    name:'DailyUse',
    components: { CardContent, SolutionExpert },
    data() {
        return {
            solutionExpertContent:'如果您的商业正处于数字化转型阶段，尽快享受数字化带来的商业机会吧，我们将帮您实现商业持续增长。联系我们深入了解日化行业的客制化解决方案',
            moduleData:[
                {title:'推广费用高、动销难、留存低、复购低等问题制约行业发展',
                children:[
                    {
                title:'媒介推广成本高  ',
                content:'市场媒介推广如大海捞针，精准度低，用户转化率低',},
                {
                title:'终端动销难  ',
                content:'代理商体系庞大，业务链长，渠道触点难以激活',},
                {
                title:'消费者留存、复购率低  ',
                content:'产品同质化严重，消费者忠诚度不高，购买行为容易变化',},
                ],
                type:'card',},
                {title:'全渠道数字化是必然趋势',
                children:[],
                img: 'https://static.rongxintech.cn/yx/solution/dailyuse-pic1.png',
                type:'img',},
                {title:'以解决您的商业问题为目的，反向输出客制化解决方案',
                children:[
                    {
                    title:'数字化评估营销效果  ',
                    content:'大数据分析营销反馈数据，数字化激活自有媒介成为触达消费者的重要入口，降低企业广告推广成本，提升营销转化率',},
                    {
                    title:'激活全渠道触点，提升动销效率  ',
                    content:'激活由导购到经销的全渠道触点，建立智能结算体系，与渠道方建立共生关系，提升渠道的积极性',},
                    {
                    title:'构建私域流量，精细化运营  ',
                    content:'打造数字化中心，建立用户标签，形成全方位用户画像，实现私域流量池的建立和精细化运营',},
                ],
                type:'card',},

                
                

                
                
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.dailyUseWrapper .module {
    .content .imgContent img {
        width: vwDecimal(953);
        height: vwDecimal(1003);
        object-fit: cover;
    }
    .content .card-content /deep/ .content {
        grid-template-columns: vwDecimal(450) vwDecimal(418);
        grid-template-rows: repeat(2, auto);
        grid-column-gap: vwDecimal(4);
    }
    &:nth-child(3) /deep/ {
        .card-content .content {
            display: grid;
            grid-row-gap:0;
            grid-template-columns: unset;
            grid-template-rows: repeat(3, auto);
            .content-item_content {
                white-space: unset;
            }
        }
    }
}
@media screen and (max-width: 414px) {
    .dailyUseWrapper .module {
        .imgContent {
            height: unset;
        }
        .imgContent img {
            width: px2Mb(690)!important;
            height: px2Mb(750)!important;
            object-fit: contain;
        }
    }
}
</style>