<template>
    <div class="card" @click="routePos === 'card' && goPage(link)" :id="id">
        <div class="card-title" v-if="titleImg">
            <img :src="titleImg"  class="titleImg">
        </div>
        <div class="card-header" v-else>
            <slot name="header"></slot>
        </div>
        <slot></slot>
        <div class="card-footer" v-if="$slots.footer">
            <span class="footerLink">
                <div @click="routePos === 'footer' && goPage(link)">{{ footerLink }}</div>
            </span>
        </div>
    </div>
</template>

<script>
const reg = new RegExp( /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/ )
export default {
    props:{
        id:[String,Number],
        footerLink:{
            type: String,
            default: '了解详情 >'
        },
        link:{
            type: String,
            default: '/consultative'
        },
        titleImg:String,
        routePos:{
            type:String,
            default: 'footer',
            validator(val) {
                return ['card','footer'].indexOf(val) > -1
            }
        }
    },
    data() {
        return {
            reg
        }
    },
}
</script>

<style lang="scss" scoped>
.card-title {
    width: auto;
}
.footerLink {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    cursor: pointer;
}
</style>
