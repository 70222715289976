<template>
    <div class="pageWrapper usWrapper">
        <div class="module module-1">
            <card-content title="有信团队介绍" cardContentData="有信团队80%为产研，其中20%来自腾讯、阿里、YY等一线互联网公司，30%毕业于985/211院校。有信以市场的认可为荣，同时也为团队的才华感到自豪。在经验丰富的领导与技术卓越的团队陪伴中，有信正蓬勃发展。"></card-content>
        </div>
        <div class="module module-2">
            <div class="title">管理团队</div>
            <div class="cardListWrapper">
                <card v-for="(item,i) in cardData" :key="i" class="card">
                    <div slot="header" class="personInfo">
                        <div class="card-avatar">
                            <img :src="item.img" />
                        </div>
                        <div class="card-info">
                            <div class="title">{{item.title}}</div>
                            <template v-if="Array.isArray(item.subTitle)">
                                <div class="subTitle" v-for="(it,ii) in item.subTitle" :key="ii">{{it}}</div>
                            </template>
                            <div class="subTitle" v-else>{{item.subTitle}}</div>
                        </div>
                    </div>
                    <card-content :cardContentData="item.children"></card-content>
                </card>
            </div>
        </div>
        <div class="module module-3">
            <div class="title">顾问团队</div>
            <div class="cardListWrapper" v-for="(item,i) in cardData2" :key="i">
                <div class="cardList-title">{{item.title}}</div>
                <div class="cardList-content">
                    <card v-for="(it,idx) in item.children" :key="idx" class="card">
                        <div slot="header" class="card-avatar">
                            <img :src="it.img" alt="">
                        </div>
                        <card-content class="card-content" :title="it.title" :cardContentData="it.children"></card-content>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Card from '../components/Card.vue'
import CardContent from '../components/CardContent.vue'
export default {
    components:{CardContent, Card},
    data(){
        return {
            cardData:[
                {
                    title:'林少章',
                    subTitle: '创始人/CEO',
                    img: 'https://static.rongxintech.cn/yx/1/1林少章.png',
                    children:[
                        {   content:'华南理工大学计算机学士'    },
                        {   content:'中山大学世界经济硕士'  },
                        {   content:'2006年加入腾讯张小龙团队，10年腾讯研发经验'    },
                        {   content:'前腾讯企业邮箱、微信企业号技术Leader'  },
                        {   content:'6年主导大型ToB产品研发经验'    },
                    ],
                },
                {
                    title:'冯卫钊',
                    subTitle: '联合创始人/总裁',
                    img: 'https://static.rongxintech.cn/yx/1/2冯卫钊.png',
                    // img:"https://static.rongxintech.cn/oryx/tmp/20210223/16140719981175251.png",
                    children:[
                        {   content:'武汉纺织大学广告学-文学学士'    },
                        {   content:'2005年加入电广传媒'    },
                        {   content:'曾服务立白、美的、拉芳、无限极、颜如玉等行业领袖企业'  },
                        {   content:'15年大客户市场营销经验，深度理解新零售'    },
                    ]
                },
                {
                    title:'孙晋侯',
                    subTitle: '联合创始人/COO',
                    img: 'https://static.rongxintech.cn/yx/1/3孙晋侯.png',
                    // img:"https://static.rongxintech.cn/oryx/tmp/20210223/16140719981175251.png",
                    children:[
                        {   content:'曾就读于北京邮电大学' },
                        {   content:'2013年2月加入滴滴，第25号员工' },
                        {   content:'曾先后负责出租车、专车、快车相关产品'  },
                        {   content:'主导网约车劳务公司、租赁公司线上化业务，有产业升级经验'    },
                    ]
                },
                {
                    title:'黄伟',
                    subTitle: '技术合伙人/CTO',
                    img: 'https://static.rongxintech.cn/yx/1/4黄伟.png',
                    // img:"https://static.rongxintech.cn/oryx/tmp/20210223/16140719981175251.png",
                    children:[
                        {   content:'华南理工大学计算机学士/硕士'   },
                        {   content:'2006年加入腾讯张小龙团队任高级工程师架构师'    },
                        {   content:'2013年加入欢聚时代，任高级技术经理，技术评委会委员'    },
                        {   content:'擅长海量用户业务建模和技术架构，对存储系统有较深经验'  },
                    ]
                },
                {
                    title:'郑秋明',
                    subTitle: '产品副总裁',
                    img: 'https://static.rongxintech.cn/yx/1/5郑秋明.png',
                    // img:"https://static.rongxintech.cn/oryx/tmp/20210223/16140719981175251.png",
                    children:[
                        {   content:'中山大学管理学学士/硕士'   },
                        {   content:'2011年入职腾讯，曾任腾讯电商、微信支付产品经理'    },
                        {   content:'2015年加入考拉先生，任产品合伙人'  },
                        {   content:'2018年加入加推科技，任首席产品官CPO'   },
                    ]
                },
                {title:'马浩',
                subTitle:['资深架构师','后端开发总监'],
                img: 'https://static.rongxintech.cn/oryx/tmp/20210304/16148668586762146.png',
                children:[
                    {content:'郑州大学计算机学士，11年研发经验',},
                    {content:'曾参与华为手机全备份项目和Wi-Fi直连项目研发',},
                ]},
                // {title:'孙海军',
                // subTitle:'大客户副总经理',
                // children:[
                //     {content:'20年大客户服务经验',},
                //     {content:'曾任IBM(中国)ToB业务大南区销售负责人',},
                //     {content:'金蝶软件To B业务区域销售负责人',},
                //     {content:'云徙大客户区域总经理',},
                // ]},

                {title:'郑龙哲',
                subTitle:['SaaS产品','高级客户总监'],
                img: 'https://static.rongxintech.cn/oryx/tmp/20210304/16148668585034124.png',
                children:[
                    {content:'中山大学国际经济与贸易学学士',},
                    {content:'10 年市场业务商务经验',},
                    {content:'曾任欢聚集团ToB业务商务总监',},
                    {content:'阿里巴巴机器人业务商务负责人',},
                ]},

                {title:'黄雅',
                subTitle:['资深工程师','解决方案总监'],
                img: 'https://static.rongxintech.cn/oryx/tmp/20210304/16148668582807569.png',
                children:[
                    {content:'湖北工业大学计算机学士/硕士，12年产研经验',},
                    {content:'曾任职腾讯、阿里高级开发工程师，图普科技零售业务总经理',},
                    // {content:'擅长企业级服务咨询',},
                ]},
                {title:'魏琦',
                subTitle:'项目总监',
                img: 'https://static.rongxintech.cn/yx/1/7魏琦.png',
                children:[
                    {content:'内蒙古大学计算机学士，18年项目管理经验',},
                    {content:'曾任摩托罗拉（中国）质量部南区主管，欢聚集团项目管理部负责人及ToB业务线项目管理负责人',},
                    {content:'擅长项目管理及企业级项目落地',},
                ]},
                {title:'李鑫',
                subTitle:'市场总监',
                img: 'https://static.rongxintech.cn/yx/1/8李鑫.png',
                children:[
                    {content:'山东大学新闻学-文学学士，暨南大学传播学硕士，9年品牌营销工作经验',},
                    {content:'曾任南方报业传媒集团子公司总经理，服务项目体量过亿，助力品牌实现零售额翻倍增长',},
                ]},
                {title:'付鸿艳',
                subTitle:'人力资源总监',
                img: 'https://static.rongxintech.cn/yx/1/9付鸿艳.png',
                children:[
                    {content:'华南师范大学人力资源管理学学士',},
                    {content:'7年人力资源从业经验',},
                    {content:'前腾讯微信HR、酷狗音乐猎聘组成员',},
                    {content:'擅长猎聘及组建优质产研团队，新业务人才梯队搭建',},
                ]},
            ],
            cardData2: [
                {
                    title: '学界解决方案专家团队',
                    children: [{
                            title: '朱磊',
                            img: 'https://static.rongxintech.cn/yx/2/1朱磊.png',
                            children: [{
                                content: '传播学博士，暨南大学新闻与传播广告学系主任；擅长数据洞察，以数字化驱动中外品牌本土化和国际化传播战略；曾主持/承担日本广告学会、CCTV、省广股份等机构项目咨询。 ',
                            }, ]
                        },
                        {
                            title: '鲁晓东',
                            img: 'https://static.rongxintech.cn/yx/2/2鲁晓东.png',
                            children: [{
                                    content: '经济学博士，中山大学岭南学院经济学系副主任、副教授；主要研究领域为产业结构、国际金融与国际投资等，对产业结构升级背景下的企业转型发展有独到见解',
                                },

                            ]
                        },
                    ]
                },
                {
                    title: '第三方咨询解决方案专家团队',
                    children: [{
                            title: '梅波',
                            img: 'https://static.rongxintech.cn/yx/3/1梅波.png',
                            children: [{
                                    content: '弯弓Digital和《私域流量观察》创始人，弯弓研究院院长，MarTech技术和内容营销深度研究者；曾先后供职南方都市报和21世纪经济报道担任高管。',
                                },

                            ]
                        },
                        {
                            title: '梁冠章',
                            img: 'https://static.rongxintech.cn/yx/3/2梁冠章.png',
                            children: [{
                                    content: '现任：邓韩贝中国区总经理；',
                                },
                                {
                                    content: '国内零售和快消行业资深人士，超过15年在国内外零售及快消领域的实践与经验；曾先后服务于联合利华和尼尔森。',
                                },

                            ]
                        },
                        {
                            title: '赵文杰',
                            img: 'https://static.rongxintech.cn/yx/3/3赵文杰.png',
                            children: [{
                                    content: '历任华为德国销售总监、Salesforce.com大中华区销售总监、SAP中国销售总监、 Oracle中国EBS系统顾问；',
                                },
                                {
                                    content: '对ERP、CRM、PLM、FSSC、云计算、PaaS、SaaS等多种应用模式有实施部署及商务推广方面的深刻理解。',
                                },

                            ]
                        },
                    ]
                },
                {
                    title: '垂直行业解决方案专家团队',
                    children: [{
                            title: '翟红亮',
                            img: 'https://static.rongxintech.cn/yx/4/1翟红亮.png',
                            children: [{
                                    content: '百森智投（深圳）科技有限公司 ，战略及数字化业务总裁；',
                                },
                                {
                                    content: '具有24年数字化解决方案开发经验，13年互联网产品及运营经验。',
                                },

                            ],
                        },
                        {
                            title: '李亚忠（Mark）',
                            img: 'https://static.rongxintech.cn/yx/4/2李亚忠.png',
                            children: [{
                                content: '具有二十年现代零售业；对中国美妆、连锁药店、母婴店和生活馆等新型零售业态有深刻的理解与研究 ；曾服务屈臣氏，创建DFS及迈致。',
                            }, ]
                        },
                        {
                            title: '沈欣',
                            img: 'https://static.rongxintech.cn/oryx/tmp/20210304/16148679820119390.png',
                            children: [{
                                content: '百果科技首席技术市场官、广东省连锁协会技术金融委员会主席；为好又多、真功夫快餐、万客隆(中国)、中油BP、OK便利店、周大福、本来生活、台湾三商集团(中国)、马来西亚 Sunshine集团、中国供销总社等企业进行过IT规划、开发及咨询',
                            }, ],
                        },

                        {
                            title: 'Cindy Xie',
                            img: 'https://static.rongxintech.cn/oryx/tmp/20210304/16148679817607799.png',
                            children: [{
                                    content: '10年奢侈品零售行业从业经验， 就职于国际一线奢侈品品牌， 担任品牌高级管理职务；',
                                },
                                {
                                    content: '参与建立上海交通大学中国奢侈品研究中心。 ',
                                },
                            ]
                        },
                        {
                            title: '张泽葵',
                            img: 'https://static.rongxintech.cn/yx/4/5张泽葵.png',
                            children: [{
                                content: '新浪家居华南大区总经理；十年家居、媒体人，长期、深度服务尚品宅配、简一大理石等众多知名家居企业；深谙品牌营销、o2o新零售之道。',
                            }, ],
                        },

                        {
                            title: '钟鹏',
                            img: 'https://static.rongxintech.cn/yx/4/6钟鹏.png',
                            children: [{
                                content: '现任颜如玉集团CE0, 实现颜如玉渠道转型升级；曾服务知名营销策划机构，10年媒介传播与品牌策划经验；曾负责腾讯智能硬件产品的营销推广，有to B市场成功经验。',
                            }, ],
                        },
                    ]
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>

@mixin placeItem($pos) {
    display: grid;
    place-items: $pos;
}
@mixin justifyAlign($justify, $align, $dir) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
    flex-direction: $dir;
}
.usWrapper {
    padding-top: 0;
    margin-left: vwDecimal(244);
    // background: #F4F4F4;
}
.module {
    /deep/ .title1, .title {
        font-size: vwDecimal(38);
        font-weight: 400;
        color: #1A2C33;
        line-height: vwDecimal(53);
        & + .content {
            margin-top: vwDecimal(20);
        }
    }
    & + .module { 
        margin-top: vwDecimal(120); 
        .title + .cardListWrapper {
            margin-top: vwDecimal(60);
        }
    }
}
.module-1 {
    position: relative;
    top:vwDecimal(-60);
    .card-content {
        box-sizing: border-box;
        width: vwDecimal(956);
        height: vwDecimal(257);
        background: #FFFFFF;
        @include justifyAlign(center ,flex-start ,column);
        padding: 0 vwDecimal(40);
         .title1 + .content {
            margin-top: vwDecimal(20);
        }
         .content .content-item_content {
            font-size: vwDecimal(18);
            font-weight: 400;
            color: #1A2C33;
            line-height: vwDecimal(32);
        }
    }
}
.module-2 {
    .cardListWrapper {
        display: grid;
        grid-template-columns: repeat(3, vwDecimal(294));
        grid-template-rows: auto;
        grid-column-gap: vwDecimal(30);
        grid-row-gap: vwDecimal(40);
        .card {
            // background-color: #ffffff;
            .personInfo {
                display: flex;
                background-image: url('https://static.rongxintech.cn/oryx/tmp/20210308/16152185991033234.png');
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center 40px;
            }
            .card-avatar {
                width: vwDecimal(132);
                height: vwDecimal(233);
                border-radius: 50%;
                img {
                    width: vwDecimal(132);
                    height: vwDecimal(233);
                    object-fit: cover;
                }
            }
            .card-info {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding-bottom: vwDecimal(16);
                .title {
                    font-size: vwDecimal(28);
                    font-weight: 500;
                    color: #1A2C33;
                    line-height: vwDecimal(40);
                }
                .subTitle {
                    font-size: vwDecimal(16);
                    font-weight: 400;
                    color: #666666;
                    line-height: vwDecimal(22);
                    white-space: nowrap;
                }
            }
            .card-content {
                    background-color: #ffffff;
                    height: 126px;
                border-top:1px solid #00A1E1;
                padding: vwDecimal(24) vwDecimal(24) vwDecimal(24) vwDecimal(12);
                /deep/ .content {
                    position: relative;
                    left: vwDecimal(12);
                }
                /deep/ .content-item {
                    & + .content-item {
                        margin-top: vwDecimal(8);
                    }
                }
                /deep/ .content-item_content {
                    font-size: vwDecimal(12);
                    font-weight: 400;
                    color: #666666;
                    line-height: vwDecimal(17);
                    display: list-item;
                }
            }
        }
    }
}
.module-3{
    .title {
        & + .cardListWrapper {
            margin-top: vwDecimal(60);
        }
    }
    
    .cardListWrapper {
        & + .cardListWrapper {
            margin-top: vwDecimal(60);
        }
        .cardList-title {
            width: vwDecimal(240);
            font-size: vwDecimal(24);
            font-weight: 400;
            color: #1A2C33;
            line-height: vwDecimal(33);
            white-space: nowrap;
            & + .cardList-content {
                margin-top: vwDecimal(100);
            }
        }
        .cardList-content {
            display: grid;
            grid-template-columns: repeat(3, vwDecimal(294));
            grid-column-gap: vwDecimal(30);
            grid-row-gap: vwDecimal(100);
            grid-template-rows: auto;
            // grid-auto-flow: column;
            .card {
                background-color: #fff;
                position: relative;
                /deep/ .title1 {
                    font-size: vwDecimal(18);
                    font-weight: 400;
                    color: #1A2C33;
                    line-height: vwDecimal(25);
                    white-space: nowrap;
                }
                /deep/ .card-header {
                    $offsetLeft:calc(50% - #{vwDecimal(60)});
                    position: absolute;
                    width: vwDecimal(120);
                    height: vwDecimal(120);
                    top: vwDecimal(-60);
                    left: $offsetLeft;
                    right: 0;
                    img {
                        width: 120px;
                        height: 120px;
                        // object-fit: cover;
                            object-fit: contain;
                    }
                    // background-color: pink;
                }
                /deep/ .card-content {
                    height: vwDecimal(236);
                    border-top: vwDecimal(4) solid #00A1E1;
                    padding: vwDecimal(72) vwDecimal(24) 0 vwDecimal(24);
                    .title1 {
                        text-align: center;
                        & + .content {
                            margin-top: vwDecimal(15);
                        }
                    }
                    .content-item_content {
                        font-size: vwDecimal(12);
                        font-weight: 400;
                        color: #1A2C33;
                        line-height: vwDecimal(20);
                        padding: 0;
                    }
                    .content {
                        width: unset;
                        grid-template-areas: unset;
                        padding: 0;
                        .content-item {
                            background: #ffffff;
                            padding: 0;
                        }
                    }
                    
                }
            }
        }
        // &:nth-last-child(1) .cardList-content {
        //     grid-template-rows: repeat(2, auto);
        // }
    }
}
@media screen and (max-width: 414px) {
    @mixin titleFs {
        font-size: px2Mb(38);
        font-weight: 400;
        color: #1A2C33;
        line-height: px2Mb(57);
    }
    @mixin subTitleFs {
        font-size: px2Mb(28);
        font-weight: 500;
        color: #1A2C33;
        line-height: px2Mb(40);
    }
    @mixin contentFs {
        font-size: px2Mb(24);
        font-weight: 400;
        color: #1A2C33;
        line-height: px2Mb(38);
    }
    @mixin content-smallFs {
        font-size: px2Mb(20);
        font-weight: 400;
        color: #1A2C33;
        line-height: px2Mb(38);
    }
    @mixin nameFs {
        font-size: px2Mb(64);
        font-weight: 400;
        color: #1A2C33;
        line-height: px2Mb(57);
    }
    @mixin nameSmallFs {
        font-size: px2Mb(28);
        font-weight: 500;
        color: #1A2C33;
        line-height: px2Mb(40);
    }
    @mixin personTitleFs {
        font-size: px2Mb(24);
        font-weight: 400;
        color: #00A1E1;
        line-height: px2Mb(38);
    }
    .usWrapper {
        width: unset;
        margin-left: 0;
        padding-top: px2Mb(40);
        
    }
    .module {
        .title1, .title {
            @include titleFs;
        }
        width: auto;
        & + .module {
            margin-top: px2Mb(120);
            .title + .cardListWrapper {
                margin-top: px2Mb(60);
            }
        }
        /deep/ .card-content {
            width: unset;
            height: unset;
            .title1 {
                @include titleFs;
                & + .content {
                    margin-top: px2Mb(20);
                }
            }
        }
        .content {
            width: unset;
        }
    }
    .module-1 {
        background: #FFFFFF;
        padding: px2Mb(40);
        height: px2Mb(309);
        position: relative;
        top: 0;
        .card-content {
            padding: 0;
            /deep/ .content .content-item_content{
                @include contentFs;
            }
        }
    }
    .module-2 {
        .cardListWrapper {
            grid-auto-flow: row;
            grid-template-columns: unset;
            grid-template-rows: unset;
            grid-column-gap: 0;
            grid-row-gap: px2Mb(60);
            .card {
                .card-avatar {
                    border-radius: 50%;
                    width: px2Mb(324.58);
                    height: px2Mb(549.72);
                    img {
                        width: px2Mb(350.58);
                        height: px2Mb(549.72);
                    }
                }
                &:nth-child(even) .card-header .personInfo {
                    flex-direction: row-reverse;
                }
                .card-info {
                    padding-bottom: px2Mb(40);
                    .title {
                        @include nameFs;
                        & + .subTitle {
                            margin-top: px2Mb(20);
                        }
                    }
                    .subTitle {
                        @include personTitleFs;
                    }
                }
                .card-content {
                    border-top-width:px2Mb(1);
                    padding: px2Mb(40);
                    /deep/ .content-item_content{
                        @include contentFs;
                    }
                    /deep/ .content-item {
                        display: flex;
                        flex-wrap: wrap;
                        & + .content-item {
                            margin-top: px2Mb(10);
                        }
                    }
                    /deep/ .content-item_content {
                        white-space: pre-wrap;
                    }
                }
            }
        }
    }
    .module-3 {
        .title {
            & + .cardListWrapper {
                margin-top: vwDecimal(60);
            }
        }
    
    .cardListWrapper {
        & + .cardListWrapper {
            margin-top: px2Mb(60);
        }
        .cardList-title {
            white-space: nowrap;
            @include subTitleFs;
            & + .cardList-content {
                margin-top: px2Mb(107.5);
            }
        }
        .cardList-content {
            $cardWidth: minmax(px2Mb(268), px2Mb(330));
            display: grid;
            grid-template-columns: repeat(2, #{$cardWidth});
            grid-column-gap: px2Mb(30);
            grid-row-gap: px2Mb(107.5);
            grid-template-rows: auto;
            // grid-auto-flow: column;
            .card {
                background-color: #fff;
                position: relative;
                /deep/ .title1 {
                    @include nameSmallFs;
                    white-space: nowrap;
                    & + .content {
                        margin-top: px2Mb(22);
                    }
                }
                /deep/ .card-header {
                    $offsetLeft:calc(50% - #{px2Mb(67.5)});
                    position: absolute;
                    width: px2Mb(135);
                    height: px2Mb(135);
                    top: px2Mb(-67.5);
                    left: $offsetLeft;
                    border-radius: 50%;
                    right: 0;
                    img {
                        width: px2Mb(135);
                        height: px2Mb(135);
                        object-fit: cover;
                    }
                    // background-color: pink;
                }
                /deep/ .card-content {
                    height: unset;
                    border-top: px2Mb(4) solid #00A1E1;
                    padding: px2Mb(79) px2Mb(20) px2Mb(40) px2Mb(20);
                    .title1 {
                        text-align: center;
                        & + .content {
                            margin-top: px2Mb(22);
                        }
                    }
                    .content-item_content {
                        @include content-smallFs;
                    }
                    .content {
                        width: unset;
                        grid-template-areas: unset;
                        .content-item {
                            background: #ffffff;
                            padding: 0;
                        }
                    }
                }
            }
        }
        // &:nth-last-child(1) .cardList-content {
        //     grid-template-rows: repeat(3, auto);
        // }
        // &:nth-last-child(2) .cardList-content {
        //     grid-template-rows: repeat(2, auto);
        // }
    }
}
}
</style>