<template>
    <div class="sceneWrapper">
        <card v-for="(item,i) in moduleData" :key="i" :id="item.id">
            <div slot="header">
                <div class="header-title">{{item.title}}</div>
                <div class="header-content">{{item.content}}</div>
            </div>
            <div class="contentWrapper">
                <card-content :cardContentData="item.children">
                    <template slot-scope="scope" slot="contentItemExtra">
                        <div class="img1">
                            <img :src="item.img" alt="">
                        </div>
                    </template>
                </card-content>
                <div class="img">
                    <img :src="item.img" alt="">
                </div>
            </div>
        </card>
        <solution-expert :content="solutionExpertContent"></solution-expert>
    </div>
</template>

<script>
import Card from '../../../components/Card.vue'
import CardContent from '../../../components/CardContent.vue'
import SolutionExpert from '../../../components/SolutionExpert.vue'
export default {
    components:{CardContent,Card,SolutionExpert},
    data() {
        return {
            solutionExpertContent:'如果您的商业正处于数字化转型阶段，尽快享受数字化带来的商业机会吧，我们将帮您实现商业持续增长。联系我们深入了解全渠道的客制化解决方案',
            moduleData: [
                {
                    title:'有信云播 ',
                    img:'https://static.rongxin.tech/test/tmp/20210304/16148642031096556.png',
                    content:'有信云播是一套具有锁粉、裂变、分佣、私域直播功能的独立社交商城系统。可按照微商和连锁门店需求增加微商系统和门店管理功能。',
                    children:[
                        {   title:'直播+短视频一体化 ',
                            content:'直播同时生成录播短视频。每次开播更新都有提醒。', 
                        },
                        {   title:'私域直播打通社交商城 ',
                            content:'基于微信生态，每一家企业独立配置系统，私域运营。在云播系统内形成全部业务和数据闭环。',
                        },
                        {   title:'领先的分享锁定关系 ',
                            content:'无论是商城分享、产品分享，还是直播链接分享，都可以锁定分享者和被分享者关系，便于分佣获利。',
                        },
                        {   title:'二级分润模式 ',
                            content:'默认二级分润模式，可以是“代理-会员”“店员-会员”“会员-会员”之间的分佣模式。',
                        },
                        {   title:'人人皆可成为主播 ',
                            content:'只要符合品牌商城直播开通资格，即可随时随地直播卖货。',
                        },
                        {   title:'直播培训+ 商学院 ',
                            content:'便捷开展面向代理、店员的内外部直播培训，后台产生观看人数、观看时长等数据统计，并开展课后问卷调查。',
                        },
                    ],
                    id:'live',
                },
                {
                    title:'营销 ',
                    img:'https://static.rongxin.tech/test/tmp/20210304/16148642193223996.png',
                    id:'marketing',
                    content:'轻量化、便捷化、智慧化，功能聚焦社交电商行业',
                    children:[
                        {
                            title:'裂变小店 ',
                            children:[
                                {
                                    title:'分销赋能 ',
                                    row:true,
                                    content:'3分钟开通个人小店，分销体系云端同步',
                                },
                                {
                                    title:'裂变赋能 ',
                                    row:true,
                                    content:'以种子用户为起点，微信生态下无限裂变',
                                },
                                {
                                    title:'私域流量赋能 ',
                                    row:true,
                                    content:'个人小店激发的传播热情，裂变导流沉淀用户',
                                },
                                {
                                    title:'整合赋能 ',
                                    row:true,
                                    content:'实现订单、发货、物流、客户、财务的集成统一管理',
                                },
                            ]
                        },
                        {
                            title:'素材中心 ',
                            children:[
                                {
                                    title:'传播赋能 ',
                                    row:true,
                                    content:'官宣素材及时传达，一键发圈高效传播',
                                },
                            ],
                        },
                        {
                            title:'数字动销 ',
                            children:[
                                {
                                    title:'大数据赋能' ,
                                    row:true,
                                    content:'用户大数据，实现数据资产沉淀与管理',},
                                {
                                    title:'AI赋能 ',
                                    row:true,
                                    content:'结合人工智能，实现客户精细化管理',
                                },
                                {
                                    title:'动销赋能 ',
                                    row:true,
                                    content:'数字技术赋能，实现最低成本转换',
                                },
                            ]
                        },
                    ]
                },
                {
                    title:'社群 ',
                    img:'https://static.rongxin.tech/test/tmp/20210304/16148642258594508.png',
                    id:'association',
                    content:'全渠道访客转化社群沉淀，由邀约到沉淀，实现一体化运营，撬动活跃指数',
                    children:[
                        {
                            title:'全渠道会员沉淀 ',
                            content:'转发、海报、专属码、落地页捕获访客，消费者生命周期管理转化沉淀会员社群，数据清洗、标签建立、数据沉淀',
                        },
                        {
                            title:'消费者生命周期管理 ',
                            content:'纵向拓宽个体消费域，吸引消费者注意力、激发购买行为发生、客户关怀提升忠诚度三步走',
                        },
                        {
                            title:'会员社群运营 ',
                            content:'海量社群运营工具，助力撬动活跃指数（群雷达、群直播、群购价、群拼团等等）',
                        },
                    ]
                },
                {   
                    title:'仓库 ',
                    img:'https://static.rongxin.tech/test/tmp/20210304/16148642316373235.png',
                    id:'storehouse',
                    content:'客制化云仓系统，在线兑仓、移仓，仓库数据记录，简易操作，提高效率',
                    children:[
                        {   
                            title:'库存共享 ',
                            content:'线上线下全渠道库存共享，降低库存成本，提高处理效率',
                        },
                        {   
                            title:'弹性分配 ',
                            content:'就近分配库存，降低运输成本，让库存分配更灵活',
                        },
                        {   
                            title:'库存预测 ',
                            content:'云仓数据闭环，依托大数据预测库存使用，降低仓储成本',
                        },
                    ]
                },
                {
                    title:'物流 ',
                    img:'https://static.rongxin.tech/test/tmp/20210304/16148642381702290.png',
                    id:'logistics',
                    content:'全渠道订单路由，实现多系统之间订单和物流信息的流转和同步，节约物流成本',
                    children:[
                        {
                            title:'路由管理 ',
                            content:'大数据赋能，自动优化物流的中转线路和物流方式，通过优先算法找出最佳效益路径',
                        },
                        {
                            title:'多维度组合 ',
                            content:'线上线下多渠道、多种类多产品、多个仓库等维度组合，自动找出最优订单解决方案',
                        },
                        {
                            title:'智能发货 ',
                            content:'实现了不同渠道商品的云仓合并发货，同渠道商品的拆单合并发货，节约会员的物流成本',
                        },
                    ]
                },
                {
                    title:'支付 ',
                    img:'https://static.rongxin.tech/test/tmp/20210304/16148642436729504.png',
                    id:'pay',
                    content:'支付场景移动化',
                    children:[
                        {
                            title:'移动化',
                            children:[
                                {   content:'轻POS助力，打破收银台束缚',    },
                                {   content:'扫码购物免排队，移动支付更省心',   },
                            ],
                        },
                        {
                            title:'场景化',
                            children:[
                                {   content:'店内看品，掌中选品更方便', },
                                {   content:'支付场景移动化提升购物效率',   },
                            ],
                        },
                        {
                            title:'智能化',
                            children:[
                                {   content:'全时段全空间触达消费者',   },
                            ],
                        },
                    ],
                },
                {
                    title:'客服 ',
                    img:'https://static.rongxin.tech/test/tmp/20210304/16148642494062179.png',
                    id:'customerService',
                    content:'智能AI客服，依托大数据技术实现智能客户跟进与AI分析建议',
                    children:[
                        {   
                            title:'智能AI建议 ',
                            content:'通过大数据分析访客来源、记录、轨迹等，自动生成并推送客户跟进建议，帮助渠道商及时跟进访客，实现会员转化沉淀',
                        },
                        {   
                            title:'自动回复 ',
                            content:'给客服系统设置好自动回复和常见问题，产品问答、文字识别、个性化记忆，及时对接与安抚消费者',
                        },
                        {   
                            title:'排队进度、客服接入提醒 ',
                            content:'买家在等待客服回复的过程中，可以实时查看排队进度，并且客服接入后也会进行系统提示',
                        },
                    ],
                },
                {
                    title:'税务 ',
                    img:'https://static.rongxin.tech/test/tmp/20210304/16148642557283614.png',
                    id:'taxation',
                    content:'全生命周期的税务管理，灵活规避财税风险',
                    children:[
                        {   
                            title:'税务管理系统 ',
                            content:'全生命周期的税务管理',
                        },
                        {   
                            title:'财税解决方案 ',
                            content:'有效管理企业税务风险',
                        },
                        {   
                            title:'社会化分销 ',
                            content:'灵活的结算模式，规避财税风险',
                        },
                    ]
                },
                {
                    title:'发票 ',
                    img:'https://static.rongxin.tech/test/tmp/20210304/16148642668349310.png',
                    id:'bill',
                    content:'面向票务行业的客制化解决方案，接入方便、业务合规、技术支持资源完备',
                    children:[
                        {
                            title:'开票入口 ',
                            children:[
                                { content:'下单页面发票开具',},
                                {content:'订单详情页发票开具',},
                            ],
                        },
                        {
                            title:'发票管理 ',
                            children:[
                                {content:'电子发票在线查看及冲红处理',}
                            ]
                        }
                    ]
                },
                {
                    title:'追踪',
                    // img:'https://static.rongxin.tech/test/tmp/20210304/16148642668349310.png',
                    img:'https://static.rongxin.tech/test/tmp/20210304/16148646944752770.png',
                    id:'origin',
                    content:'全网采集、分析访客大数据，解决谁访问、访问来源、访问轨迹、意向标记等难题',
                    children:[
                        {title:'雷达追踪 ',
                        content:'技术赋能，最精准的锁客方式；群雷达，锁定来自群的意向客户；客户雷达，微信生态下所有访客轨迹提取',},
                        {title:'客户名片 ',
                        content:'自动提取访客信息，最快速的攫取客户价值，全面提起目标用户的性别、微信名称、微信ID、手机号码、所在区域',},
                        {title:'客户管理 ',
                        content:'AI助力，最精细化的客户管理 客户数据模型AI维护建议；自动同步事件到日程；微信服务消息提醒；轻松高效客户管理',},
                    ]
                },
            ]
        }
    },      
}
</script>

<style lang="scss" scoped>
.module {
    margin-top: vwDecimal(120);
}
.card + .card {
    padding-top: vwDecimal(120);
}

$widthList:540,541,541,541,541,543,565,543,541,543;
$heightList:1030,705,495,399,463,443,554,341,274,527;
@each $width in $widthList {
    $i: index($list: $widthList, $value: $width);
    .card:nth-child(#{$i}) .contentWrapper .img {
        width: #{$width}px;
        $value:nth($heightList, $i);
        height: #{$value}px;
        margin-left: vwDecimal(40);
        margin-right: 0;
        img {
            width: #{$width}px;
            height: #{nth($heightList, $i)}px;
            object-fit: cover;
        }
    }
}
.card:nth-child(even) .contentWrapper {
    flex-direction: row-reverse;
    margin-left: 0;
    width: 820px;
    .img {
        margin-right: vwDecimal(40);
        margin-left: 0;
    }
}
.card:nth-child(3) .contentWrapper .img {
    width: 541px;
    height: 495px;
    margin-left: vwDecimal(40);
    img {
        width: 541px;
        height: 495px;
        object-fit: cover;
    }
}
.card:nth-child(4) .contentWrapper .img {
    width: 541px;
    height: 399px;
    img {
        width: 541px;
        height: 399px;
        object-fit: cover;
    }
}
.card:nth-child(5) .contentWrapper .img {
    width: 541px;
    height: 463px;
    margin-left: vwDecimal(40);
    img {
        width: 541px;
        height: 399px;
        object-fit: cover;
    }
}
.card:nth-child(6) .contentWrapper .img {
    width: 543px;
    height: 443px;
    img {
        width: 543px;
        height: 443px;
        object-fit: cover;
    }
}
.card:nth-child(8) .contentWrapper .img {
    width: 543px;
    height: 443px;
    img {
        width: 543px;
        height: 443px;
        object-fit: cover;
    }
}
.card:nth-child(9) .contentWrapper .img {
    width: 543px;
    height: 443px;
    margin-left: vwDecimal(40);
    img {
        width: 543px;
        height: 443px;
        object-fit: cover;
    }
}
.card:nth-child(10) .contentWrapper .img {
    width: 543px;
    height: 443px;
    img {
        width: 543px;
        height: 443px;
        object-fit: cover;
    }
}
.card-header {
    .header-title {
        font-weight: bold;
            color: #1A2C33;
            font-size: vwDecimal(38);
            line-height: vwDecimal(53);
            & + .header-content { margin-top: vwDecimal(20); }
    }
    .header-content {
        font-weight: 400;
            color: #1A2C33;
            font-size: vwDecimal(16);
            line-height: vwDecimal(32);
            margin-bottom: vwDecimal(40);
    }
}
.card-content  {
    /deep/ .content .content-item {
        .otherWrapper {
            display: flex;
            justify-content: space-between;
        }
        &:nth-child(even) .otherWrapper {
            flex-direction: row-reverse;
        }
        &_title {
            font-weight: 400;
            color: #00A1E1;
            font-size: vwDecimal(24);
            line-height: vwDecimal(33);
            & + .content-item_content { margin-top: vwDecimal(20); }
            & + .content-item_children { margin-top: vwDecimal(20); }
        }
        &_content {
            font-weight: 400;
            color: #1A2C33;
            font-size: vwDecimal(16);
            line-height: vwDecimal(32);
            & + .content-item_title { margin-top: vwDecimal(20); }
        }
        
        &_children_content, &_children_children {
            font-weight: 400;
            color: #1A2C33;
            font-size: vwDecimal(16);
            line-height: vwDecimal(32);
            &_children_title {
                font-size: vwDecimal(16);
                line-height: vwDecimal(32);
                font-weight: bold;
            }
            &_children_children + .content-item_children_children {
                margin-top: vwDecimal(10);
            }
        }
        &_children_children {
            display: flex;
            &_title {
                white-space: nowrap;
            }
        }
        &_children_title.row { font-weight: bold; }
    }
    /deep/ .content-item + .content-item {
        margin-top: vwDecimal(40);
    }
}
.img {
    // width: vwDecimal(542);
    // height: vwDecimal(289);
    // margin-left: vwDecimal(40);
}
.contentWrapper {
    display: flex;
    margin-top: vwDecimal(40);
}
@media screen and (max-width: 414px) {
    @mixin title {
        font-size: px2Mb(38);
        font-weight: 400;
        color: #1A2C33;
        line-height: px2Mb(57);

    }
    @mixin subTitle {
        font-size: px2Mb(24);
        font-weight: 400;
        color: #00A1E1;
        line-height: px2Mb(33);
    }
    @mixin content {
        font-size: px2Mb(24);
        font-weight: 400;
        color: #5E6B70;
        line-height: px2Mb(38);
    }
    .sceneWrapper {
        padding: px2Mb(60) px2Mb(30);
        width: unset;
        margin-left: 0;
    }
    .img {
        display: none;
    }
    .contentWrapper {
        display: flex;
        margin-top: px2Mb(40);
    }
    .card {
        margin-bottom: px2Mb(40);
        & + .card {
            margin-top: px2Mb(80);
        }
    }
    .card:nth-child(even) .contentWrapper {
        flex-direction: unset;
        width: auto;
    }
    .card-header {
        .header-title {
            @include title;
            & + .header-content {  margin-top: px2Mb(18);  }
        }
        .header-content {
            @include content;
            margin-bottom: px2Mb(40);
        }
    }
    .card-content  {
        /deep/ .content {
            display: grid;
            grid-template-columns: repeat(2, minmax(#{px2Mb(268)},#{px2Mb(324)}));
            grid-template-rows: auto;
            grid-column-gap: px2Mb(40);
        }
        /deep/ .content .content-item {
            &:nth-child(2) {
                margin-top: 0;
            }
            &:nth-child(odd) {
                margin-left: 0;
            }
            &_title {
                @include subTitle;
                & + .content-item_content { margin-top: px2Mb(20);  }
                & + .childrernWrapper { margin-top: px2Mb(20); }
            }
            &_content {
                @include content;
                & + .content-item_title { 
                    margin-top: vwDecimal(20); 
                }
            }
            &_children_content, &_children_children {
                @include content;
                &_children_children + .content-item_children_children {
                    margin-top: vwDecimal(10);
                }
            }
            &_children_children {
                display: flex;
                &_title {
                    white-space: nowrap;
                }
            }
            &_children_title {
                @include content;
                font-weight: bold;
            }
        }
        /deep/ .content-item + .content-item {
            margin-top: px2Mb(40);
        }
    }
}
</style>