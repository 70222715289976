import Vue from 'vue'
import Router from 'vue-router'
// import ShowCardContent from './views/ShowCardContent.vue'
import ContentFrame from './containers/ContentFrame.vue'
import NavBar from './components/NavBar.vue'
import Wrap from './components/Wrap.vue'

import Home from './views/Home.vue'
import News from './views/News.vue'
import Team from './views/Team.vue'
import Us from './views/Us.vue'
import Custom from './views/Custom.vue'
import Service from './views/Service.vue'
import MakeUp from './views/Solution/industry/makeUp.vue'
import DailyUse from './views/Solution/industry/dailyUse.vue'
import Health from './views/Solution/industry/health.vue'
import Alcohol from './views/Solution/industry/alcohol.vue'
import Single from './views/Solution/channel/single.vue'
import Multiple from './views/Solution/channel/mutiple.vue'
import All from './views/Solution/channel/all.vue'
import Scene from './views/Solution/scene/scene.vue'

import Selldistribute from './views/Product/Frame1/Selldistribute.vue'
import Distribution from './views/Product/Frame1/Distribution.vue'
import Retail from './views/Product/Frame1/Retail.vue'
import Fields from './views/Product/Frame1/Fields.vue'
import Marketing from './views/Product/Frame2/Marketing.vue'
import CustomDecimal from './views/Product/Frame2/Custom.vue'
import Goods from './views/Product/Frame2/Goods.vue'
import Order from './views/Product/Frame2/Order.vue'
import SettleAccount from './views/Product/Frame2/SettleAccount.vue'
import Consultative from './components/Consultative.vue'
import HelpCenter from './views/helpCenter/HelpCenter.vue'
import Article from './views/helpCenter/Article.vue'
import ArticleList from './views/helpCenter/ArticleList.vue'


Vue.use(Router)

const originalReplace = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalReplace.call(this, location)
}

export default new Router({
    mode: 'hash',
    // linkActiveClass: 'active's
    linkExactActiveClass: 'active',
    routes: [
        {
            path: '/helpCenter/articleList', name: 'helpCenter', meta: { title: '有信科技帮助中心' }, component: HelpCenter,
            children: [
                {
                    path: '/helpCenter/article/:id',
                    name: 'article',
                    component: Article
                },
                {
                    path: '/helpCenter/articleList/:id?',
                    name: 'articleList',
                    component: ArticleList
                }
            ]
        },

        { 
            path: '/', name: 'home', meta: { title: '广州有信科技有限公司' }, component: Wrap,
            children: [
                {path: '/', name: 'home', meta: { title: '广州有信科技有限公司' }, component: Home},
                {
                    path: '/news',
                    name: 'news',
                    meta: {
                        title: '新闻动态-广州有信科技有限公司'
                    },
                    components: {
                        default:ContentFrame,
                        content:News,
                        navBar: NavBar,
                    },
                    props:{
                        navBar: {
                            title: '新闻资讯',
                            btn: '联系我们',
                            childrenData: ['连锁企业优秀供应商排名第一', '十大私域流量服务商', 'ADMEN国际双项大奖', '更多动态'],
                            url:[
                                'https://mp.weixin.qq.com/s/Cjc6zRNMj08RepDzab0-SA',
                                'https://mp.weixin.qq.com/s/MhXjIo--kTG07_2OKD3Vcw',
                                'https://mp.weixin.qq.com/s/zLfsQPyQcgtI_48GYnEUvw',
                                '/consultative'
                            ],
                            activeIdx:0,
                        },
                        default: {
                            maskData: ['关注行业资讯 ', ' 聚焦数字化升级 '],
                            divider: true,
                            maskMbData:'新闻资讯',
                             // img: String,
                        }
                    }
                },
                {
                    path: '/team',
                    name: 'team',
                    meta: {
                        title: '关于我们-广州有信科技有限公司'
                    },
                    components: {
                        default:ContentFrame,
                        content:Team,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '关于有信',
                            btn: '联系我们',
                            childrenData: ['关于我们', '有信团队', '新闻动态'],
                            url: ['/team', '/us', '/news'],
                            activeIdx: 0,
                        },
                        default: {
                            maskData: '全渠道数字化解决方案供应商',
                            divider:true,
                            maskMbData:'关于有信',
                            img: 'https://static.rongxintech.cn/yx/team/team-banner.png',
                            // img: String,
                        }
                    }
                },
                {
                    path: '/us',
                    name: 'us',
                    meta: {
                        title: '有信团队-广州有信科技有限公司'
                    },
                    components: {
                        default:ContentFrame,
                        content:Us,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '关于有信',
                            btn: '联系我们',
                            childrenData: ['关于我们', '有信团队', '新闻动态'],
                            url: ['/team', '/us', '/news'],
                            activeIdx:1,
                        },
                        default: {
                            maskData: ['有信以市场的认可为荣 ', ' 同时也为团队的才华感到自豪。 '],
                            divider: true,
                            maskMbData:'关于有信',
                            img: 'https://static.rongxintech.cn/yx/us/us-banner.png',
                            // img: String,
                        }
                    }
                },
                {
                    path: '/custom',
                    name: 'custom',
                    meta: {
                        title: '客户案例-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: Custom,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '客户案例',
                            btn: '联系我们',
                            childrenData: ['成功客户案例', '客制化解决方案', '长期主义服务方式', '客户成功规划'],
                            url: ['/custom', '/service#custom', '/service#longterm', '/service#schematization', ],
                            hashArr: ['', '#custom', '#longterm', '#schematization'],
                            activeIdx: 0,
                        },
                        default: {
                            maskData: '更懂中国商业、更懂客户、更懂消费者场景',
                            mask: 'rgba(0, 161, 225, 0.3)',
                            maskMbData:'客户案例',
                            img: 'https://static.rongxintech.cn/yx/custom/custom-banner/customer-banner.png',
                        }
                    }
                },
                {
                    path: '/service',
                    name: 'service',
                    meta: {
                        title: '服务与支持-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: Service,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '服务与支持',
                            btn: '联系我们',
                            childrenData: ['什么是云计算？', '全渠道是什么？', '什么是客制化？',
                                '客制化解决方案', '长期主义服务方式', '客户成功规划', '客户案例', '信· 资讯'
                            ],
                            url: ['https://zhuanlan.zhihu.com/p/354477430', 'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA', 'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ',
                                '/service#custom', '/service#longterm', '/service#schematization', '/custom', '/consultative'],
                            activeIdx: 3,
                            hashArr: [null, null, null, '#custom','#longterm','#schematization',null]
                        },
                        default: {
                            maskData: '客制化解决方案，长期主义服务方式，陪伴式客户成功',
                            mask: 'rgba(245, 22, 116, 0.3)',
                            maskMbData:'服务与支持',
                            img: 'https://static.rongxintech.cn/yx/service/service-banner.png',
                            // img: String,
                        }
                    }
                },
                {
                    path: '/consultative',
                    name: 'consultative',
                    meta: {
                        title: '了解更多-广州有信科技有限公司'
                    },
                    components: {
                        content: Consultative,
                    },
                },
        
                {
                    path: '/solution/industry/makeUp',
                    meta: {
                        title: '美妆行业解决方案-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: MakeUp,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '按行业',
                            btn: '观看演示',
                            childrenData: ['什么是云计算？', '全渠道是什么？', '什么是客制化？', '美妆行业解决方案', '日化行业解决方案', '大健康行业解决方案', '酒水行业解决方案'],
                            url:[
                                'https://zhuanlan.zhihu.com/p/354477430', 'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA', 'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ',
                                '/solution/industry/makeUp', '/solution/industry/dailyUse', '/solution/industry/health', '/solution/industry/alcohol',
                            ],
                            activeIdx:3,
                        },
                        default: {
                            maskData: '从拉新转化到存量激活的全渠道数字化解决方案',
                            mask: 'rgba(85, 7, 193, 0.3)',
                            maskMbData:'美妆行业解决方案',
                            img: 'https://static.rongxintech.cn/yx/solution/makeUp-banner.png',
                            // img: String,
                        }
                    },
                }, {
                    path: '/solution/industry/dailyUse',
                    meta: {
                        title: '日化行业解决方案-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: DailyUse,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '按行业',
                            btn: '观看演示',
                            childrenData: ['什么是云计算？', '全渠道是什么？', '什么是客制化？', '美妆行业解决方案', '日化行业解决方案', '大健康行业解决方案', '酒水行业解决方案'],
                            url: [
                                'https://zhuanlan.zhihu.com/p/354477430', 'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA', 'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ',
                                '/solution/industry/makeUp', '/solution/industry/dailyUse', '/solution/industry/health', '/solution/industry/alcohol',
                            ],
                            activeIdx: 4,
                        },
                        default: {
                            maskData: ['以消费者为中心，全渠道触点激活', '场景驱动的业务创新， 数字化驱动业务增长 '],
                            mask: 'rgba(33, 78, 188, 0.3)',
                            maskMbData:'日化行业解决方案',
                            img: 'https://static.rongxintech.cn/yx/solution/dailyuse-banner.png',
                            // img: String,
                        }
                    },
                }, {
                    path: '/solution/industry/health',
                    meta: {
                        title: '大健康行业解决方案-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: Health,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '按行业',
                            btn: '观看演示',
                            childrenData: ['什么是云计算？', '全渠道是什么？', '什么是客制化？', '美妆行业解决方案', '日化行业解决方案', '大健康行业解决方案', '酒水行业解决方案'],
                            url: [
                                'https://zhuanlan.zhihu.com/p/354477430', 'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA', 'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ',
                                '/solution/industry/makeUp', '/solution/industry/dailyUse', '/solution/industry/health', '/solution/industry/alcohol',
                            ],
                            activeIdx: 5,
                        },
                        default: {
                            maskData: '优化企业内部供应链，打通大健康业务供应链、 信息链',
                            mask: 'rgba(57, 226, 108, 0.3)',
                            maskMbData:'大健康行业解决方案',
                            img: 'https://static.rongxintech.cn/yx/solution/health-banner.png',
                            // img: String,
                        }
                    },
                }, {
                    path: '/solution/industry/alcohol',
                    meta: {
                        title: '酒水行业解决方案-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: Alcohol,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '按行业',
                            btn: '观看演示',
                            childrenData: ['什么是云计算？', '全渠道是什么？', '什么是客制化？', '美妆行业解决方案', '日化行业解决方案', '大健康行业解决方案', '酒水行业解决方案'],
                            url: [
                                'https://zhuanlan.zhihu.com/p/354477430', 'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA', 'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ',
                                '/solution/industry/makeUp', '/solution/industry/dailyUse', '/solution/industry/health', '/solution/industry/alcohol',
                            ],
                            activeIdx: 6,
                        },
                        default: {
                            maskData: [ '以数字化新零售重构酒水人、 货、 场， ',
                                '快速搭建全渠道系统'
                            ],
                            mask: 'rgba(192, 11, 58, 0.3)',
                            maskMbData:'酒水行业解决方案',
                            img: 'https://static.rongxintech.cn/yx/solution/alcohol-banner.png',
                            // img: String,
                        }
                    },
                },
        
                {
                    path: '/solution/channel/single',
                    meta: {
                        title: '单渠道解决方案-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: Single,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '按渠道',
                            btn: '观看演示',
                            childrenData: ['什么是云计算？', '全渠道是什么？', '什么是客制化？', '单渠道解决方案', '多渠道解决方案', '全渠道解决方案'],
                            url: ['https://zhuanlan.zhihu.com/p/354477430', 'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA', 'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ', '/solution/channel/single', '/solution/channel/multiple', '/solution/channel/all', ],
                            activeIdx: 3,
                        },
                        default: {
                            maskData: '为单一渠道赋能，助力企业多渠道、 跨渠道发展',
                            mask: 'rgba(234, 132, 32, 0.3)',
                            maskMbData:'单渠道解决方案',
                            img: 'https://static.rongxintech.cn/yx/solution/solution-single-banner.png',
                            // img: String,
                        }
                    },
                }, {
                    path: '/solution/channel/multiple',
                    meta: {
                        title: '多渠道解决方案-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: Multiple,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '按渠道',
                            btn: '观看演示',
                            childrenData: ['什么是云计算？', '全渠道是什么？', '什么是客制化？', '单渠道解决方案', '多渠道解决方案', '全渠道解决方案'],
                            url: ['https://zhuanlan.zhihu.com/p/354477430', 'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA', 'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ',
                                '/solution/channel/single', '/solution/channel/multiple', '/solution/channel/all', ],
                            activeIdx: 4,
                        },
                        default: {
                            maskData: '打破渠道间壁垒，融合多个渠道效能， 为企业降本增效',
                            mask: 'rgba(245, 213, 22, 0.3)',
                            maskMbData:'多渠道解决方案',
                            img: 'https://static.rongxintech.cn/yx/solution/solution-multiple-banner.png',
                            // img: String,
                            // img: String,
                        }
                    },
                }, {
                    path: '/solution/channel/all',
                    meta: {
                        title: '全渠道解决方案-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: All,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '按渠道',
                            btn: '观看演示',
                            childrenData: ['什么是云计算？', '全渠道是什么？', '什么是客制化？', '单渠道解决方案', '多渠道解决方案', '全渠道解决方案'],
                            url: ['https://zhuanlan.zhihu.com/p/354477430', 'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA', 'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ', '/solution/channel/single', '/solution/channel/multiple', '/solution/channel/all', ],
                            activeIdx: 5,
                        },
                        default: {
                            maskData: '打通数据闭环，助力企业实现传统行业数字化转型',
                            mask: 'rgba(245, 213, 22, 0.3)',
                            maskMbData:'全渠道解决方案',
                            img: 'https://static.rongxintech.cn/yx/solution/all-banner.png',
                            // img: String,
                        }
                    },
                }, {
                    path: '/solution/scene/scene',
                    name:'scene',
                    meta: {
                        title: '全商业应用场景-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: Scene,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '场景解决方案',
                            btn: '观看演示',
                            childrenData: ['直播', '营销', '社群', '仓库', '物流', '支付', '客服', '税务', '发票', '追踪', ],
                            url: [ 
                            '/solution/scene/scene#live', '/solution/scene/scene#marketing', '/solution/scene/scene#association', '/solution/scene/scene#storehouse', '/solution/scene/scene#logistics', '/solution/scene/scene#pay', '/solution/scene/scene#customerService', '/solution/scene/scene#taxation', '/solution/scene/scene#bill', '/solution/scene/scene#origin', ],
                            activeIdx: 0,
                            hashArr: ['#live', '#marketing', '#association', '#storehouse', '#logistics', '#pay', '#customerService', '#taxation', '#bill', '#origin']
                        },
                        default: {
                            maskData: '满足行业客户的个性化场景需求，提供全场景客制化服务',
                            mask: 'rgba(245, 213, 22, 0.3)',
                            maskMbData: '全商业应用场景',
                            img: 'https://static.rongxin.tech/test/tmp/20210304/16148663607835026.png',
                        }
                    },
                },
        
                {
                    path: '/product/frame1/selldistribute',
                    meta: {
                        title: 'X·OCD-经销渠道-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: Selldistribute,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '按渠道性质分',
                            btn: '观看演示',
                            childrenData: ['什么是云计算？', '全渠道是什么？', '什么是客制化？', 
                            'X·OCD-经销渠道', 'X·OCD-门店渠道', 'X·OCD-社交渠道', 'X·OCD-公域私域'],
                            url: ['https://zhuanlan.zhihu.com/p/354477430', 'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA', 'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ',
                            '/product/frame1/selldistribute', '/product/frame1/retail', '/product/frame1/distribution', '/product/frame1/fields', ],
                            activeIdx: 3,
                        },
                        default: {
                            maskData: '人货钱场单新零售数字化管理系统',
                            mask: 'rgba(0, 255, 172, 0.3)',
                            maskMbData: 'X·OCD-经销渠道',
                            img: 'https://static.rongxintech.cn/yx/product/frame1/selldistribution/product-jingxiao-banner.png',
                        }
                    },
                }, {
                    path: '/product/frame1/distribution',
                    meta: {
                        title: 'X·OCD-社交渠道-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: Distribution,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '按渠道性质分',
                            btn: '观看演示',
                            childrenData: ['什么是云计算？', '全渠道是什么？', '什么是客制化？', 
                            'X·OCD-经销渠道', 'X·OCD-门店渠道', 'X·OCD-社交渠道', 'X·OCD-公域私域'],
                            url: ['https://zhuanlan.zhihu.com/p/354477430', 'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA', 'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ',
                            '/product/frame1/selldistribute', '/product/frame1/retail', '/product/frame1/distribution', '/product/frame1/fields', ],
                            activeIdx: 5,
                        },
                        default: {
                            maskData: '打造品牌自有的私域流量，实现裂变式、扩展式传播',
                            mask: 'rgba(24, 121, 185, 0.3)',
                            maskMbData: 'X·OCD-社交渠道',
                            img: 'https://static.rongxintech.cn/yx/product/frame1/social/product-shejiao-banner.png',
                            // img: String,
                        }
                    },
                }, {
                    path: '/product/frame1/retail',
                    meta: {
                        title: 'X·OCD-门店渠道-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: Retail,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '按渠道性质分',
                            btn: '观看演示',
                            childrenData: ['什么是云计算？', '全渠道是什么？', '什么是客制化？', 
                            'X·OCD-经销渠道', 'X·OCD-门店渠道', 'X·OCD-社交渠道', 'X·OCD-公域私域'],
                            url: ['https://zhuanlan.zhihu.com/p/354477430', 'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA', 'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ',
                            '/product/frame1/selldistribute', '/product/frame1/retail', '/product/frame1/distribution', '/product/frame1/fields', ],
                            activeIdx: 4,
                        },
                        default: {
                            maskData: '终端门店一体化管控， 助推门店的服务与体验升级',
                            mask: 'rgba(219, 183, 4, 0.3)',
                            maskMbData: 'X·OCD-门店渠道',
                            img: 'https://static.rongxintech.cn/yx/product/frame1/store/product-mendian-banner.png',
                            // img: String,
                        }
                    },
                }, {
                    path: '/product/frame1/fields',
                    meta: {
                        title: 'X·OCD-公域私域-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: Fields,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '按渠道性质分',
                            btn: '观看演示',
                            childrenData: ['什么是云计算？', '全渠道是什么？', '什么是客制化？', 
                            'X·OCD-经销渠道', 'X·OCD-门店渠道', 'X·OCD-社交渠道', 'X·OCD-公域私域'],
                            url: ['https://zhuanlan.zhihu.com/p/354477430', 'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA', 'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ',
                            '/product/frame1/selldistribute', '/product/frame1/retail', '/product/frame1/distribution', '/product/frame1/fields', ],
                            activeIdx: 6,
                        },
                        default: {
                            maskData: '联合快手， 行业内第一款真正打通公域和私域的数字化系统 ',
                            mask: 'rgba(201, 83, 255, 0.3)',
                            maskMbData: 'X·OCD-公域私域',
                            img: 'https://static.rongxintech.cn/yx/product/frame1/fields/product-lingyu-banner.png',
                        }
                    },
                },
        
                {
                    path: '/product/frame2/Marketing',
                    meta: {
                        title: 'X·OCD-全渠道营销-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: Marketing,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '全渠道场景',
                            btn: '观看演示',
                            childrenData: ['什么是云计算？', '全渠道是什么？', '什么是客制化？', 
                            'X·OCD-全渠道营销', 'X·OCD-全渠道商品', 'X·OCD-全渠道订单', 'X·OCD-全渠道结算', 'X·OCD-全渠道客户'],
                            url: ['https://zhuanlan.zhihu.com/p/354477430', 'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA', 'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ',
                            '/product/frame2/Marketing', '/product/frame2/goods', '/product/frame2/order', '/product/frame2/settleAccount', '/product/frame2/custom', ],
                            activeIdx:3,
                        },
                        default: {
                            maskData: ['无缝对接客户需求','在合适的时间，以合适的方式，传递合适的信息'],
                            mask: 'rgba(204, 45, 45, 0.3)',
                            maskMbData: 'X·OCD-全渠道营销',
                            img: 'https://static.rongxintech.cn/yx/product/frame2/marketing/marketing-banne.png',
                        }
                    },
                }, {
                    path: '/product/frame2/custom',
                    meta: {
                        title: 'X·OCD-全渠道客户-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: CustomDecimal,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '全渠道场景',
                            btn: '观看演示',
                            childrenData: ['什么是云计算？', '全渠道是什么？', '什么是客制化？', 
                            'X·OCD-全渠道营销', 'X·OCD-全渠道商品', 'X·OCD-全渠道订单', 'X·OCD-全渠道结算', 'X·OCD-全渠道客户'],
                            url: ['https://zhuanlan.zhihu.com/p/354477430', 'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA', 'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ',
                            '/product/frame2/Marketing', '/product/frame2/goods', '/product/frame2/order', '/product/frame2/settleAccount', '/product/frame2/custom'],
                            activeIdx: 7,
                        },
                        default: {
                            maskData: ['邀约-沉淀，通过消费者唯一ID的建立，','为消费者在不同的渠道提供标准的服务，实现一体化运营'],
                            mask: 'rgba(14, 124, 231, 0.3)',
                            maskMbData: 'X·OCD-全渠道客户',
                            img: 'https://static.rongxintech.cn/yx/product/frame2/custom/product-custom-banner.png',
                        }
                    },
                }, {
                    path: '/product/frame2/goods',
                    meta: {
                        title: 'X·OCD-全渠道商品-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: Goods,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '全渠道场景',
                            btn: '观看演示',
                            childrenData: ['什么是云计算？', '全渠道是什么？', '什么是客制化？', 
                            'X·OCD-全渠道营销', 'X·OCD-全渠道商品', 'X·OCD-全渠道订单', 'X·OCD-全渠道结算', 'X·OCD-全渠道客户'],
                            url: ['https://zhuanlan.zhihu.com/p/354477430', 'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA', 'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ',
                            '/product/frame2/Marketing', '/product/frame2/goods', '/product/frame2/order', '/product/frame2/settleAccount',
                            '/product/frame2/custom', ],
                            activeIdx: 4,
                        },
                        default: {
                            maskData: ['打破商品、库存的渠道壁垒，','以全渠道商品 + 库存打造场景式消费体验 '],
                            mask: 'rgba(226, 148, 20, 0.3)',
                            maskMbData: 'X·OCD-全渠道商品',
                            img: 'https://static.rongxintech.cn/yx/product/frame2/goods/product-goods-banner.png'
                        }
                    },
                }, {
                    path: '/product/frame2/order',
                    meta: {
                        title: 'X·OCD-全渠道订单-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: Order,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '全渠道场景',
                            btn: '观看演示',
                            childrenData: ['什么是云计算？', '全渠道是什么？', '什么是客制化？', 
                            'X·OCD-全渠道营销', 'X·OCD-全渠道商品', 'X·OCD-全渠道订单', 'X·OCD-全渠道结算', 'X·OCD-全渠道客户'],
                            url: ['https://zhuanlan.zhihu.com/p/354477430', 'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA', 'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ',
                            '/product/frame2/Marketing', '/product/frame2/goods', '/product/frame2/order', '/product/frame2/settleAccount',
                            '/product/frame2/custom'],
                            activeIdx: 5,
                        },
                        default: {
                            maskData: ['以提升消费者体验以及渠道的服务能力为目的，根据多种','路由规则，以最优最适合的方式进行订单分配'],
                            mask: 'rgba(20, 65, 180, 0.3)',
                            maskMbData: 'X·OCD-全渠道订单',
                            img: 'https://static.rongxintech.cn/yx/product/frame2/order/product-order-banner.png',
                        }
                    },
                }, {
                    path: '/product/frame2/settleAccount',
                    meta: {
                        title: 'X·OCD-全渠道结算-广州有信科技有限公司'
                    },
                    components: {
                        default: ContentFrame,
                        content: SettleAccount,
                        navBar: NavBar,
                    },
                    props: {
                        navBar: {
                            title: '全渠道场景',
                            btn: '观看演示',
                            childrenData: ['什么是云计算？', '全渠道是什么？', '什么是客制化？', 
                            'X·OCD-全渠道营销', 'X·OCD-全渠道商品', 'X·OCD-全渠道订单', 'X·OCD-全渠道结算', 'X·OCD-全渠道客户'],
                            url: ['https://zhuanlan.zhihu.com/p/354477430', 'https://mp.weixin.qq.com/s/_P2Wi8kG7h4QUwvAo0_dRA', 'https://mp.weixin.qq.com/s/ZeWx0eLkXKnNACuJwM77zQ',
                            '/product/frame2/Marketing', '/product/frame2/goods', '/product/frame2/order', '/product/frame2/settleAccount'
                            ,'/product/frame2/custom'],
                            activeIdx: 6,
                        },
                        default: {
                            maskData: ['解决平台多方结算的难题，保证合规合法结算的同时，', '降低平台方运营成本 '],
                            mask: 'rgba(141, 65, 226, 0.3)',
                            maskMbData: 'X·OCD-全渠道结算',
                            img: 'https://static.rongxintech.cn/yx/product/frame2/settleAccount/product-settle-banner.png',
                        }
                    },
                },
        
                // { path: '/cardContent', name: 'cardContent', meta: { title: 'carContent样式' }, 
                //     components: {
                //         default:ShowCardContent,
                //         content:ContentFrame,
                //     } 
                // },
                // { path: '/xxx', name: 'solution', meta: { title: 'xxx' }, component: () => import(/* webpackChunkName: "solution" */'./views/xxx.vue') },
                // { path: '/xxx1/:i?', name: 'building', component: () => import(/* webpackChunkName: "building" */'./views/xxx1.vue') },
                // { path: '/xxx2/:id?', name: 'news', component: () => import(/* webpackChunkName: "news" */'./views/xxx2.vue') },
            ]
        },
        
    ],
    scrollBehavior (to, from, savedPosition) {
        const position = {}
        // if (to.hash) {
        //     position.selector = to.hash
        // }
        if (to.path !== '/solution/scene/scene' && to.path !== '/service') {
            position.x = 0
            position.y = 0
        }
        if (from.path === '/' && !to.hash || to.path === '/consultative') {
            position.x = 0
            position.y = 0
        }
        if (to.hash || ((to.path === '/solution/scene/scene' || to.path === '/service') && to.hash)) {
            position.selector = to.hash
        }
        
        // console.log(to, from, 'to, from,');
        return position
        // const position = {}
        // if (to.hash) {
        //     position.selector = to.hash
        // } else {
        //     position.x = 0
        //     position.y = 0
        // }
        if (from.path === '/' && !to.hash || to.path === '/consultative') {
            position.x = 0
            position.y = 0
        }
        return position
    }
})
