<template>
    <div class="pageWrapper singleWrapper">
        <div class="module" v-for="(item,i) in moduleData" :key="i">
            <div class="title" v-if="Array.isArray(item.title)">
                <span class="titleItem" v-for="(it,i) in item.title" :key="i">
                    {{it}}
                </span>
            </div>
            <div class="title" v-else>{{item.title}}</div>
            <div class="content">
                <card-content :cardContentData="item.children" v-if="item.type == 'card'"></card-content>
                <div class="imgContent" v-if="item.type == 'img'">
                    <img :src="item.img" alt="">
                </div>
            </div>
        </div>
        <solution-expert :content="solutionExpertContent"></solution-expert>
    </div>
</template>

<script>
import CardContent from '../../../components/CardContent.vue'
import SolutionExpert from '../../../components/SolutionExpert.vue'
export default {
    name:'single',
    components: { CardContent, SolutionExpert },
    data() {
        return {
            solutionExpertContent:'如果您的商业正处于数字化转型阶段，尽快享受数字化带来的商业机会吧，我们将帮您实现商业持续增长。联系我们深入了解单渠道的客制化解决方案',
            moduleData:[
                {   
                    title:'线下渠道  ',
                    children:[
                    {
                        title:'地域限制渠道辐射范围  ',
                        content:'受地域与实际选址影响，渠道辐射范围小，限制了潜在客户的规模与多样性'
                    },
                    {
                        title:'整体效能有限  ',
                        content:'当效能达到一定规模后，无法实现裂变式增长'
                    },
                    {
                        title:'服务能力不足  ',
                        content:'不能适应消费者方式的变革，受制于时间和空间，服务能力影响渠道发展'
                    },
                    ],
                    type:'card',
                },
                {
                    title:'线上渠道  ',
                    children:[
                        {
                            title:'体验缺失  ',
                            content:'渠道限制，消费者现实消费体验感较差'
                        },
                        {
                            title:'同质化严重  ',
                            content:'线上商城产品链条短，导致同质化严重，品牌信任度低'
                        },
                        {
                            title:'货期较长  ',
                            content:'下单后产品到消费者的距离较长，影响消费者体验'
                        },
                    ],
                    type:'card',
                },
                {
                    title:'渠道拓展+品牌化趋势明显',
                    children:[],
                    img: 'https://static.rongxintech.cn/yx/solution/single-pic1.png',
                    type:'img',
                },
                {
                    title:'以解决您的商业问题为目的，反向输出客制化解决方案',
                    children:[
                        {
                            title:'线下渠道  ',
                            content:[
                                '1.渠道整合打造线上+线下的渠道模式，形成线上、线下相辅相成生态',
                                '2.打造社交商城，借助原线下门店生态，打造线上社交商城生态',
                                '3.线上营销导流线下门店购买，突破门店效能瓶颈'
                            ],
                        },
                        {   
                            title:'线上渠道  ',
                            content:[
                                '1.云营销赋能线上门店拓新，拓宽流量入口',
                                '2.社交商城助力私域流量沉淀，提高转化率',
                                '3.订单路由赋能物流、仓储，降本增效，提高客户体验值',
                            ]
                        },
                    ],
                    type:'card',
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.singleWrapper .module {
    .content {
        .imgContent img {
            width: vwDecimal(953);
            height: vwDecimal(672);
            object-fit: contain;
        }
    }
    &:nth-child(4) .content .card-content /deep/ .content {
            grid-template-columns: unset;
            grid-template-rows: auto;
            grid-column-gap: 0;
            grid-row-gap: 0;
            padding: 0;
            .content-item_content { 
                // white-space: unset; 
                & + .content-item_content {
                    margin-top: 20px;
                }
            }
            .content-item {
                padding: vwDecimal(40);
                & + .content-item { background: #F7FBFF; }
            }
            .content-item_title + .content-item_contentWrapper {
                margin-top: vwDecimal(20);
            }
            .content-item_children + .content-item_children {
                margin-top: vwDecimal(20);
            }
        }
}
@media screen and (max-width: 414px) {
    .singleWrapper .module .content {
        .imgContent {
            height: unset;
        }
        .imgContent img {
            width: px2Mb(690);
            height: px2Mb(500);
            object-fit: contain;
        }
    }
    .singleWrapper .module .content /deep/ .card-content .content .content-item {
        padding: px2Mb(40);
    }
    .singleWrapper .module .content .card-content /deep/ .content-item + .content-item {
        margin-top: 0;
    }
    .singleWrapper .module .content .card-content /deep/ .content .content-item_title + .content-item_children {
        margin-top: px2Mb(20);
    }
    .singleWrapper .module .content .card-content /deep/ .content .content-item_children {
        & +  .content-item_children {
            margin-top: px2Mb(20);
        }
        font-size: px2Mb(24);
        font-weight: 400;
        color: #1A2C33;
        line-height: px2Mb(38);
    }
}
</style>