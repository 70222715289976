<template>
    <div class="navBar pc" v-if="show">
        <div class="autoScroll">
            <template v-if="Array.isArray(title)">
                <div class="navBar-title" v-for="(item,i) in title" :key="i">{{item}}</div>
            </template>
            <div class="navBar-title" v-else>{{title}}</div>
            <div class="navBar-contentWrapper">
                <div class="navBar-content" v-for="(item,i) in childrenData" :key="i" :class="{'activeRoute': isActive(i)}">
                    <div v-if="url[i] " @click="goPage(url[i]+(hash && hash[i]||''))">{{item}}</div>
                </div>
            </div>
            <div class="navBar-question"> 有疑惑？ </div>
            <div class="navBar-question"> 400-003-1009 </div>
            <button class="navBar-relate" @click="goPage('/consultative')">联系我们</button>
        </div>
    </div>
</template>

<script>
const reg = new RegExp( /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/ )

export default {
    name:'navBar',
    props:{
        title:[Array, String],
        btn:String,
        childrenData:Array,
        url:{
            type:Array,
            required:true,
        },
        activeIdx:Number,
        type:[String,Array],
        hash:Array,
        hashArr:Array,
    },
    data() {
        return {
            reg,
            realIdx:null,
            show: true,
            autoShow:false
        }
    },
    mounted(){
        if (this.isPc) {
            window.removeEventListener('scroll', this.handleScroll)
            window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
        }
    },
    watch:{
        $route:function(to){
            if (to.hash) {
                this.realIdx = this.hashArr.indexOf(to.hash)
            }
        }
    },
    methods:{
        isActive(i){
            var url = this.url[i]+(this.hash && this.hash[i]||'')
            return this.$route.fullPath == url
        },
        handleScroll(){
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
            if (scrollTop > 1935) {
                this.show = false
            } else {
                this.show = true
            }
            // let offsetTop = document.querySelector('#boxFixed').offsetTop;  // 要滚动到顶部吸附的元素的偏移量
            // this.isFixed = scrollTop > offsetTop ? true : false;  // 如果滚动到顶部了，this.isFixed就为true
        }
    }
}
</script>

<style lang="scss" scoped>
$fontFam: "Helvetica", "Tahoma", "Arial", "PingFang-SC-Medium", "PingFang-SC-Regular", "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei";
$Color:(
    title:#040921,
    relate: #ffffff,
    content: #4C4C4C,
    question:#040921,
);
$titleVW:vwDecimal(24);
$contentVW:vwDecimal(16);
$titleVH:vwDecimal(33);
$contentVH:vwDecimal(22);
$Font: (
    title: normal normal #{$titleVW}/#{$titleVH} $fontFam,
    relate: normal normal #{$contentVW}/#{$contentVH} $fontFam,
    content: normal normal #{$contentVW}/#{$contentVH} $fontFam,
    question: normal normal #{$contentVW}/#{$contentVH} $fontFam,
);
@each $key, $value in $Font {
    .navBar-#{$key} {
        font: $value;
        color:map-get($map: $Color, $key: $key);
        @if ($key != 'title' and $key != 'question') {
            cursor: pointer;
        }
        @if ($key == 'content') {
            padding: 0 vwDecimal(28);
            & + .navBar-content {
                margin-top: vwDecimal(20);
            }
            &:hover {
                color:#0046AD;
                font-weight: bold;
            }
            &.activeRoute {
                color:#0046AD;
                font-weight: normal;
                border-left: 8px solid #0046AD;
                background: rgba(0, 70, 173, .19);
                margin-top: vwDecimal(10);
                padding: vwDecimal(10) vwDecimal(20);
                & + .navBar-content {
                    margin-top: vwDecimal(10);
                }
            }
        }
    }
}
.autoScroll {
    // position: sticky;
    // top:123px;
}
.navBar {
    width: vwDecimal(204);
    padding-top: vwDecimal(150);
    padding-bottom: vwDecimal(64);
    top: 0;
    // bottom: vwDecimal(871);
    background: #FFFFFF;
    opacity: 0.8;
    position: fixed;
    box-sizing: border-box;
    z-index: 10;
    .navBar-title {
        padding-left: 30px;
        & + .navBar-title {
            margin-top: 0;
        }
    }
    .navBar-relate {
        margin-top: vwDecimal(20);
        border-radius: vwDecimal(4);
        margin-left: 30px;
        padding: vwDecimal(8) vwDecimal(30);
        background: #0099FF;
        outline: none;
        border-width: 0;
        & + .navBar-question {
            margin-top: vwDecimal(60);
        }
    }
    .navBar-contentWrapper {
        margin-top: vwDecimal(40);
    }
    .navBar-question {
        & + .navBar-question { margin-top: 0; }
        margin-left: 30px;
        margin-top: 40px;
    }
}
</style>