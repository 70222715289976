<template>
    <!-- 帮助中心-左侧导航 -->
    <div class="aside d-flex flex-column">
        <!-- 分类 -->
        <div class="aside_area flex-1">
            <div class="help_class" v-for="(one, index) in classifyInfo" :key="index">
                <div class="one-layer d-flex position-relative a-center class-hover" @click="clickClassify(one)"
                    :class="[(one.tagId === currClassifyInfo.oneTagId && one.child.length === 0) ? 'on one-on' : '', 
                        one.child.length > 0 && judgeOpen(one) ? 'arrow-own-pdl': 'arrow-common-pdl']">
                    <span class="arrow-right arrow-right-big" v-show="one.child.length > 0 && !judgeOpen(one)"></span>
                    <span class="arrow_down arrow_down_big" v-show="one.child.length > 0 && judgeOpen(one)"></span>
                    <span class="ml-10 class-title-text" :class="one.child.length > 0 ? '' : 'pl-5'">{{one.tagName}}</span>
                </div>
                <div v-show="judgeOpen(one)">
                    <div class="two-layer d-flex position-relative class-hover" v-for="(two, index2) in one.child" :key="index2"
                        :class="two.tagId === currClassifyInfo.twoTagId ? 'on two-on' : ''"
                        @click="clickClassify(two)">
                        <!-- <span class="arrow-right arrow-right-small"></span> -->
                        <div class="ml-10 class-title-text">{{two.tagName}}</div>
                    </div>
                </div>
                
                <!-- 暂时无 content -->
                <!-- <div class="content">
                    <div class="li on">文章标题选中状态</div>
                    <div class="li">文章标题hover状态</div>
                    <div class="li text-wrap-1">文章标题最多显示的效果是显示的效果是</div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
    name: 'HelpAside',
    data() {
        return {
        }
    },
    created() {
        // 获取分类数据
        this.getClassInfo()
    },
    computed: {
        ...mapState({
            classifyInfo: state => state.classify.classifyInfo,
            currClassifyInfo: state => state.classify.currClassifyInfo
        })
    },
    methods: {
        ...mapMutations([
            'setClassifyInfo',
            'setCurrClassifyInfo'
        ]),
        getClassInfo() {
            const param = {
                content_type: 'college',
                is_hide: '',
                page: '1',
                num: '500'
            }
            this.$ajax('/api/moment2/content/tag/list', param, { flag: 'help' }).done(res => {
                this.handleData(res)
            })
        },
        handleData(res) {
            res = res.data
            let classInfo = res.filter(v => v.tagLevel === 1).map(v => {
                const child = res.filter(c => v.tagId === c.parentId)
                return {
                    child,
                    ...v
                }
            })
            this.setClassifyInfo(classInfo)
        },
        clickClassify(data) {
            // 设置当前点击后的分类信息
            let obj = {}
            obj.where = 'clickClass'
            let path = '/helpCenter/articleList/'
            if (data.tagLevel === 1) {
                obj.oneTagId = data.tagId
                obj.oneTagName = data.tagName
                // 判断是否有二级分类
                if (data.child.length > 0) {
                    // 如果有二级分类，则默认高亮第一个二级分类
                    obj.twoTagId = data.child[0].tagId
                    obj.twoTagName = data.child[0].tagName
                }
                // 展开 / 收起
                if (data.tagId === this.currClassifyInfo.oneTagId) {
                    obj.oneTagId = ''
                }
                // 加一个tagId 路由变化触发更新
                let tagId = obj.twoTagId ? obj.twoTagId : obj.oneTagId
                path = path + tagId
            } else if (data.tagLevel === 2) {
                obj.oneTagId = data.parentId
                obj.twoTagId = data.tagId
                obj.twoTagName = data.tagName
                // 点击二级分类则取二级分类的tagId
                path = path + data.tagId
            }
            this.setCurrClassifyInfo(obj)
            // 跳转
            if (this.$route.path == path) {
                return
            }
            this.$router.push({
                path
            })
        },
        judgeOpen(data) {
            // 判断一级分类是否需要展开
            if (data.tagId === this.currClassifyInfo.oneTagId) {
                return true
            }
            return false
        }
    }
}
</script>

<style lang="scss" scoped>
    .aside {
        color: #333;
        .help_class {
            // 1、下边框有颜色 对应着上边框没有宽度 是正三角形
            // 2、上边框有颜色 对应着下边框没有宽度 是倒三角形
            .arrow_down { border-color: #666 transparent transparent transparent; border-style: solid; }
            .arrow_down_small { border-width: 4px 4px 0 4px; }
            .arrow_down_big { border-width: 5px 4px 0 4px; }
            .arrow-common-pdl { padding-left: 58px; }
            .arrow-own-pdl { padding-left: 55px; }
            .arrow-right { border-color: transparent transparent transparent #666; border-style: solid; }
            .arrow-right-small { border-width: 4px 0 4px 4px; }
            .arrow-right-big { border-width: 4px 0 4px 5px; }
            .on { background-color: #E5F2FF; }
            .on::after { position: absolute; right: 0; width: 2px; content: ""; background: #007EFF; }
            .one-on::after { height: 48px; }
            .two-on::after { height: 40px; }
            .one-layer { height: 48px; line-height: 48px; }
            .two-layer { padding-left: 85px; }
            .two-layer, .content { line-height: 40px; }
            .one-layer-arrow { font-size: 14px; }
            .two-layer-arrow { font-size: 12px; }
            .class-title-text { font-weight: 500; }
            .content {
                .li { padding-left: 88px; font-size: 14px; width: 238px; height: 40px; font-weight: 400; }
                .on { background-color: #E5F2FF; }
            }
            .class-hover:hover, .content .li:hover { color: #007EFF; cursor: pointer; }
        }
    }
    // 隐藏滚动条
    ::-webkit-scrollbar {
        display: none;
        width: 0 !important;
        height: 0 !important;
        -webkit-appearance: none;
        background: transparent;
    }
</style>