<template>
    <div class="frame1Wrapper sellDistributeWrapper">
        <div class="module" v-for="(item,i) in moduleData" :key="i">
            <div class="title">{{item.title}}</div>
            <div class="subTitle" v-if="item.subTitle">{{item.subTitle}}</div>
            <div class="content">
                <ul class="p_box guide_list" v-if="item.type == 'card'">
                    <li class="equal" v-for="(it, i) in item.children" :key="i">
                        <div class="tit">{{ it.title }}</div>
                        <p class="label">{{ it.content }}</p>
                    </li>
                </ul>
                <div class="p_box panel_body pc" v-if="item.type == 'tab'">
                    <div class="p_box tabs_box">
                        <ul class="tabs_list">
                            <li :class="{on: tabCur == ii}" v-for="(it, ii) in item.children" @click="tabCur = ii;" :key="ii">
                                <div class="tit">{{ it.title }}</div>
                            </li>
                        </ul>
                        <div class="flex1 cont" v-if="item.children[tabCur]">
                            <p v-for="(content, j) in item.children[tabCur].children" :key="j">{{ j + 1 }}、{{ content }}</p>
                        </div>
                    </div>
                    <div class="flex1 img_box">
                        <img :src="item.img" alt="经销系统">
                    </div>
                </div>
                <!-- <card-content :cardContentData="item.children" v-if="item.type == 'tab'" class="mb"></card-content> -->
                <!-- <card-content :cardContentData="item.children" v-if="item.type == 'advantage'"></card-content> -->
                <div class="cardCoreList mb" v-if="item.type == 'tab'">
                    <div class="cardCore" v-for="(it,ii) in item.children" :key="ii">
                        <div class="title">{{it.title}}</div>
                        <div class="childrenList">
                            <div class="content" v-for="(v,iii) in it.children" :key="iii">{{v}}</div>
                        </div>
                    </div>
                </div>
                <div class="advantageList" v-if="item.type == 'advantage'">
                    <div class="advantage" v-for="(it,ii) in item.children" :key="ii">
                        <div class="title">{{it.title}}</div>
                        <div class="content">{{it.content}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <consultative></consultative> -->
    </div>
</template>

<script> 
import CardContent from '../../../components/CardContent.vue'
import Consultative from '../../../components/Consultative.vue'
export default {
  components: { CardContent , Consultative},
    name: 'sellDistribute',
    data() {
        return {
            moduleData:[
                {
                    title: '问题导向',
                    children: [
                        { title: '战略层', content: '以品牌商为核心的供应链中，各个环节无法实现协同工作、信息共享。' },
                        { title: '业务层', content: '经销商的日常运作管理无法数据化，采购、销售、库存、客户等数据不统一。' },
                        { title: '技术层', content: '信息不同步，供应链中各个环节各自为营，信息无法对称，管理成本递增。' }
                    ],
                    type:'card'
                },
                {
                    title: '核心功能',
                    children: [
                        {
                            title: '会员管理',
                            children: ['会员唯一ID','全生命周期管理','会员储值','会员权益','架构属性管理']
                        },
                        {
                            title: '商品管理',
                            children: ['统一授权','一物一码','溯源防窜','自由供货','商品履约','商品触点']
                        },
                        {
                            title: '财务管理',
                            children: ['空中分账','账户管理','即刻结算','账号安全','灵活充值','交易明细']
                        },
                        {
                            title: '仓库管理',
                            children: ['在线兑货','下级移仓','上级提货','在线退仓','一件代发','库存账目']
                        },
                        {
                            title: '订单管理',
                            children: ['订单路由','会员归属','库存分类','智能拆单','核销订单','订单跟踪']
                        }
                    ],
                    img: 'https://static.rongxintech.cn/yx/product/frame1/selldistribution/product-jingxiao-pic1.png',
                    // img: 'https://static.rongxintech.cn/oryx/tmp/20210226/16143288907046214.png',
                    type:'tab'
                },
                {
                    title: '产品优势',
                    subTitle: '整合全渠道信息，实现仓储配送高效协同；专注提效降本，人货钱场单经营管理数字化',
                    children: [
                        { title: '统筹管理后台，高效集成管理', content: '围绕企业商业模式和经销体系业务流程设计，一个管理后台、打通会员、商品、订单、仓库、销售等信息数据，让生意更简单、管理更高效！' },
                        { title: '一物一码，商品统一授权管理', content: '对商品和的经销商进行统一授权管理，解决商品溯源防窜货问题，实现自由设置供货模式，提升整体管理效率。' },
                        { title: '在线云仓，掌上操作省时省力更省心', content: '随时随地在线兑仓、提货、移仓、发货，库存数据动态更新、全局同步，帮助企业掌握全网销售和库存实况。' }, 
                        { title: '团队管理，成员属性和动态实时掌握', content: '代理商体系云端同步，在线完成申请、授权、审核等，实时掌握团队架构与动向，整合庞大的代理商资料，轻松实现系统化管理。' },
                        { title: '财务管理，可视化业绩数据细分', content: '所有业绩数据细分、财务自动结算，以日报、周报、月报形式呈现业绩情况，对个人及团队经营现状了然于胸！' },
                        { title: '多政策并行的多引擎系统', content: '通过多引擎系统打造品牌的多个政策并行功能，实现账户内多个余额、多个活动、多个SPU/SKU功能。' }
                    ],
                    type:'advantage'
                }
            ],
            tabCur: 0,
        }
    }
}
</script>

<style lang="scss" scoped>

</style>