<template>
    <div class="frame2Wrapper settleAccountWrapper">
        <div class="module" v-for="(item,i) in moduleData" :key="i">
            <div class="module-top">
                <div class="title">{{item.title}}</div>
                <template v-if="Array.isArray(item.subTitle)">
                    <div class="subTitle" v-for="(it,ii) in item.subTitle" :key="ii">{{it}}</div>
                </template>
                <div class="subTitle" v-else></div>
            </div>
            <div class="content">
                <!-- v-if="item.img" -->
                <div class="imgContent" >
                    <img :src="item.img" alt="">
                </div>
                <card-content :cardContentData="item.children" v-if="item.children"></card-content>
            </div>
        </div>
        <!-- <consultative></consultative> -->
    </div>
</template>

<script>
import CardContent from '../../../components/CardContent.vue'
import Consultative from '../../../components/Consultative.vue'
export default {
  components: { CardContent, Consultative },
    data() {
        return {
            moduleData:[
                {   
                    title:'为什么需要结算数字化',
                    subTitle:['代销、分销、经销、购销等，结算十分复杂；','从商品生产出来，经过多种方式到达消费者手中，其中商品的关系扭转，经过代销、分销、经销、购销等多种方式，结算十分复杂。'],
                    img: 'https://static.rongxintech.cn/yx/product/frame2/settleAccount/product-settle-pic2.png',
                },
                {   
                    title:'空中分账',
                    subTitle:['结算及时，结算明晰，结算合规合法','采用空中分账，通过结算引擎，以结算数字化强固品牌方和渠道的关系，提升管理效率。'],
                    img: 'https://static.rongxintech.cn/yx/product/frame2/settleAccount/product-settle-pic3.png',
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
$navBarWidth: vwDecimal(204);
$offsetLeft: vwDecimal(360);
$wrapperPaddingLeftWidth: vwDecimal(40);
$marginLeftWidth: calc(#{$offsetLeft} + #{$navBarWidth} + #{$wrapperPaddingLeftWidth});
.settleAccountWrapper {
    width: vwDecimal(956);
}
@media screen and (max-width: 414px) {
    .settleAccountWrapper {
        width: unset;
        margin-left: 0;
    }
}
</style>