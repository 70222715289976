<template>
    <div class="frame2Wrapper orderWrapper">
        <div class="module" v-for="(item,i) in moduleData" :key="i">
            <div class="module-top">
                <div class="title">{{item.title}}</div>
                <template v-if="Array.isArray(item.subTitle)">
                    <div class="subTitle" v-for="(it,ii) in item.subTitle" :key="ii">{{it}}</div>
                </template>
                <div class="subTitle" v-if="!Array.isArray(item.subTitle) && item.subTitle">{{item.subTitle}}</div>
            </div>
            <div class="content">
                <card-content :cardContentData="item.children" v-if="item.children"></card-content>
                 <!-- v-if="item.img" -->
                <div class="imgContent">
                    <img :src="item.img" alt="">
                </div>
            </div>
        </div>
        <!-- <consultative></consultative> -->
    </div>
</template>

<script>
import CardContent from '../../../components/CardContent.vue'
import Consultative from '../../../components/Consultative.vue'
export default {
  components: { CardContent, Consultative },
    data() {
        return {
            moduleData:[
                {   
                    title:'订单路由的方式',
                    subTitle:[
                        '根据商业需要客制化订单路由配置原则，强化订单配送与全渠道的关系',
                        '时间最快、仓库距离就近、会员归属、线下门店距离就近、库存数量值、顺序配送、门店服务能力原则、库存品类、客户满意度原则、门店仓优先原则、电商仓优先原则、库存最低库存值、最优物流配送等。',
                    ],
                    img: 'https://static.rongxintech.cn/yx/product/frame2/order/product-order-pic1.png',
                },
                {   
                    title:'全渠道订单',
                    subTitle:['路由管理，多维度组合，智能拆单','企业通过不同的渠道与消费者联系，产生的订单也比较分散，如何更好的进行订单管理，以使消费者获得能好的配送体验，也使渠道商获得更多的订单，实现全渠道订单的优化，提升订单效率。 '],
                    img: 'https://static.rongxintech.cn/yx/product/frame2/order/product-order-pic2.png',
                },
                {   
                    title:'LBS智能优配',
                    subTitle:'基于LBS，智能计算消费需求，优配落地服务线索',
                    img: 'https://static.rongxintech.cn/yx/product/frame2/order/product-order-pic3.png',
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.orderWrapper {
    width: vwDecimal(956);
}
@media screen and (max-width: 414px) {
    .orderWrapper {
        width: unset;
        margin-left: 0;
    }
}
</style>