<template>
    <div class="serviceWrapper">
        <div class="module" v-for="(item,i) in moduleData" :key="i" :id="item.id">
            <div class="module-top">
                <div class="title">{{item.title}}</div>
                <div class="content">{{item.content}}</div>
            </div>
            <div class="imgContent">
                <img :src="item.img" alt="">
            </div>
        </div>
        <!-- <consultative></consultative> -->
    </div>
</template>

<script>
import Consultative from '../components/Consultative.vue'
export default {
    components: { Consultative },
    name:'service',
    data() {
        return {
            moduleData:[
                    {title:'客制化解决方案',
                    content:'基于企业商业模式的特点，以解决问题为目的反向输出解决方案',
                    id:'custom',
                    img: 'https://static.rongxintech.cn/yx/service/service-pic1.png',
                    },

                    {title:'长期主义服务方式',
                    content:'长期主义的坚守，有信科技浪漫而长情的企服理念',
                    id:'longterm',
                    img: 'https://static.rongxintech.cn/yx/service/service-pic2.png',
                    },

                    {title:'客户成功规划',
                    content:'客户全生命周期管理，助力商业模式成功',
                    id:'schematization',
                    img: 'https://static.rongxintech.cn/yx/service/service-pic3.png',
                    },

                    // 信·资讯（月刊下载）
                    // 跳转留资页面
            ]
        }
    },  
}
</script>

<style lang="scss" scoped>
.module {
    & + .module {
       padding-top: vwDecimal(120);
    }
    .module-top {
        .title {
            font-size: vwDecimal(38);
            font-weight: 500;
            color: #1A2C33;
            line-height: vwDecimal(53);
            & + .content {
                margin-top: vwDecimal(16);
            }
        }
        .content {
            font-size: vwDecimal(24);
            font-weight: 400;
            color: #1A2C33;
            line-height: vwDecimal(32);
        }
        & + .imgContent {
            margin-top: vwDecimal(40);
        }
    }
    .imgContent {
        width: vwDecimal(916);
        height: vwDecimal(409);
        // background-color: pink;
        img {

        }
    }
}
@media screen and (max-width: 414px){
    @mixin title {
        font-size: px2Mb(38);
        font-weight: 500;
        color: #1A2C33;
        line-height: px2Mb(53);
    }
    @mixin subTitle {
        font-size: px2Mb(24);
        font-weight: 400;
        color: #1A2C33;
        line-height: px2Mb(32);
    }

    .serviceWrapper {
        margin-left: 0;
        position: relative;
        top:0;
        padding: px2Mb(60) px2Mb(30);
        .consultativeWrapper {
            margin: unset;
        } 
    }
    .module {
        & + .module {
            margin-top: px2Mb(120);
        }
        .module-top {
            .title {
                @include title;
                & + .content {
                    margin-top: px2Mb(17);
                }
            }
            .content {
                @include subTitle;
            }
            & + .imgContent {
                margin-top: px2Mb(60);
            }
        }
        .imgContent {
            width: unset;
            height: unset;
            // height: px2Mb(851);
            // background-color: pink;
            img {
                width: px2Mb(700);
                height: unset;
                // height: px2Mb(851);
            }
        }
    }
}
</style>