<template>
    <div class="frame2Wrapper allWrapper">
        <div class="module" v-for="(item,i) in moduleData" :key="i">
            <div class="module-top">
                <div class="title">{{item.title}}</div>
                <template v-if="Array.isArray(item.subTitle)">
                    <div class="subTitle" v-for="(it,ii) in item.subTitle" :key="ii">{{it}}</div>
                </template>
                <div class="subTitle" v-if="item.subTitle && !Array.isArray(item.subTitle)"></div>
            </div>
            <div class="content">
                <card-content :cardContentData="item.children" v-if="item.children"></card-content>
                <!-- v-if="item.img" -->
                <div class="imgContent">
                    <img :src="item.img" alt="" >
                </div>
            </div>
        </div>
        <solution-expert :content="solutionExpertContent"></solution-expert>
    </div>
</template>

<script>
import CardContent from '../../../components/CardContent.vue'
import SolutionExpert from '../../../components/SolutionExpert.vue'
export default {
  components: { CardContent, SolutionExpert },
    data() {
        return {
            solutionExpertContent:'如果您的商业正处于数字化转型阶段，尽快享受数字化带来的商业机会吧，我们将帮您实现商业持续增长。联系我们深入了解全渠道的客制化解决方案',
            moduleData:[
                {   
                    title:'邀约-沉淀，实现一体化运营',
                    img: 'https://static.rongxintech.cn/yx/solution/all-pic1.png',
                },
                {   
                    title:'库存共享、弹性分配、库存预测',
                    img: 'https://static.rongxintech.cn/yx/solution/all-pic2.png',
                },
                {   
                    title:'一物一码，全程追踪，全生命周期管理',
                    img: 'https://static.rongxintech.cn/yx/solution/all-pic3.png',
                },
                {   
                    title:'路由管理，多维度组合，智能拆单',
                    img: 'https://static.rongxintech.cn/yx/solution/all-pic4.png',
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.allWrapper {
    width: vwDecimal(956);
}
@media screen and (max-width: 414px) {
    .allWrapper {
        width: unset;
        margin-left: 0;
    }
}
</style>