let observer = null

// function debounce(fn, delay) {
//     let timer = null

//     return function() {
//         let args = arguments
//         let context = this

//         if (timer) {
//             clearTimeout(timer)

//             timer = setTimeout(function() {
//                 fn.apply(context, args)
//             }, delay)
//         } else {
//             timer = setTimeout(function() {
//                 fn.apply(context, args)
//             }, delay)
//         }
//     }
// }

function throttle(fn, delay) {
    let timer = null
    // eslint-disable-next-line
    let remaining = 0
    let previous = new Date()

    return function() {
        let now = new Date(),
            remaining = now - previous,
            args = arguments,
            context = this

        if (remaining >= delay) {
            if (timer) {
                clearTimeout(timer)
            }

            fn.apply(context, args)
            previous = now
        } else {
            if (!timer) {
                timer = setTimeout(function() {
                    fn.apply(context, args)
                    previous = new Date()
                }, delay - remaining)
            }
        }
    }
}

let isPc = (function () {
    var userAgentInfo = navigator.userAgent
    var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
    var flag = true
    for (var v = 0; v < Agents.length; v++) {
        if (~userAgentInfo.indexOf(Agents[v])) {
            flag = false
            break
        }
    }
    return flag
})()

function isAndroid() {
	let userAgentInfo = navigator.userAgent
	if (userAgentInfo.indexOf('Android') > -1) {
		return true;
	} else {
		return false;
	}
}

function repalceDot(str) {
    if (isPc) {
        return str;
    } else {
        return str.replace(/，|\\,|。|\\.|！/g, '<br>');
    }
}

function IObserver(target, callback) {
    observer && observer.unobserve(target)
    // observer.disconnect()
    function handler (entries/* , observer */) { 
        entries.forEach(entry => callback && callback(entry));
    }
    const options = {
        rootMargin: '0px',
    }
        
    observer = new IntersectionObserver(handler, options)
    observer.observe(target)
}

function navChangeBg(that) {
    let top = document.querySelectorAll('.top')[0]
    let banner = document.querySelectorAll('.banner_wrapper')[0]
    if (isPc && banner) {
        if (typeof IntersectionObserver === 'undefined') {
            if ((window.scrollY < banner.clientHeight) || (banner.clientHeight === 0 || window.screenY === 0)) {
                top.classList.remove('bg')
            } else {
                top.classList.add('bg')
            }
            window.addEventListener('scroll', () => {
                if (~that.$route.path.indexOf('/build') || !isPc) {
                    top.classList.add('bg')
                    return
                }
                throttle(() => {
                    if ((window.scrollY < banner.clientHeight) || (banner.clientHeight === 0)) {
                        top.classList.remove('bg')
                    } else {
                        top.classList.add('bg')
                    }
                }, 120)()
            })
        } else {
            IObserver(banner, (entry) => {
                let rect = entry.boundingClientRect
                if (rect.bottom >= 0) {
                    top.classList.remove('bg')
                } else {
                    top.classList.add('bg')
                }
            })
        }
    } else {
        top.classList.add('bg')
    }
}

export default {
    // debounce,
    // throttle,
    isPc: isPc,
	isAndroid,
    repalceDot,
    navChangeBg
}
