<template>
    <!-- 帮助中心-主布局 -->
    <div class="d-flex help_center flex-column">
        <!-- 上侧 -->
        <div class="d-flex up bg-white a-center">
            <!-- logo -->
            <div class="flex-1 d-flex a-center logo_area">
                <img class="logo_img" src="https://static.rongxintech.cn/oryx/tmp/20210323/16164818190277205.png">
                <span class="text">有信科技帮助中心</span>
            </div>
            <!-- 搜索区 -->
            <div class="search_area d-flex flex-column">
                <div class="input_area">
                    <span class="fangdajing iconfont icon-fangdajing"></span>
                    <input ref="search_input" class="input_text" @keydown.enter="search" v-model="searchContent" placeholder="搜索文章" />
                </div>
            </div>
        </div>

        <!-- 下侧 -->
        <div class="d-flex flex-1 down">
            <div class="d-flex content">
                <!-- 左侧导航栏 -->
                <div class="help_aside">
                    <help-aside />
                </div>
                <!-- 帮助中心-主布局下的路由组件渲染出口 -->
                <div class="flex-1 router_view bg-white">
                    <router-view />
                </div>
            </div>
        </div>
        
        
    </div>
</template>

<script>
import HelpAside from '@/components/helpCenter/HelpAside.vue'
export default {
    components: {
        HelpAside
    },
    data() {
        return {
            searchContent: ''
        }
    },
    methods: {
        search() {
            this.$store.commit('setCurrClassifyInfo', {})
            const dom = this.$refs['search_input']
            const isListPath = this.$route.path.includes('/helpCenter/articleList')
            this.$ajax('/api/moment/college/list', { match: this.searchContent }, { flag: 'help' }).done(res => {
                dom.blur()
                if (!isListPath) {
                    // 先跳转到articleList
                    this.$router.push({ path: `/helpCenter/articleList?id=article` })
                }
                this.$store.commit('setArticleList', res.data)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .help_center { height: 100vh; }
    .up {
        padding-left: 45px; padding-right: 34px; border-bottom: 1px solid #EBEEF5;
        .logo_area {
            height: 64px;
            .logo_img { width: 32px; height: 32px; }
            .text { width: 142px; height: 20px; font-size: 16px; font-weight: 500; color: #313233; line-height: 20px; margin-left: 20px; }
        }
        .search_area {
            .input_area {
                height: 64px; line-height: 64px; position: relative; padding-left: 23px;
                .fangdajing { color: #999; position: absolute; left: 40px; top: 2px; }
                .input_text { width: 419px; box-sizing: border-box; height: 32px; background: #F5F7FA; border-radius: 16px; padding-left: 45px; border: 0; outline: none; }
                .input_text::placeholder { color: #999; }
            }
        }
    }
    .down {
        justify-content: center; background-color: #FFF; overflow: hidden;
        .content {
            width: 1300px;
            .help_aside {
                border-right: 1px solid #EBEEF5; width: 327px; background-color: #FFF; height: 100%; overflow-y: auto; padding-top: 28px;
            }
            .router_view { overflow-y: auto; height: 100%; }
        }
    }
    // 隐藏滚动条
    ::-webkit-scrollbar {
        display: none;
        width: 0 !important;
        height: 0 !important;
        -webkit-appearance: none;
        background: transparent;
    }
</style>