<template>
    <div class="module solutionExpertWrapper">
        <div class="left">
            <template v-if="Array.isArray(title)">
                <div class="title" v-for="(item,i) in title" :key="i">{{item}}</div>
            </template>
            <div class="title" v-else>{{title}}</div>
            <div class="content">{{content}} </div>
            <button class="btn" @click="goPage('/consultative')">联系我们</button>
        </div>
        <div class="right">
            <img src="https://static.rongxintech.cn/oryx/tmp/20210225/16142356769904608.png" alt="expert">
        </div>
    </div>
</template>

<script>
export default {
    name:'solutionExpert',
    props:{
        title:{
            type:[Array,String],
            default:'更多解决方案，我们的解决方案专家将为您分享',
        },
        content:{
            type:String,
            default:'如果您的商业正处于数字化转型阶段，尽快享受数字化带来的商业机会吧，我们将帮您实现商业持续增长。联系我们深入了解美妆行业的客制化解决方案'
        },
    }
}
</script>

<style lang="scss" scope>
.solutionExpertWrapper {
    display: flex;
    font-weight: 400;
    color: #1A2C33;
    background: #F7FBFF;
    padding: vwDecimal(30) vwDecimal(40) !important;
    box-sizing: border-box;
    width: vwDecimal(952);
    height: vwDecimal(420);

    .left {
        & + .right {
            margin-left: vwDecimal(84);
        }
        .title {
            width: vwDecimal(504);
            font-size: vwDecimal(36);
            line-height: vwDecimal(64);
        }
        .content {
            color: #5E6B70;
            width: vwDecimal(500);
            font-size: vwDecimal(18);
            line-height: vwDecimal(36);
            margin: vwDecimal(20) 0 vwDecimal(40) 0;
        }
        .btn {
            color: #FFFFFF;
            border-width: 0;
            background: linear-gradient(270deg, #3BCDFF 0%, #5794FF 100%);
            height: vwDecimal(40);
            font-size: vwDecimal(16);
            line-height: vwDecimal(22);
            border-radius: vwDecimal(4);
            padding: vwDecimal(10) vwDecimal(60);
        }
    }
    .right {
        img {
            width: vwDecimal(259);
            height: vwDecimal(351);
            object-fit: cover;
        }
    }
}
@media screen and (max-width: 414px) {
    .solutionExpertWrapper { display: none; }
}
</style>