import Vue from 'vue'
import Vuex from 'vuex'
import classify from './modules/classify'
import article from './modules/article'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        classify,
        article
    }
})