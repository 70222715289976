<template>
    <div class="frame2Wrapper customWrapper">
        <div class="module" v-for="(item,i) in moduleData" :key="i">
            <div class="module-top">
                <div class="title">{{item.title}}</div>
                <template v-if="Array.isArray(item.subTitle)">
                    <div class="subTitle" v-for="(it,ii) in item.subTitle" :key="ii">{{it}}</div>
                </template>
                <div class="subTitle" v-else></div>
            </div>
            <div class="content customContent">
                <card-content :cardContentData="item.children" v-if="item.children"></card-content>
                 <!-- v-if="item.img" -->
                <div class="imgContent">
                    <img :src="item.img" alt="">
                </div>
            </div>
        </div>
        <!-- <consultative></consultative> -->
    </div>
</template>

<script>
import CardContent from '../../../components/CardContent.vue'
import Consultative from '../../../components/Consultative.vue'
export default {
  components: { CardContent, Consultative },
    data() {
        return {
            moduleData:[
                {   
                    title:'功能',
                    children:[
                        {content:'全渠道会员画像',},
                        {content:'全渠道会员唯一ID',},
                        {content:'全渠道会员生命周期',},
                        {content:'全渠道会员MOT',},
                        {content:'全渠道会员PAC分层',},
                        {content:'全渠道会员RFM管理',}
                    ],
                    img: 'https://static.rongxintech.cn/yx/product/frame2/custom/product-custom-pic1.png',
                },
                {   
                    title:'价值',
                    children:[
                        {content:'拓宽单个消费者的消费域',},
                        {content:'提升消费者的消费生命周期',},
                        {content:'提升消费者沟通的效率和成功率',},
                        {content:'全渠道会员统一管理',},
                        {content:'全渠道会员的深度洞察',},
                    ],
                    img: 'https://static.rongxintech.cn/yx/product/frame2/custom/product-custom-pic2.png',
                },
                {   
                    title:'应用场景',
                    children:[
                        {content:'会员级别及权益设置',},
                        {content:'会员生日/节日关怀',},
                        {content:'消费过程有效沟通',},
                        {content:'消费喜好及变迁轨迹记录',},
                    ],
                    img: 'https://static.rongxintech.cn/oryx/tmp/20210304/16148563960271907.png',
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.customWrapper /deep/ {
    width: vwDecimal(956);
    .module .customContent .card-content .content {
        width: 100%;
        display: grid;
        grid-template-columns: vwDecimal(264) vwDecimal(300) vwDecimal(220);
        grid-template-rows: repeat(2, auto);
        grid-column-gap: vwDecimal(25);
        grid-row-gap:vwDecimal(20);
        .content-item .content-item_content {
            font-size: vwDecimal(24);
            font-weight: 400;
            color: #1A2C33;
            line-height: vwDecimal(33);
            display: list-item;
            &::marker {
                color: #93D3EC;
                font-size: vwDecimal(16);
            }
        }
    } 
}
@media screen and (max-width: 414px) {
    .customWrapper {
        padding: px2Mb(60) px2Mb(30);
        width: unset;
        margin-left: 0;
    }
    
    .customWrapper .module /deep/ .customContent .card-content .content {
        grid-template-columns: repeat(2, minmax(px2Mb(184) ,px2Mb(288)));
        grid-template-rows: repeat(2, auto);
        grid-row-gap: px2Mb(20);
        grid-column-gap: px2Mb(80);
        .content-item .content-item_content {
            font-size: px2Mb(24);
            line-height: px2Mb(33);
            font-weight: 400;
            color: #1A2C33;
        }
    }
    .imgContent{ display: none; } 
}


</style>