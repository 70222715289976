<template>
    <div class="pageWrapper alcolholWrapper">
        <div class="module" v-for="(item,i) in moduleData" :key="i">
            <div class="title" v-if="Array.isArray(item.title)">
                <span class="titleItem" v-for="(it,i) in item.title" :key="i">
                    {{it}}
                </span>
            </div>
            <div class="title" v-else>{{item.title}}</div>
            <div class="content">
                <card-content :cardContentData="item.children" v-if="item.type == 'card'"></card-content>
                <div class="imgContent" v-if="item.type == 'img'">
                    <img :src="item.img" alt="">
                </div>
            </div>
        </div>
        <solution-expert :content="solutionExpertContent"></solution-expert>
    </div>
</template>

<script>
import CardContent from '../../../components/CardContent.vue'
import SolutionExpert from '../../../components/SolutionExpert.vue'
export default {
    name:'Alcohol',
    components: { CardContent, SolutionExpert },
    data() {
        return {
            solutionExpertContent:'如果您的商业正处于数字化转型阶段，尽快享受数字化带来的商业机会吧，我们将帮您实现商业持续增长。联系我们深入了解酒水行业的客制化解决方案',
            moduleData:[
                {title:'人、货、场，数据分离，各自有难',
                children:[
                    {
                title:'陈旧、单一的分销模式 ',
                content:'终端销售的方式主要渠道为传统的销售渠道，经销商与酒水企业利益不相通，导致企业无法获取市场的真实状况。',
                },
                {
                title:'商品种类多，管理难 ',
                content:'酒水种类多，商品、库存、订单系统不统一，导致数据无法分析优化，管理难度大',
                },
                {
                title:'销售难度大，复购率低 ',
                content:'以“产品为核心”的传统模式，消费者与企业之间脱节，无法实现对客群的精准营销；只注重曝光，缺乏与消费者的沟通，消费者的印象不深刻，客户对品牌忠诚度低，进而导致商品复购率低。',
                },
                {
                title:'商品销售情况不透明，库存积压 ',
                content:'市场销售情况无法真实地反馈到企业，直接导致市场需求与企业生产的不匹配，库存积压',
                },
                ],
                type:'card'
                },
                {title:'全链路化、阶层化、差异化、品牌化带来的场景式消费升级',
                children:[
                ],
                img: 'https://static.rongxintech.cn/yx/solution/alcohol-pic1.png',
                type:'img'},
                {title:'以解决您的商业问题为目的，反向输出客制化解决方案',
                children:[
                {
                    title:'全渠道会员一体化运营 ',
                content:'让不同的角色都有自己的管理权限',
                },
                {
                    title:'裂变小店体系 ',
                content:'分销赋能、裂变赋能、私域流量赋能、整合赋能',
                },
                {
                    title:'全渠道商品智能运营 ',
                content:'库存共享、弹性分配、库存预测、一物一码，全程追踪，全生命周期管理',
                },
                {
                    title:'全渠道订单管理 ',
                content:'路由管理、多维度组合、智能拆单并单',
                },
                ],
                type:'card'},
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.alcolholWrapper .module .content {
    .imgContent img {
        width: vwDecimal(953);
        height: vwDecimal(1012);
        object-fit: contain;
    }
    .card-content /deep/ .content {
        grid-template-rows: vwDecimal(149) vwDecimal(181);
        .content-item_content {
            white-space: unset;
        }
    }
}
@media screen and (max-width: 414px) {
    .alcolholWrapper .module {
        .imgContent {
            height: unset;
        }
        .imgContent img {
            width: px2Mb(690)!important;
            height: px2Mb(800)!important;
            object-fit: contain;
        }
    }
}
</style>