<template>
    <div class="home">
        <div class="module module-1">
            <div class="left">
                <div class="title">打通数据壁垒，助力企业实现行业全渠道数字化转型</div>
                <div class="content">全渠道数字化是企业距离市场最近的、也是最急切的数字化，只有借助全渠道数字化才能实现消费者的有效沟通，实现商业的持续创新和盈利。</div>
                <div class="btn_group">
                    <button class="btn decimal" @click="goPage('/consultative')">开始我的数字化进程</button>
                    <button class="btn video" @click="openVideo">观看视频</button>
                </div>
            </div>
            <div class="right">
                <img src="https://static.rongxintech.cn/oryx/tmp/20210304/16148686763295938.png" alt="" class="homeImg">
            </div>
        </div>
        <div class="module module-3">
            <div class="module-top">
                <div class="title">X·OCD全商业场景数字化系列产品</div>
                <div class="content">打通渠道间的壁垒、重新规划数字化背景下的全渠道新零售转型充分利用大数据打造以人为中心的场景式消费体验</div>
            </div>
            <div class="module-card module-3_card">
                <card v-for="(item,i) in cardData" :key="i" routePos="card" :link="item.link">
                    <card-content divider :cardContentData="item.content">
                        <div slot="title">
                            <p class="title-small">X·OCD 全渠道</p>
                            <p class="title-normal">{{item.title}}</p>
                        </div>
                    </card-content>
                </card>
            </div>
        </div>
        <div class="module module-2">
            <div class="module-top">
                <div class="title">X·OCD全渠道数字化系列产品</div>
                <div class="content">针对解决不同商业场景中的问题，一站式连接品牌、渠道商、消费者的数字化系统</div>
            </div>
            <div class="module-card module-2_card">
                <card v-for="(item,i) in sceneData" :key="i" :titleImg="item.img" routePos="card" :link="item.link">
                    <card-content divider :cardContentData="item.content" :title="item.title"></card-content>
                </card>
            </div>
        </div>
        <div class="module module-5">
            <div class="module-top">
                <div class="title">客制化解决方案</div>
                <div class="content">适用不同行业、不同渠道特征、不同应用场景</div>
            </div>
            <div class="module-card module-5_tabList">
                <div class="tab" :class="{on: tabCur == i}" v-for="(item,i) in tabList" :key="i" @click="handleTabChange(i)">{{ item.title }}</div>
            </div>
            <div class="bd solution_list">
                <swiper :options="swiperOption" ref="mySwiper" @slideChangeTransitionStart="handleSwiperTransition">
                    <swiper-slide v-for="(li, index) in swiperSlides" :key="index">
                        <div class="solution_item">
                            <img class="img default" :src="li.default" alt="">
                            <img class="img mobile" :src="li.mobile" alt="">
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
            <!-- <img src="https://static.rongxintech.cn/oryx/tmp/20210222/16139801635163655.png" alt="" srcset="" class="img"> -->
        </div>
        <div class="module module-6">
            <div class="module-top">
                <div class="title">有信云为何迅速赢得客户信任</div>
                <div class="content"></div>
            </div>
            <card-content class="module-card module-6_card" :cardContentData="whyData">
                <img slot="contentExtraMb"  @click="goPage('/custom')"
                src="https://static.rongxintech.cn/yx/home/home-custom@2x.png" alt="" srcset="" class="img">
            </card-content>
            <!-- <img src="https://static.rongxintech.cn/oryx/tmp/20210222/16139801635163655.png" alt="" srcset="" class="img"> -->
        </div>
        <div class="module module-7">
            <div class="module-top">
                <div class="title">有信资讯</div>
                <div class="content"></div>
            </div> 
            <div class="module-card module-7_card">
                <swiper :class="{'swiper-no-swiping': isPc}" :options="newsSwiperOption" ref="newsSwiper">
                    <swiper-slide v-for="(li, index) in cardData2" :key="index">
                        <div class="news_box" @click="goPage(li.link)" v-if="li.link">
                            <div class="news_item">
                                <img class="titleImg" :src="li.img" alt="">
                                <div class="title">{{ li.title }}</div>
                                <div class="content">{{ li.content }}</div>
                                <div class="link">了解详情 &gt;</div>
                            </div>
                        </div>
                        </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
                <!-- <card v-for="(item,i) in cardData2" :key="i" :titleImg="item.img" :link="item.link">
                    <card-content :title="item.title" :cardContentData="item.content"></card-content>
                    <div slot="footer"></div>
                </card> -->
            </div>
        </div>
        <div class="videoWrapper" v-if="showBackDrop">
            <div class="videoWrapper__mask"></div>
            <div class="videoWrapper__btn close" @click="close"><img src=" https://cdn-static-www.jiatui.com/website-new/image-test/closed.png"></div>
            <div class="videoBody" @click="clickMask">
                <div class="vsc-controller"></div>
                <video class="nativeVideo" id="video" src="https://pro-rx.oss-cn-hangzhou.aliyuncs.com/tanke/video/pc.mp4" controls="controls" autoplay="autoplay" preload="auto" muted></video>
            </div>
        </div>
    </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import Card from '../components/Card.vue'
import CardContent from '../components/CardContent.vue'

export default {
    name:'Home',
    components: {
        Card,
        CardContent,
        swiper,
        swiperSlide,
    },
    data() {
        return {
            sceneData: [
                {   
                    title:'X·OCD-经销渠道数字化',
                    img:'https://static.rongxintech.cn/oryx/tmp/20210304/16148707685788238.png',
                    content:'人货钱场单新零售数字化管理系统',
                    link:'product/frame1/selldistribute'
                },
                {   
                    title:'X·OCD-社交渠道数字化',
                    img:'https://static.rongxintech.cn/yx/home/home-distri.png',
                    content:'终端门店一体化管控，助推门店的服务与体验升级',
                    link:'product/frame1/distribution',
                },
                {   
                    title:'X·OCD-门店渠道数字化',
                    img:'https://static.rongxintech.cn/yx/home/home-retail.png',
                    content:'打造品牌自有的私域流量，实现裂变式、扩展式传播',
                    link:'product/frame1/retail'
                },
                {   
                    title:'X·OCD-公域私域数字化',
                    img:'https://static.rongxintech.cn/yx/home/home-fields.png',
                    content:'联合快手、抖音，行业内第一款真正打通公域和私域的数字化系统',
                    link:'product/frame1/fields'
                },
            ],
            cardData:[
                {
                    title:'营销数字化',
                    content:'无缝对接客户需求，在合适的时间，以合适的方式，传递合适的信息',
                    link:'/product/frame2/marketing',
                },
                {
                    title:'商品数字化',
                    content:'打破商品、库存的渠道壁垒，以全渠道商品+库存打造场景式消费体验',
                    link:'product/frame2/goods',
                },
                {
                    title:'订单数字化',
                    content:'以提升消费者体验以及渠道的服务能力为目的，根据多种路由规则，以最优最适合的方式进行订单分配',
                    link:'/product/frame2/order',
                },
                {
                    title:'结算数字化',
                    content:'解决平台多方结算的难题，保证合规合法结算的同时，降低平台方运营成本',
                    link:'/product/frame2/settleAccount',
                },
                {
                    title:'会员数字化',
                    content:'邀约-沉淀，通过消费者唯一ID的建立，为消费者在不同的渠道提供标准的服务，实现一体化运营',
                    link:'/product/frame2/custom',
                },
            ],
            kuaishouData:[
                {   
                    title:'直播:',
                    content:'我播你赚，全渠道跨域直播', 
                },
                {   
                    title:'探客:',
                    content:'探客有道，销售更有数', 
                },
                {   
                    title:'社交:',
                    content:'全渠道分享，全渠道受益',   
                },
                {   
                    title:'结算:',
                    content:'空中分账，及时激励',   
                },

            ],
            tabList:[
                { title: '行业解决方案' },
                { title: '渠道解决方案' },
                { title: '场景解决方案' },
            ],
            tabCur: 0,
            whyData:[
                {content:'轻咨询：基于企业咨询匹配数字化产品'},
                {content:'客制化：提供企业特色数字化解决方案'},
                {content:'快部署：快速交付，让数字化与商业同步'},
                {content:'灵实施：灵活应对企业变化，敏捷反应'},
            ],
            cardData2:[
                {
                    img:'https://static.rongxin.tech/test/tmp/20210407/16177839269802283.png',
                    title:'立足大湾区数字化，有信科技创始人/CEO林少章接受《21世纪经济报道》专访',
                    content:'近期，大湾区第一大经济类权威媒体《21世纪经济报道》就“全渠道数字化”对有信科技CEO-林少章进行了专访报道。',
                    link:'https://mp.weixin.qq.com/s/_LM2U6p9SC5doRHiVS3dNg',
                },
                {
                    img:'https://static.rongxin.tech/test/tmp/20210407/16177841077670920.png',
                    title:'有信科技获亿元A+轮融资，客制化云服务应对多变新商业场景',
                    content:'“受疫情影响，企业数字化意识意愿明显提高。”有信科技创始人、CEO林少章对南都记者说，企业数字化是必然，但从现状看，进程才刚开始，过程比较曲折，需要漫长时间，十年甚至更长”。',
                    link:'https://m.mp.oeeee.com/a/BAAFRD000020210315454164.html',
                },
                {
                    img:'https://static.rongxin.tech/test/tmp/20210407/16177841126686551.png',
                    title:'有信科技创始人林少章：疫情后企业数字化意愿提高 全渠道数字化成创投热土',
                    content:'提供全渠道数字化解决方案的广州有信科技有限公司（以下简称有信科技）近日获得A+轮亿元级人民币融资。该轮融资由金沙江创投和凯泰资本联合投资，这距离上一轮融资仅有7个月。',
                    link:'https://bm.cnfic.com.cn/sharing/share/articleDetail/2269168/1',
                }
            ],
            showBackDrop: false,
            // default 是 pc 端的 banner, 如果两端图片一致，可以去掉 mobile 的相关逻辑
            swiperSlides: [
                {
                    default: 'https://static.rongxintech.cn/oryx/tmp/20210309/16152845279602305.png',
                    mobile: 'https://static.rongxintech.cn/oryx/tmp/20210309/16152845279602305.png',
                },
                {
                    default: 'https://static.rongxintech.cn/oryx/tmp/20210309/16152845387204187.png',
                    mobile: 'https://static.rongxintech.cn/oryx/tmp/20210309/16152845387204187.png',
                }, 
                {
                    default: 'https://static.rongxintech.cn/oryx/tmp/20210309/16152845522808916.png',
                    mobile: 'https://static.rongxintech.cn/oryx/tmp/20210309/16152845522808916.png',
                },
            ],
            swiperOption: {
                slidesPerView: 1,
                slidesPerColumn: 1,
                // freeMode: true,
                spaceBetween: 0,
                // speed: 2000,
                // autoplay: false,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false
                },
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    bulletClass: 'my-bullet',
                    bulletActiveClass: 'my-bullet-active',
                    clickable: true, 
                },
                // breakpoints: {
                //     1200: {
                //         // slidesPerView: 1,
                //         // slidesPerColumn: 1,
                //         centeredSlides: true,
                //         loop: true,
                //         spaceBetween: 0,
                //         pagination: {
                //             el: '.swiper-pagination'
                //         }
                //     }
                // }
            },
            newsSwiperOption: {
                slidesPerView: 3,
                slidesPerColumn: 1,
                spaceBetween: 0,
                loop: false,
                breakpoints: {   
                    768: {
                        slidesPerView: 1.2,
                        slidesPerColumn: 1,
                        spaceBetween: 0,
                        // pagination: {
                        //     el: '.swiper-pagination'
                        // }
                    }
                }
            }
        }
    },
    computed: {
        mySwiper() {
            return this.$refs.mySwiper.swiper
        },
    },
    created() {
        this.cardData.map(v => { v.img = v.titleImg })
        if (!this.isPc) {
            this.swiperOption.autoplay = false
        }
    },
    mounted() {
        this.cardData.map(v => { v.img = v.titleImg })
        console.log(this.cardData,'this.cardData');
    },
    methods: {
        goNews(){
            // this.$router.push('/cardContent')
            this.$router.push('/news')
        },
        goTeam(){
            this.$router.push('/team')
        },
        goUs(){
            this.$router.push('/us')
        },
        goShowContent(){
            this.$router.push('/cardContent')
        },
        openVideo() {
            this.showBackDrop = true
            this.$nextTick(() => {
                let dom = document.getElementsByTagName('body')[0]
                document.getElementById('video') && document.getElementById('video').play()
                dom.style.overflow = 'hidden'
            })
        },
        close() {
            let dom = document.getElementsByTagName('body')[0]
            dom.style.overflow = 'auto'
            this.showBackDrop = false
        },
        clickMask() {
            if (!this.isPc) {
                this.showBackDrop = false
                this.close()
            }
        },
        handleTabChange(i) {
            this.tabCur = i
            this.mySwiper.slideTo(i + 1, 100, false)
        },
        handleSwiperTransition() {
            this.tabCur = this.mySwiper.activeIndex === 4 ? 0 : this.mySwiper.activeIndex - 1
        },
    },
}
</script>

<style lang="scss" scoped>
.btn_group {
    display: flex;
    .btn {
        display: flex; align-items: center; justify-content: center; font-weight: 400; color: #FFFFFF;
        border-radius: vwDecimal(6);
        height: vwDecimal(60);
        padding: 0 vwDecimal(30);
        font-size: vwDecimal(24);
        & + .btn {
            margin-left: vwDecimal(30);
        }
    }
    .decimal { background: #00A1E1; }
    .video { background: #6EB04D; }
}
.home { background-color: #fff; }
.module { 
    display: flex; flex-direction: column; font-weight: 500; margin: 0 auto;
    & + .module {
        margin-top: vwDecimal(120);
    }
    .module-top {
        display: flex; flex-direction: column; justify-content: center; align-items: center;
        .title {
            font-size: vwDecimal(38);
            display: flex; justify-content: flex-start; color: #1A2C33; font-weight: 500; line-height: vwDecimal(53);
            & + .content { margin-top: vwDecimal(20); }
        }
        .content {
            font-size: vwDecimal(24);
            display: flex; justify-content: center; color: #1A2C33; line-height: vwDecimal(33);
        }
        & + .module-card { margin-top: vwDecimal(60); }
    }
}
.module-1 {
    // width: vwDecimal(1200);
    width: 100%;
    // height: vwDecimal(516);
    flex-direction: row; font-weight: 400; justify-content: space-between; align-items: center; display: flex;
    margin-bottom: vwDecimal(120);
    .title {
        font-size: vwDecimal(36);
        width: vwDecimal(432);
        line-height: vwDecimal(50);
        & + .content { 
            margin-top: vwDecimal(20);
        }
    }
    .content {
        width: vwDecimal(531);
        line-height: vwDecimal(52);
        color:#00A1E1;
        & + .btn_group { 
            margin-top: vwDecimal(40);
        }
    }
    .homeImg { 
        width: vwDecimal(550);
    }
}

.module-2 {
    width: 100%;
    display: flex; flex-direction: column; justify-content: flex-end;
    .module-2_card {
        width: 100%;
        display: grid; grid-auto-flow: column; grid-template-columns: repeat(2, vwDecimal(588)); 
        grid-template-rows: repeat(2, auto); grid-column-gap: vwDecimal(24); grid-row-gap: vwDecimal(24);
        .card {
            display: flex;
            padding: vwDecimal(15);
            background: #FFFFFF;
            box-shadow: 0px 0px vwDecimal(24) 0px rgba(0, 161, 225, 0.1);
            cursor: pointer;
            /deep/ .card-title {
                width: vwDecimal(135);
                height: auto;
                .titleImg {
                    width: vwDecimal(135);
                    object-fit: cover;
                }
            }
            /deep/ .card-content {
                margin-left: vwDecimal(40);
                padding-top: vwDecimal(25);
                padding-bottom: vwDecimal(25);
                .title1 {
                    font-size: vwDecimal(24);
                    line-height: vwDecimal(33);
                    font-weight: 500;
                    color: #1A2C33;
                    line-height: vwDecimal(33);
                    & + .content {
                        margin-top: vwDecimal(20);
                    }
                }
                .content-item_content {
                    font-weight: 400;
                    color: #5E6B70;
                    font-size: vwDecimal(16);
                    line-height: vwDecimal(32);
                }
            }
        }
    }
    
}
.module-3 {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    text-align: center;
    .module-top {
        width: vwDecimal(1022);
        margin: 0 auto;
    }
    .module-3_card {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        .card {
            background: #FFFFFF;
            box-shadow: 0px 0px vwDecimal(24) 0px rgba(0, 161, 225, 0.1);
            width: vwDecimal(224);
            height: vwDecimal(490);
            border-radius: vwDecimal(2);
            /deep/ .card-header {
                height: vwDecimal(180);
                margin: vwDecimal(20) vwDecimal(17) 0 vwDecimal(20);
                background-size: contain;
                background-repeat: no-repeat;
            }
            /deep/ .card-title {
                display: flex;
                justify-content: center;
                align-items: center;
                .titleImg {
                    width: vwDecimal(190);
                    height: vwDecimal(160);
                    object-fit: cover;
                }
            }
            /deep/ .card-content {
                padding: 0 vwDecimal(22);
                .title-small {
                    font-size: vwDecimal(14);
                    font-weight: 400;
                    color: #00A1E1;
                    line-height: vwDecimal(20);
                    & + .title-normal {
                        margin-top: vwDecimal(4);
                    }
                }
                .title-normal {
                    font-weight: 500;
                    color: #1A2C33;
                    font-size: vwDecimal(24);
                    line-height: vwDecimal(33);
                }
                .content {
                    font-size: vwDecimal(16);
                    line-height: vwDecimal(32);
                    font-weight: 400;
                    color: #5E6B70;
                    text-align: left;
                }
            }
            /deep/ .divider {
                text-align: center;
                &::after {
                    content:'';
                    display: block;
                    width: vwDecimal(70);
                    height: vwDecimal(2);
                    background:#00A1E1;
                    margin: vwDecimal(24) auto;
                }
            }
        }
        $imgList: 'https://static.rongxintech.cn/yx/home/home-marketing.png',
            'https://static.rongxintech.cn/yx/home/home-goods.png',
            'https://static.rongxintech.cn/yx/home/home-order.png',
            'https://static.rongxintech.cn/yx/home/home-settleAccount.png',
            'https://static.rongxintech.cn/yx/home/home-customer.png';
        $imgList-hover: 'https://static.rongxintech.cn/oryx/tmp/20210304/16148540100331700.png',
                        'https://static.rongxintech.cn/oryx/tmp/20210304/16148540153672598.png',
                        'https://static.rongxintech.cn/oryx/tmp/20210304/16148540214057688.png',
                        'https://static.rongxintech.cn/oryx/tmp/20210304/16148540266586216.png',
                        'https://static.rongxintech.cn/oryx/tmp/20210304/16148540314800867.png';
        @each $img in $imgList {
            $i: index($list: $imgList, $value: $img);
            .card:nth-child(#{$i}) {
                /deep/ .card-header {
                    background-image: url($img);
                }
                &:hover /deep/ .card-header {
                    background-image:url(nth($imgList-hover, $i));
                }
            }
        }
    }
}

.module-5 {
    width: 100%;
    display: flex; flex-direction: column; justify-content: center;
    .title, 
    .content {
        line-height: vwDecimal(32);
        color: #1A2C33; text-align: center;
    }
    .module-5_tabList {
        display: flex; justify-content: space-between; 
        font-size: vwDecimal(24);
        line-height: vwDecimal(60);
        .tab { 
            flex: 1; width: 0; text-align: center; border-bottom: 4px solid transparent; cursor: pointer;
        }
        .on { border-bottom-color: #00A1E1; }
    }
    .solution_item { background: #2bc1fd; }
    .img {
        vertical-align: top; box-sizing: border-box;
        cursor: pointer;
        &.default {
            display: block;
            height: vwDecimal(450);
            object-fit: cover;
        }
        &.mobile {
            display: none;
        }
    }
    /deep/ .swiper-pagination {
        .my-bullet {
            display: inline-block; vertical-align: top; width: 6px; height: 6px; border: 1px solid #fff; 
            border-radius: 100%; cursor: pointer;
            & + .my-bullet { margin-left: 4px;}
            &:focus { outline: none; }
        }
        .my-bullet-active {
            width: 38px; height: 6px; border-radius: 4px; background: #fff;
        }
    }
}

.module-6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: vwDecimal(70);
    .title {
        line-height: vwDecimal(32);
        color: #1A2C33;
        text-align: center;
    }
    .module-6_card {
        box-sizing: border-box;
        position: relative;
        width: vwDecimal(1117);
        background: #FFFFFF;
        box-shadow: 0px 0px 16px 0px rgba(0, 161, 225, 0.15);
        padding: vwDecimal(70) vwDecimal(60);
        /deep/ .content-item {
            display: list-item;
            font-size: vwDecimal(24);
            line-height: vwDecimal(33);
            font-weight: 400;
            color: #1A2C33;
            &::marker { color: #00A1E1; }
            & + .content-item {
                margin-top: vwDecimal(24);
            }
        }
    }
    .img {
        width: vwDecimal(647);
        height: vwDecimal(316);
        position: absolute;
        right: vwDecimal(-83);
        top: vwDecimal(10);
        cursor: pointer;
    }
}

.module-7 {
    width: 100%;
    .module-7_card { width: 100%; cursor: pointer;}
    .news_box { padding: 12px; }
    .news_item {
        position: relative; width: 100%; background: #FFFFFF; box-shadow: 0px 0px 24px 0px rgba(0,161,225,0.1); 
        height: vwDecimal(524);
        .titleImg {
            width: 100%;
            max-height: vwDecimal(160);
            object-fit: cover;
        }
        .title {
            font-size: vwDecimal(24);
            line-height: vwDecimal(33);
            margin-top: vwDecimal(20);
            font-weight: 400; color: #1A2C33; padding: 20px; padding-bottom: 0;
        }
        .content {
            font-weight: 400;
            color: #1A2C33;
            font-size: vwDecimal(18);
            line-height: vwDecimal(25);
            padding: 20px;
        }
        .link {
            position: absolute; bottom: 0; right: 0; padding: 0 vwDecimal(20) vwDecimal(40);
            font-size: vwDecimal(18); line-height: vwDecimal(25);
            color: #00A1E1; cursor: pointer;
        }
    }
}

.videoWrapper {
    position: fixed; z-index: 998; top: 0; right: 0; bottom: 0; left: 0;
    .videoWrapper__mask { position: absolute; width: 100%; height: 100%; top: 0; left: 0; background: #000; opacity: .85; }
    .videoWrapper__btn {
        position: absolute; z-index: 1; display: flex; align-items: center; justify-content: center; border-radius: 50%;
        opacity: .8; cursor: pointer; box-sizing: border-box; user-select: none;
    }
    .close {  top: 10vh; right: 10vw; width: 40px; height: 40px; font-size: 40px; }
    .videoBody { position: relative; max-height: 70vh; width: 70%; margin: 0 auto 50px; box-sizing: border-box; }
    .nativeVideo { width: 100%; height: 100%; object-fit: fill; margin-top: 15vh; }
}

@media screen and (max-width: 414px) {
    @mixin title {
        font-size: px2Mb(38);
        font-weight: 500;
        color: #1A2C33;
        line-height: px2Mb(53);
    }
    @mixin content {
        font-size: px2Mb(24);
        font-weight: 400;
        color: #1A2C33;
        line-height: px2Mb(44)
    }
    @mixin subTitle {
        font-size: px2Mb(28);
        font-weight: 500;
        color: #1A2C33;
        line-height: px2Mb(40);
    }
    @mixin subSubTitle {
        font-size: px2Mb(24);
        font-weight: 500;
        color: #1A2C33;
        line-height: px2Mb(33);
    }

    .btn_group {
        display: flex;
        .btn {
            border-radius: px2Mb(6);
            height: px2Mb(60);
            padding: px2Mb(14) px2Mb(30);
            font-size: px2Mb(24);
            font-weight: 400;
            color: #FFFFFF;
            line-height: px2Mb(33);
            font-weight: 400;
            color: #FFFFFF;
            & + .btn {
                margin-left: px2Mb(60);
            }
        }
    }
    .home {
        padding: px2Mb(60) px2Mb(30);
        overflow: hidden;
    }
    .module { 
        display: flex;
        flex-direction: column;
        font-weight: 500;
        margin: 0 auto;
        width: unset;
        & + .module {
            margin-top: px2Mb(120);
        }
        .module-top {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .title {
                display: flex;
                justify-content: flex-start;
                font-weight: 500;
                @include title;
                & + .content {
                    margin-top: px2Mb(40);
                }
            }
            .content {
                display: flex;
                justify-content: flex-start;
                @include content;
                color: #5E6B70;
            }
            & + .module-card {
                margin-top: px2Mb(60);
            }
        }
    }
    .module-1 {
        width: unset;
        height: unset;
        flex-direction: column; 
        font-weight: 400;
        justify-content: unset;
        align-items: unset;
        .title {
            font-size: px2Mb(40);
            line-height: px2Mb(56);
            width: unset;
            height: unset;
            & + .content { 
                margin-top: px2Mb(40);
            }
        }
        .content {
            width: unset;
            height: unset;
            font-size: px2Mb(28);
            line-height: px2Mb(44);
            color:#00A1E1;
            & + .btn_group { 
                margin-top: px2Mb(60);
            }
        }
        .homeImg { 
            width: 100%;
            margin-top: px2Mb(60);
            object-fit: cover;
        }
    }

    .module-2 {
        height: unset;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .module-2_card {
            display: grid;
            grid-auto-flow: row;
            grid-template-columns: unset;
            grid-template-rows: unset;
            grid-column-gap: unset;
            grid-row-gap: px2Mb(60);
            margin: 0;
            width: unset;
            .card {
                display: flex;
                padding: px2Mb(15);
                background: #FFFFFF;
                box-shadow: 0px 0px px2Mb(24) 0px rgba(0, 161, 225, 0.1);
                /deep/ .card-title {
                    width: px2Mb(165);
                    height: auto;
                    .titleImg {
                        width: px2Mb(165);
                        height: auto;
                    }
                }
                /deep/ .card-content {
                    margin-left: px2Mb(20);
                    padding: px2Mb(38) 0;
                    // padding-top: 38px;
                    // padding-bottom: 38px;
                    .title1 {
                        @include subTitle();
                        & + .content {
                            margin-top: px2Mb(10);
                        }
                    }
                    .content-item_content {
                        @include content();
                    }
                }
            }
        }
    }

    .module-3 {
        height: unset;
        justify-content: center;
        .module-top {
            width: auto;
            text-align: left;
        }
        .module-3_card {
            display: flex;
            flex-direction: column;
            justify-content: unset;
            .card {
                position: relative;
                height: px2Mb(264);
                & + .card {
                    margin-top: px2Mb(60);
                }
                width: unset;
                background: #FFFFFF;
                box-shadow: 0px 0px px2Mb(24) 0px rgba(0, 161, 225, 0.1);
                border-radius: 0;
                /deep/ .card-header {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: px2Mb(253);
                    height: px2Mb(211);
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position-y: px2Mb(32);
                    background-position-x: px2Mb(32);
                    .titleImg {
                        display: none;
                        width: px2Mb(253);
                        height: px2Mb(211);
                        display: none;
                        object-fit: cover;
                    }
                }
                /deep/ .card-content {
                    padding: px2Mb(30) px2Mb(20);
                    .title-small {
                        @include subSubTitle();
                        font-weight: 400;
                        color: #00A1E1;
                        & + .title-normal {
                            margin-top: 0;
                        }
                    }
                    .title-normal {
                        @include subTitle();
                        font-weight: 500;
                        color: #1A2C33;
                    }
                    .content {
                        position: absolute;
                        margin-top: px2Mb(20);
                        @include content();
                        font-weight: 400;
                        color: #5E6B70;
                        text-align: left;
                    }
                }
                /deep/ .divider {
                    text-align: start;
                    &::after {
                        content:'';
                        display: none;
                        // background:#00A1E1;
                    }
                }
            }
            $imgList: 'https://static.rongxintech.cn/oryx/tmp/20210222/16139641955819928.png',
                'https://static.rongxintech.cn/oryx/tmp/20210222/16139641844034756.png',
                'https://static.rongxintech.cn/oryx/tmp/20210222/16139763381099104.png',
                'https://static.rongxintech.cn/oryx/tmp/20210222/16139641896925836.png',
                'https://static.rongxintech.cn/oryx/tmp/20210222/16139641786596216.png';
            @each $img in $imgList {
                $i: index($list: $imgList, $value: $img);
                .card:nth-child(#{$i}) {
                    &:hover /deep/ .card-header {
                        background-image:url(#{$img});
                    }
                }
            }
        }
    }


    .module-5 {
        height: unset;
        .module-5_tabList {
            @include subSubTitle;
            width: 100%;
            font-weight: normal;
            color: #5E6B70;
        }
        .tab { padding-bottom: px2Mb(12); }
        .img {
            width: 100%;
            height: px2Mb(230)!important;
            object-fit: cover;
        }
    }

    .module-6 {
        height: unset;
        justify-content: center;
        margin-bottom: 0;
        .title {
            line-height: vwDecimal(32);
            color: #1A2C33;
            text-align: start;
        }
        .module-6_card {
            position: relative;
            left: px2Mb(-30);
            width: px2Mb(589);
            box-shadow: 0px 0px 16px 0px rgba(0, 161, 225, 0.15);
            padding: px2Mb(60);
            /deep/ .content {
                width: px2Mb(518);
            }
            /deep/ .content-item {
                display: list-item;
                font-size: px2Mb(24);
                font-weight: 400;
                color: #1A2C33;
                line-height: px2Mb(33);
                &::marker { color: #00A1E1; }
                & + .content-item {
                    margin-top: px2Mb(20);
                }
            }
            .img {
                width: px2Mb(720);
                height: px2Mb(352);
                left: px2Mb(40);
                right: unset;
                top:unset;
                bottom: px2Mb(-330);
            }
        }

    }

    .module-7 {
        margin-top: px2Mb(452) !important;
        height: unset; width: unset; padding-bottom: 50px; margin-right: px2Mb(-30);
        .news_item {
            height: px2Mb(872); box-shadow: 0px 0px px2Mb(24) 0px rgba(0, 161, 225, 0.1);
            .titleImg {
                max-height: px2Mb(225);
            }
            .title {
                padding: px2Mb(40); padding-bottom: px2Mb(20);
                font-size: px2Mb(38);
                line-height: px2Mb(53);
            }
            .content {
                padding: px2Mb(40);
                @include subTitle();
                font-weight: normal;
                line-height: px2Mb(44);
            }
            .link {
                padding: px2Mb(45) px2Mb(40); 
                @include subTitle();
                font-weight: normal;
                color: #00A1E1;
            }
        }
    }
    .videoWrapper {
        position: fixed; z-index: 998; top: 0; right: 0; bottom: 0; left: 0;
        .videoWrapper__mask { position: absolute; width: 100%; height: 100%; top: 0; left: 0; background: #000; opacity: .85; }
        .videoWrapper__btn {
            position: absolute; z-index: 1; display: flex; align-items: center; justify-content: center; border-radius: 50%;
            opacity: .8; cursor: pointer; box-sizing: border-box; user-select: none;
        }
        .close { top: 30vh; right: 40px; width: 40px; height: 40px; font-size: 40px; }
        .videoBody { position: relative; max-height: 70vh; width: 70%; margin: 28vh auto 50px; box-sizing: border-box; }
        .nativeVideo { width: 100%; height: 100%; object-fit: fill; margin-top: 11vh; }
    }
}
</style>