<template>
    <div class="apply">
		<div class="advisory">
			<div class="info">
				<h2 v-if="isPc">有信科技</h2>
				<img src="https://static.rongxintech.cn/oryx/tmp/20200611/1591849878955.png" alt="客户经理头像">
				<h3>曾俊雄</h3>
				<p>客户经理</p>
				<div class="QRzone">
					<img src="https://static.rongxintech.cn/oryx/tmp/20200611/1591843510057.png" alt="联系二维码">
					<p v-if="isPc">微信扫码，马上沟通</p>
					<p v-else>长按识别二维码，马上沟通</p>
				</div>
			</div>
			<div class="blueEdition">
				<h2>留下您的信息</h2>
				<div class="title">我们将为您提供免费体验</div>
				<div class="form">
					<input type="text" placeholder="请输入您的姓名" required v-model="form.name">
					<input type="text" placeholder="请输入您的电话" required maxlength="11" v-model.trim="form.mobile">
					<input type="text" placeholder="请输入您的公司名称" required v-model="form.companyName">
					<template v-if="isPc">
						<textarea placeholder="请描述您的需求" v-model="form.description"></textarea>
					</template>
				</div>
				<!-- <div class="captra">
					<div id="verifycode" class="captraBtn"></div>
					<input type="text" v-model="form.captra" placeholder="输入验证码">
				</div> -->
				<button class="m_btn btn primary" @click="submit">提交申请</button>
			</div>
		</div>
    </div>
</template>

<script>
// import GVerify from '../utils/gVerify';
import { Message } from 'element-ui';

export default {
    data(){
        return {
            form : { 
                demand : "",
                description : "",
                mobile : "",
                name : "",
                companyName : "",
                // captra:"",
                timer2: null
            },
            // verifyCode: null
        }
    },
    // mounted() {
    //     this.verifyCode = new GVerify('verifycode')
    // },
    methods:{
        submit(){
            // if (!this.verifyCode.validate(this.form.captra)) {
            //     Message({message: "请填写验证码" ,type:"info"})
            //     return
            // }
            if (this.isPc) {
                // if (!this.form.demand) {
                //     Message({message: "请填写需求" ,type:"info"})
                //     return
                // } else 
				if (!this.form.description) {
                    Message({message: "请填写描述" ,type:"info"})
                    return
                }
            }
            if (!this.form.name) {
                Message({message: "请填写姓名" ,type:"info"})
                return
            } else if (!this.form.mobile) {
                Message({message: "请填写电话" ,type:"info"})
                return
            } else if (!this.form.companyName) {
                Message({message: "请填写公司" ,type:"info"})
                return
			}
			//header 域名 
            this.$ajax("/oryx/applyrecord/add", this.form, {type:"post"}).done(() => {
                Message({message: "提交成功，即将返回上一页" ,type:"success"})
                this.timer2 = setTimeout(() => {
                    this.$router.go(-1)
                }, 1500)
            })
        }
    },
    beforeDestroy() {
        clearTimeout(this.timer2)
        this.timer2 = null
    }
}
</script>

<style lang="scss" scoped>
    // .apply {padding-top: 64px;}
    .apply {
		background: url(https://pro-rx.oss-cn-hangzhou.aliyuncs.com/oryx/tmp/20191012/1570849034076.png) center center no-repeat; 
		padding-top: 56px;
		min-height: 540px;
		background-size: cover; 
	}
	// background: url(https://pro-rx.oss-cn-hangzhou.aliyuncs.com/tanke/apply_bg.jpg) center center no-repeat; }
	
	.advisory {
		width: 910px;
		height: 482px;
		margin: 0 auto;
		text-align: center;
		background-color: #fff;
	}	
	.info, 
	.blueEdition {
		display: inline-block; vertical-align: top; font-size: 0;
	}
	
	.info {
		width: 270px; padding: 20px; position: relative;
		& > img {
			margin-top: 30px;
			width: 68px;
			height: 68px;
			border-radius: 100%;
		}
		h2,
		& > p {
			font-weight: 400;
		}
		& > p { font-size: 12px; color: #666; }
		h2 { font-size: 14px; color: #333; text-align: left; }
		h3 {
			margin-top: 10px;
			font-size: 18px;
			font-weight: 500;
			line-height: 28px;
		}
		.QRzone {
			padding-top: 40px;
			padding-bottom: 20px;
			font-size: 16px; 
			img { 
				width: 160px; 
				margin-bottom: 20px; 
			}
		}
		&:after {
			position: absolute;
			top: 70px;
			right: 0;
			bottom: 22px;
			width: 1px;
			background-color: #e5e5e5;
			content: '';
		}	
	}
	
	.blueEdition {
		padding: 30px 130px; 
		width: 340px;
		h2 { 
			font-size: 18px; 
			text-align: center; 
			font-weight: 500;
			line-height: 32px;
		}
		.title { 
			font-size: 14px; 
			color: #606266; 
			letter-spacing: 1px; 
			text-align: center; 
			padding-bottom: 30px; 
		}
		input, 
		textarea { 
			font-size: 14px;
			width: 100%; 
			height: 40px; 
			padding: 0 10px;
			border: 1px solid #B3B3B3; 
			border-radius: 4px;
			box-sizing: border-box;
			box-shadow: 0px 0px 0px rgba(0,0,0,0); 
			-webkit-appearance: none;
		}
		input { 
			margin-bottom: 20px;
		}
		textarea { 
			height: 80px; 
			resize: none;
			padding: 10px;
		}
		.btn {
			margin-top: 30px;
			width: 100%;
			height: 48px;
			font-size: 20px;
			font-weight: 500;
			line-height: 46px; 
			border-radius: 3px;
			background: #11A2FF; 
			border-color: #11A2FF;
		}
		.captra {
			display: flex; justify-content: space-between;
			input { 
				width: 136px; height: 40px; padding:0 24px; 
				font-size: 14px;
				color: #909399;
				letter-spacing: 0.5px;
				line-height: 18px;
			}
			.captraBtn { 
				width: 186px; height: 40px; padding:0 24px; font-size: 14px; color: #909399; letter-spacing: 0.5px; 
				line-height: 18px; border: 1px solid #DCDFE6; border-radius: 4px; border-radius: 4px; 
				box-sizing: border-box; display: flex; align-items: center; 
			}
		}
	}
	@media screen and (max-width: 414px) {
		.advisory {
			width: 100%;
			height: auto;
		}
			
		.apply {
			padding-top: 0;
			background: none;
		}
		.info,
		.blueEdition {
			display: block;
		}
		.info {
			width: 100%;
			padding: 0 0 10px;
			background: #f5f5f5;
			&:after {
				width: 0;
			}
			h3 {
				font-size: 14px;
			}
			.QRzone {
				padding-top: 30px;
				font-size: 14px; 
				img { 
					// width: 160px; 
					margin-bottom: 10px; 
				}
			}
		}
		.blueEdition {
			margin: 0 auto;
			padding: 30px 0;
			width: 300px;
			
			input {
				margin-bottom: 16px;
			}
			.btn {
				margin-top: 28px;
				height: 45px;
				font-size: 16px;
				font-weight: 500;
				line-height: 43px; 
				border-radius: 4px;
			}
		}
	}
</style>