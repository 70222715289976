<template>
    <div class="module consultativeWrapper" :class="{leaveMessagePage}">
        <div class="left">
            <div class="title">了解更多 </div>
            <div class="title">请致电400-003-1009</div>
            <img src='https://static.rongxintech.cn/yx/liuzi.png' alt="" class="leftImg">
            <div class="subTitle">有信科技，致力于全渠道数字化解决方案</div>
            <div class="content">轻咨询·客制化·快部署·灵实施·云服务</div>
        </div>
        <!-- <div class="divider"></div> -->
        <div class="right">
            <div class="title">{{title}}</div>
            <div class="subTitle">{{subTitle}}</div>
            <el-form :model="form">
                <el-form-item label="">
                    <el-input v-model="form.companyName" placeholder="企业"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-input v-model="form.name" placeholder="姓名"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-input v-model="form.mobile" placeholder="电话"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-input type="textarea" v-model="form.description" placeholder="需求"></el-input>
                </el-form-item>
                <!-- <el-form-item label="">
                    <el-input v-model="form.email" placeholder="邮箱"></el-input>
                </el-form-item> -->
            </el-form>
            <button class="btn" @click="submit">提交表格</button>
        </div>
    </div>
</template>

<script>
import { Message } from 'element-ui';
export default {
    name:'consultative',
    data() {
        return {
            form:{
                demand : "",
                companyName:'',
                name:'',
                mobile:'',
                description:'',
            },
            leaveMessagePage:false,
            title:'专业咨询服务',
            subTitle:'完善信息后，我们的解决方案专家将为你提供专业咨询服务。',
            timer2: null
        }
    },
    created(){
        if (this.$route.path === '/consultative') {
            this.leaveMessagePage = true
        }
    },
    watch:{
        $route:(to) => {
            if (to.path === '/consultative') {
                this.leaveMessagePage = true
                this.title = '专业顾问服务'
                this.subTitle = '完善信息后，你将获得专业全渠道数字化顾问提供的详细解答'
            }
        }
    },
    methods:{
        submit(){
            if (!this.form.name) {
                Message({message: "请填写姓名" ,type:"info"})
                return
            } else if (!this.form.mobile) {
                Message({message: "请填写电话" ,type:"info"})
                return
            } else if (!this.form.companyName) {
                Message({message: "请填写公司" ,type:"info"})
                return
			}
			//header 域名 
            this.$ajax("/oryx/applyrecord/add", this.form, {type:"post"}).done(() => {
                Message({message: "提交成功，即将返回上一页" ,type:"success"})
                this.timer2 = setTimeout(() => {
                    this.$router.go(-1)
                }, 1500)
            })
        }
    },
    beforeDestroy() {
        clearTimeout(this.timer2)
        this.timer2 = null
    }
}
</script>

<style lang="scss" scoped>
$widthList: 200 , 628 , 200, 133 , 145, 212, 179;
$marginRightList: 60, 249, 60, 92, 46, 70, 0;
$areaList: 'product','solution', 'service', 'news', 'us', 'related', 'other', 'officialAccount';
.consultativeWrapper {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    background-color: #fff;
    width: vwDecimal(956);
    box-sizing: border-box;
    padding: vwDecimal(60) vwDecimal(40);
    .left {
        display: flex;
        flex-direction: column;
        .title {
            width: px2Mb(360);
            color: #1A2C33;
            line-height: vwDecimal(50);
        }
        .leftImg {
            width: vwDecimal(311);
            height: vwDecimal(169);
            margin: vwDecimal(40) 0;
        }
        .subTitle {
            color: #666666;
            width: vwDecimal(341);
            font-size: vwDecimal(24);
            line-height: vwDecimal(36);
            margin-bottom: vwDecimal(20);
        }
        .content {
            width: 375px;;
            color: #666666;
            font-size: vwDecimal(16);
            line-height: vwDecimal(32);
        }
    }
    .divider {
        background-color: #EBEEF5;
        width: 1px;
        height: vwDecimal(477);
    }
    .right {
        color: #1A2C33;
        display: flex;
        flex-direction: column;
        .el-radio-group {
            margin-bottom: vwDecimal(20);
            .el-radio /deep/ {
                margin-right: 0;
                .el-radio__label {
                    padding-left: vwDecimal(5);
                    font-size: vwDecimal(16);
                }
                & + .el-radio {
                    margin-left: vwDecimal(20);
                }
            }
        }
        .title {
            height: vwDecimal(33);
            font-size: vwDecimal(24);
            line-height: vwDecimal(33);
        }
        .subTitle {
            font-size: vwDecimal(18);
            line-height: vwDecimal(25);
            margin: vwDecimal(20) 0 vwDecimal(40) 0;
        }
        .btn {
            color: #FFFFFF;
            background: #00A1E1;
            height: vwDecimal(44);
            font-size: vwDecimal(16);
            line-height: vwDecimal(22);
            margin-top: vwDecimal(40);
            border-radius: vwDecimal(4);
            border-width: 0;
        }
    }
}
.leaveMessagePage {
    background-color: #F0F2F5;
    height: vwDecimal(636);
    margin: 0 auto;
    .left{
        font-weight: 400;
        .title {
            width:unset;
            font-size: vwDecimal(38);
            font-weight: 400;
            color: #1A2C33;
            line-height: vwDecimal(53);
        }
        .subTitle {
            width:unset;
            font-size: vwDecimal(24);
            font-weight: 400;
            color: #666666;
            line-height: vwDecimal(32);
        }
        .content {
            color:#00A1E1;
            font-size: vwDecimal(16);
            line-height: vwDecimal(32);
            width: 375px;
        }
    }
    .right{
        width: vwDecimal(358);
        height: vwDecimal(436);
        background: #FFFFFF;
        box-shadow: 0px 0px 24px 0px rgba(0, 161, 225, 0.1);
        padding: vwDecimal(40);
        .el-form-item{
            margin-bottom: 0;
            /deep/ .el-form-item__content {
                line-height: vwDecimal(32);
            }
            /deep/ input{
                height: vwDecimal(32);

                font-size: vwDecimal(16);
            }
            & + .el-form-item {
                margin-top: vwDecimal(15);
            }
        }
        .btn{
            height: vwDecimal(32);
            margin-top: vwDecimal(40);
        }
    }
}
@media screen and (max-width: 414px) {
    .consultativeWrapper {
        padding: px2Mb(60) px2Mb(30);
        width: unset;
        margin-left: 0;
        display: flex;
        justify-content: unset;
        background-color: unset;
        widows: unset;
        height: unset;
        background-color: #fff;
        .left {
            display: none;
            .title {
                width: unset;
                height: unset;
            }
        }
        .divider {
            display: none;
        }
        .right {
            color: #1A2C33;
            display: flex;
            flex-direction: column;
            .title {
                font-size: px2Mb(38);
                font-weight: 400;
                color: #1A2C33;
                line-height: px2Mb(57);
                height: unset;
            }
            .subTitle {
                font-size: px2Mb(24);
                font-weight: 400;
                color: #1A2C33;
                line-height: px2Mb(38);
                margin: px2Mb(20) 0 px2Mb(40) 0;

            }
            .el-form  {
                /deep/ .el-form-item__content {
                    line-height: px2Mb(44);
                }
                /deep/ input{
                    font-size: px2Mb(24);
                    height: px2Mb(44);
                    line-height: px2Mb(38);
                }
                .el-form-item + .el-form-item {
                    margin-top: px2Mb(20);
                }
                .el-form-item {
                    margin-bottom:0;
                }
                .el-input {
                    line-height: px2Mb(38);
                }
            }
            .btn {
                color: #FFFFFF;
                background: #00A1E1;
                border-width: 0;
                height: px2Mb(64);
                font-size: px2Mb(24);
                line-height: px2Mb(33);
                margin-top: px2Mb(40);
                border-radius: px2Mb(6);
            }
        }
    }
    .leaveMessagePage {
        background-color: #F0F2F5;
        height: unset;
        margin: unset;
        display: flex;
        flex-direction: column;
        .left{
            display: block;
            margin: 0 auto;
            margin-top: px2Mb(60);
            font-weight: 400;
            text-align: center;
            .title {
                font-size: px2Mb(38);
                line-height: px2Mb(57);
            }
            .subTitle {
                font-weight: 500;
                color: #666666;
                font-size: px2Mb(28);
                line-height: px2Mb(40);
                width: unset;
            }
            .content {
                color: #00A1E1;
                width: unset;
                font-size: px2Mb(24);
                line-height: px2Mb(38);
            }
        }
        .right{
            order: -1;
            width: unset;
            height: unset;
            box-shadow: unset;
            padding: px2Mb(40);
            .el-form  {
                /deep/ .el-form-item__content {
                    line-height: px2Mb(44);
                }
                /deep/ input{
                    font-size: px2Mb(24);
                    height: px2Mb(44);
                    line-height: px2Mb(38);
                }
                .el-form-item + .el-form-item {
                    margin-top: px2Mb(20);
                }
                .el-form-item {
                    margin-bottom:0;
                }
                .el-input {
                    line-height: px2Mb(38);
                }
            }
            .btn{
                margin-top:px2Mb(40)
            }
        }
    }
}

</style>