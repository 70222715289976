<template>
    <div class="fixed" v-if="isShow">
        <img class="avator" :src="avator" alt="联系人头像">
        <h3>{{ title }}</h3>
		<div class="advisory">
			<img :src="QR" alt="二维码">
			<h3>扫码联系</h3>
			<p>了解更多信息</p>
		</div>
    </div>
</template>

<script>
export default {
    props: {
		
    },
    data() {
        return {
            avator: 'https://static.rongxintech.cn/oryx/tmp/20200611/1591849878955.png',
			QR: 'https://static.rongxintech.cn/oryx/tmp/20200611/1591843510057.png',
			title: '客户经理',
			isShow: true,
        }
    },
	methods: {
		hide() {
			this.isShow = false;
		},
		show() {
			this.isShow = true;
		}
	}
}
</script>

<style lang="scss" scoped>
.fixed {
	position: fixed;
	right: 0px;
	bottom:  200px;
	z-index: 2;
	width: 100px;
	padding: 20px 0;
	text-align: center;
	background: #fff;
	box-shadow:0px 0px 5px 0px rgba(0,0,0,0.1);
	
	.avator {
		margin-bottom: 10px;
		width: 54px;
		height: 54px;
		border-radius: 100%;
	}
	h3 {
		font-weight: 400;
		font-size: 14px;
		color: #333;
		line-height: 20px;
	}	
	.advisory {
		margin-top: 20px;
		p { font-size: 12px; line-height: 20px; }
		img {
			margin-bottom: 10px;
			padding: 5px;
			width: 80px;
			height: 80px;
			box-sizing: border-box;
			border: 1px solid #ebebeb;
			box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
		}
	}
}
@media screen and (max-width: 414px) {
    .fixed { display: none; }
}
</style>

