<template>
    <div class="newsWrapper">
        <div class="page-title mb">新闻动态</div>
        <div class="cardList">
            <card v-for="(item,i) in cardData" :key="i" :titleImg="item.img" :link="item.link">
                <card-content divider :title="item.title" :cardContentData="item.content">
                    <div slot="contentExtraMb" class="date mb">
                        <p>{{item.date}}</p>
                    </div>
                </card-content>
                <!-- <template slot="footer" slot-scope="scope"></template> -->
                <div slot="footer"></div>
            </card>
        </div>
        <div class="page">
            <div class="page-title pc">历史动态</div>
            <div class="page-cards">
                <card v-for="(item,i) in cardData2" :key="i" :titleImg="item.img" :routePos="item.routePos" :link="item.link">
                    <card-content divider :title="item.title" :cardContentData="item.content">
                        <div slot="contentExtra" class="date pc">
                            <p>{{item.date}}</p>
                        </div>
                        <div slot="contentExtraMb" class="date mb">
                            <p>{{item.date}}</p>
                        </div>
                    </card-content>
                </card>
            </div>
            <div class="page-pager"></div>
        </div>
        <div class="more mb" @click="goPage('/consultative')">查看更多</div>
    </div>
</template>

<script>
import Card from '../components/Card.vue'
import CardContent from '../components/CardContent.vue'
export default {
    components:{
        Card,
        CardContent
    },
    data(){
        return {
            cardData:[
                {
                    img:"https://static.rongxin.tech/test/tmp/20210407/16177839269802283.png",
                    title:'立足大湾区数字化，有信科技创始人/CEO林少章接受《21世纪经济报道》专访',
                    content:'近期，大湾区第一大经济类权威媒体《21世纪经济报道》就“全渠道数字化”对有信科技CEO-林少章进行了专访报道。',
                    date:'',
                    link:'https://mp.weixin.qq.com/s/_LM2U6p9SC5doRHiVS3dNg',
                },
                {
                    img:"https://static.rongxin.tech/test/tmp/20210407/16177841077670920.png",
                    title:'有信科技获亿元A+轮融资，客制化云服务应对多变新商业场景',
                    content:'“受疫情影响，企业数字化意识意愿明显提高。”有信科技创始人、CEO林少章对南都记者说，企业数字化是必然，但从现状看，进程才刚开始，过程比较曲折，需要漫长时间，十年甚至更长”。',
                    date:'',
                    link:'https://m.mp.oeeee.com/a/BAAFRD000020210315454164.html'
                },
                {
                    img:"https://static.rongxin.tech/test/tmp/20210407/16177841126686551.png",
                    title:'有信科技创始人林少章：疫情后企业数字化意愿提高 全渠道数字化成创投热土',
                    content:'提供全渠道数字化解决方案的广州有信科技有限公司（以下简称有信科技）近日获得A+轮亿元级人民币融资。该轮融资由金沙江创投和凯泰资本联合投资，这距离上一轮融资仅有7个月。',
                    date:'',
                    link:'https://bm.cnfic.com.cn/sharing/share/articleDetail/2269168/1',
                },
            ],
            cardData2:[
                {
                    img:"https://static.rongxin.tech/test/tmp/20210407/16177847737351675.png",
                    title:'36氪｜「有信科技」获亿元级人民币A+轮融资，由金沙江创投、凯泰资本联合投资',
                    content:'广州有信科技有限公司（下称“有信云”）宣布完成亿元级人民币A+轮融资，由金沙江创投和凯泰资本联合投资。本轮融资将用在加大产研投入，尤其是APaaS平台的研发。今年，有信云将建立起SaaS生态，完善客户服务体系',
                    date:'2021/03/09',
                    link:'https://36kr.com/p/1130333124767752',
                    routePos:'card',
                },
                {
                    img:"https://static.rongxin.tech/test/tmp/20210407/16177847746703154.png",
                    title:'私域流量观察｜金沙江押注全渠道数字化，有信科技再获亿元融资猛攻OCD系统',
                    content:'随着数字化转型投资热度提升，有信开始频频受到投资人关注，并在2020年7月获得金沙江数千万投资。仅隔半年之后的2021年3月初，有信再次宣布获得金沙江创投和凯泰资本的A+轮投资，融资额度亿元级人民币。',
                    date:'2021/03/09',
                    link:'https://mp.weixin.qq.com/s/f3Un_v0lMs1vc6LOLzSfUQ',
                    routePos:'card',
                },
                {
                    img:"https://static.rongxin.tech/test/tmp/20210407/16177847756391417.png",
                    title:'铅笔道｜重磅！8个月再融亿元 前微信企业号技术leader再创业：1年营收翻4倍',
                    content:'近日，有信宣布完成亿元级的A+轮融资，投资方为金沙江创投，凯泰资本。本次融资距上次仅有8个月。',
                    date:'2021/03/09',
                    link:'https://mp.weixin.qq.com/s/lLAeQhstGl-mSBZHE39ZmA',
                    routePos:'card',
                },
                {
                    img:"https://static.rongxin.tech/test/tmp/20210407/16177847760587561.png",
                    title:'见实｜一年帮卖10亿私域GMV的有信，拿到了亿元A+轮融资',
                    content:'资本的嗅觉总是敏锐的，有信年均GMV已经超过10亿，今年到现在已有超过5个亿的GMV。有信目前合作的品牌大多是亿级体量的大企业。',
                    date:'2021/03/09',
                    link:'https://mp.weixin.qq.com/s/tunUFLnmkE21llLnt8bdqw',
                    routePos:'card',
                },
                {
                    img:"https://static.rongxintech.cn/oryx/tmp/20210223/16140750034625870.jpg",
                    title:'数字革命如何重构商业未来？终于有人在千人大会上说出真相',
                    content:'2020年12月2日，第五届中国新媒体千人峰会暨第二届弯弓MarTech生态展在越秀国际会议中心隆重举行。峰会以“新纪元：内容革命和数字私域未来”为主题，梳理私域流量的底层逻辑，聚焦“数字化”重构商业未来。',
                    date:'2020/12/04',
                    link:'https://mp.weixin.qq.com/s/_PsByxRYUG37W0mRQEgJug',
                    routePos:'card',
                },
                {
                    img:"https://static.rongxintech.cn/oryx/tmp/20210223/16140750116022065.jpg",
                    title:'BBC才是私域流量的终极形式',
                    content:'2020年10月23日，在深圳私域流量行业大会上，有信科技的联合创始人兼COO孙晋侯受邀参加，他分享的一个想法引发了很多现场嘉宾的强烈共鸣。',
                    date:'2020/12/26',
                    link:'https://mp.weixin.qq.com/s/AD1gMuqtRNDmPHw9VWHK1A',
                    routePos:'card',
                },
                {
                    img:"https://static.rongxintech.cn/oryx/tmp/20210223/16140750193307210.jpg",
                    title:'有信科技荣获“2020中国私域行业年度大奖”',
                    content:'2020年10月23日，由见实科技、运营深度精选、弯弓Digital（私域流量观察）首度联手打造的“2020中国私域流量行业大会”在深圳凯宾斯基酒店举行。有信荣获“2020中国私域行业年度大奖——十大私域流量服务商”。',
                    date:'2020/10/24',
                    link:'https://mp.weixin.qq.com/s/MhXjIo--kTG07_2OKD3Vcw',
                    routePos:'card',
                },
            ],
        }
    }
}
</script>

<style lang="scss" scoped>

.newsWrapper {
    margin-left: vwDecimal(244);
    position: relative;
    top:vwDecimal(-60);
    .mb {
        display: none;
    }
}
.cardList {
    display: grid;
    grid-template-columns: repeat(3, vwDecimal(306));
    grid-column-gap: vwDecimal(24);
    .card /deep/ {
        height: vwDecimal(650);
        padding: vwDecimal(20);
        background: #FFFFFF;
        box-shadow: 0px 0px vwDecimal(24) 0px rgba(0, 161, 225, 0.1);
        border-radius: vwDecimal(2);
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
        .card-title {
            .titleImg {
                width: vwDecimal(270);
                height: vwDecimal(179);
            }
            & + .card-content {
                margin-top: vwDecimal(20);
            }
        }

        .card-content {
            flex: 1;
            .title1 {
                font-size: 24px;
                line-height: vwDecimal(33);
                font-weight: 400;
                color: #1A2C33;
            }
            .content {
                font-size: 16px;
                line-height: vwDecimal(32);
                font-weight: 400;
                color: #5E6B70;
            }
        }
        .footerLink {
            font-size: 16px;
            line-height: vwDecimal(22);
            font-weight: 400;
            color: #00A1E1;
        }
        .divider {
            text-align: left;
            &::after {
                content:'';
                display: block;
                width: 44px;
                height: 2px;
                background:#00A1E1;
                margin: vwDecimal(20) auto;
                margin-left: 0;
            }
        }
    }
}
.page {
    .page-title {
        font-size: 38px;
        font-weight: 400;
        color: #1A2C33;
        font-size: vwDecimal(38);
        line-height: vwDecimal(53);
        margin-top: vwDecimal(120);
        margin-bottom: vwDecimal(40);
    }
    .page-cards {
        display: grid;
        grid-row-gap: vwDecimal(40);
        .card /deep/{
            cursor: pointer;
            display: flex;
            width: vwDecimal(966);
            padding: vwDecimal(16);
            background: #FFFFFF;
            .card-title  .titleImg {
                width: vwDecimal(208);
                height: vwDecimal(208);
                object-fit: cover;
            }
            .card-content {
                display: flex;
                flex-direction: column;
                margin-left: vwDecimal(20);
                .title1 {
                    font-weight: 400;
                    color: #1A2C33;
                    font-size: vwDecimal(24);
                    line-height: vwDecimal(33);
                    margin-top: vwDecimal(20);
                    margin-bottom: vwDecimal(16);
                }
                .content  {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    font-size: vwDecimal(16);
                    line-height: vwDecimal(32);
                    font-weight: 400;
                    color: #5E6B70;
                }
                .date {
                    text-align: end;
                }
            }
        }
    }
}
@media screen and (max-width: 414px) {
    
    @mixin cardTitle {
        font-size: px2Mb(28);
        font-weight: 500;
        color: #1A2C33;
        line-height: px2Mb(40);
    }
    @mixin cardContent {
        font-size: px2Mb(24);
        font-weight: 400;
        color: #1A2C33;
        line-height: px2Mb(38);
    }
    @mixin cardFooterLink {
        font-size: px2Mb(20);
        font-weight: 400;
        color: #00A1E1;
        line-height: px2Mb(28);
    }
    .newsWrapper {
        top:0;
        padding: px2Mb(60) px2Mb(30);
        margin-left: 0;
        .mb {
            display: block;
        }
        .pc {
            display: none;
        }
        .page-title {
            font-size: px2Mb(38);
            font-weight: 400;
            color: #1A2C33;
            line-height: px2Mb(57);
            margin-top: 0;
            margin-bottom: px2Mb(40);
        }
    }
    .cardList {
        display: grid;
        grid-template-columns: unset;
        grid-auto-flow: row;
        grid-column-gap:0;
        /deep/ .title1 {
            font-size: px2Mb(28);
                    font-weight: 500;
                    color: #1A2C33;
                    line-height: px2Mb(40);
        }
        .card {
            height: unset;
            padding: 0;
            background: #FFFFFF;
            box-shadow: unset;
            border-radius: 0;
            & + .card {
                margin-top: px2Mb(40);
            }
            & /deep/ {
                .card-title {
                    .titleImg {
                        height: px2Mb(230);
                        width: 100%;
                        object-fit: cover;
                    }
                    & + .card-content {
                        margin-top: 0;
                    }
                }
                .card-content {
                    flex: 1;
                    padding: px2Mb(40);
                    display: flex;
                    flex-direction: column;
                    .date {
                        order: -1;
                        display: flex;
                        font-size: px2Mb(20);
                        font-weight: 400;
                        color: #00A1E1;
                        line-height: px2Mb(28);
                        // @include cardFooterLink;
                        margin-bottom: px2Mb(10);
                    }
                    .title1 {
                        font-size: px2Mb(28);
                        font-weight: 500;
                        color: #1A2C33;
                        line-height: px2Mb(40);
                        // @include cardTitle;
                        & + .content {
                            margin-top: px2Mb(17);
                        }
                    }
                    .content {
                        font-size: px2Mb(24);
                        font-weight: 400;
                        color: #1A2C33;
                        line-height: px2Mb(38);
                        // @include cardContent;
                    }
                }
                .card-footer {
                    display: none;
                }
                /deep/ .divider {
                    &::after {
                        content:'';
                        display: none;
                    }
                }
            }
        }
    }
    .page {
        margin-top: px2Mb(40);
        .page-cards {
            display: grid;
            grid-row-gap:px2Mb(40);
            .card /deep/{
                flex-direction: column;
                width: unset;
                padding: 0;
                background: #FFFFFF;
                .card-title  .titleImg {
                    height: px2Mb(230);
                    width: 100%;
                }
                .card-content {
                    display: flex;
                    flex-direction: column;
                    margin-left: 0;
                    padding: px2Mb(40);
                    .title1 {
                        @include cardTitle;
                        margin-top: 0;
                        margin-bottom: px2Mb(17);
                    }
                    .content  {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        @include cardContent;
                    }
                    .date {
                        text-align: start;
                        order: -1;
                        @include cardFooterLink;
                        margin-bottom: px2Mb(10);
                    }
                }
            }
        }
    }
    .more {
        margin: px2Mb(60) auto;
        width: px2Mb(284);
        height: px2Mb(70);
        background: #0099FF;
        border-radius: px2Mb(6);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: px2Mb(24);
        font-weight: 400;
        color: #FFFFFF;
        line-height: px2Mb(38);
        cursor: pointer;
        text-align: center;
        padding: px2Mb(16) 0;
        box-sizing: border-box;
    }
}
</style>