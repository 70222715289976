<template>
    <div class="footerWrapper">
        <div class="footer-related" v-if="related">
            <div class="title">立即开始数字化进程</div>
            <button class="btn" @click="goPage('/consultative')">联系我们</button>
            <!-- <img src="https://static.rongxintech.cn/oryx/tmp/20210225/16142382518605387.png" alt="related"> -->
        </div>
        <div class="footer-cardList">
            <div class="cardList">
                <card v-for="(item,i) in bottomData" :key="i" :id="i">
                    <div slot="header" class="title">
                        <div class="title-small">{{item.title}}</div>
                    </div>
                    <card-content btn divider :cardContentData="item.children" >
                        <template slot="contentExtra">
                            <img :src="item.img" alt="" v-if="item.img" class="wxPublic">
                        </template>
                    </card-content>
                </card>
            </div>
            <div class="right">
                <div class="logo" >
                    <img src="https://static.rongxintech.cn/oryx/tmp/20210309/16152566009696694.png" alt=""  class="pc">
                </div>

                <div class="imgList">
                    <div class="imgInfo">
                        <img src="https://static.rongxintech.cn/oryx/tmp/20210309/16152819549366662.png" alt="">
                        <div class="info">关注公众号</div>
                    </div>
                    <div class="imgInfo">
                        <img src="https://dev-rx.oss-cn-hangzhou.aliyuncs.com/test/tmp/20210426/16194206585592243.png" alt="">
                        <div class="info">在线咨询</div>
                    </div>
                </div>
                <div class="tel">TEL:400-003-1009 </div>
                <div class="email">E-mail：marketing@youxin.cloud</div>
                <div class="bottom">
                    <div class="link">
                        友情链接：
                        <span @click="goPage('https://xiaokefu.com.cn/')">芝麻小客服</span> 
                        &nbsp;
                        <span @click="goPage('https://www.bangongyi.com/')">办公易</span>
                    </div>
                    <!-- <div class="copyRight">Copyright 2018-2021 youxin.cloud All Rights Reserved</div> -->
                    <div class="companyName">
                        广州有信科技有限公司
                    </div>
                    <div class="copyRight">
                        Copyright 2018-2021
                    </div>
                    <div class="copyRight">
                        youxin.cloud All Rights Reserved
                    </div>
                    <a class="copyRight aLink" @click="goPage('https://beian.miit.gov.cn')">粤ICP备18029216号</a>
                </div>
            </div>
        </div>
        <div class="footer-legal mb">
            <div class="companyName">广州有信科技有限公司</div>
            <div class="copyRight">Copyright 2018-2021 youxin.cloud All Rights Reserved</div>
            <!-- <div class="copyRight">粤ICP备18029216号-6</div> -->
            <a class="copyRight aLink" @click="goPage('https://beian.miit.gov.cn')">粤ICP备18029216号</a>
            <div class="link">
                友情链接：
                <span @click="goPage('https://xiaokefu.com.cn/')">芝麻小客服</span> 
                &nbsp;
                <span @click="goPage('https://www.bangongyi.com/')">办公易</span>
            </div>
            <div class="toTop" @click="goToTop"></div>
        </div>
        
    </div>
</template>

<script>
import Card from '../components/Card.vue'
import CardContent from '../components/CardContent.vue'
export default {
    props:{
        related:{
            type:Boolean,
            default:true,
        }
    },
    components:{
        CardContent,
        Card
    },
    data(){
        return {
            bottomData:[
                {
                    title:'产品',
                    children:[
                        {   
                            title:'按全渠道场景分',
                            children:[
                                {   content:'X·OCD-全渠道营销',route:'/product/frame2/marketing'    },
                                {   content:'X·OCD-全渠道商品',route:'/product/frame2/goods'    },
                                {   content:'X·OCD-全渠道订单',route:'/product/frame2/order'    },
                                {   content:'X·OCD-全渠道结算',route:'/product/frame2/settleAccount'    },
                                {   content:'X·OCD-全渠道客户',route:'/product/frame2/custom'    },
                            ],
                        },
                        {   
                            title:'按渠道性质分',
                            children:[
                                {   content:'X·OCD-经销渠道',route:'/product/frame1/selldistribute'    },
                                {   content:'X·OCD-门店渠道',route:'/product/frame1/retail'    },
                                {   content:'X·OCD-社交渠道',route:'/product/frame1/distribution'    },
                                {   content:'X·OCD-公域私域',route:'/product/frame1/fields'    },
                            ],
                        },
                    ]
                },
                {
                    title:'解决方案',
                    children:[
                        {
                            id:31,
                            title:'按行业',
                            children:[
                                {   content:'美妆行业解决方案',
                                    route:'/solution/industry/makeUp',
                                },
                                {   content:'日化行业解决方案',
                                    route:'/solution/industry/dailyUse',
                                },
                                {   content:'大健康行业解决方案',
                                    route:'/solution/industry/health',
                                },
                                {   content:'酒水行业解决方案',
                                    route:'/solution/industry/alcohol',
                                }
                            ]
                        },
                        {
                            id:32,
                            title:'按渠道',
                            children:[
                                {   content:'单渠道解决方案',
                                    route:'/solution/channel/single',
                                },
                                {   content:'多渠道解决方案',
                                    route:'/solution/channel/multiple',
                                },
                                {   content:'全渠道解决方案',
                                    route:'/solution/channel/all',
                                },
                            ]
                        },
                        {
                            id:33,
                            title:'按商业应用场景',
                            children:[
                                {   content:'直播',
                                    route:'/solution/scene/scene',
                                    hash:'#live',
                                },
                                {   content:'物流',
                                    route:'/solution/scene/scene',
                                    hash:'#logistics'
                                },
                                {   content:'营销',
                                    route:'/solution/scene/scene',
                                    hash:'#marketing',
                                },
                                {   content:'支付',
                                    route:'/solution/scene/scene',
                                    hash:'#pay',
                                },
                                {   content:'客服',
                                    route:'/solution/scene/scene',
                                    hash:'#customerService'
                                },
                                {   content:'社群',
                                    route:'/solution/scene/scene',
                                    hash:'#association',
                                },
                                {   content:'税务',
                                    route:'/solution/scene/scene',
                                    hash:'#taxation'
                                },
                                {   content:'仓库',
                                    route:'/solution/scene/scene',
                                    hash:'#storehouse'
                                },
                                {   content:'发票',
                                    route:'/solution/scene/scene',
                                    hash:'#bill'
                                },
                                {   content:'追踪',
                                    route:'/solution/scene/scene',
                                    hash:'#origin',
                                },
                            ]
                        }
                    ]
                },
                {   
                    title:'服务与支持',
                    children:[
                        {   content:'客制化', route:'/service',hash:'#custom'  },
                        {   content:'长期主义', route:'/service',hash:'#longterm'    },
                        {   content:'客户成功', route:'/service',hash:'#schematization'    },
                    ]
                },
                {   
                    title:'新闻动态',
                    children:[
                        {   content:'动态资讯', route:'/news',    },
                    ]
                },
                {   
                    title:'关于我们',
                    children:[
                        {   content:'关于我们', route:'/team',    },
                        {   content:'有信团队', route:'/us',    },
                        {   content:'加入我们' ,route:'https://www.lagou.com/gongsi/474323.html',   },
                    ]
                },
                {   
                    title:'联系我们',
                    children:[
                        {   content:'咨询热线：400-003-1009', route:'/consultative',  },
                        {   content:'免费咨询 >', route:'/consultative', },
                    ]
                },
                // {   
                //     title:'公众号',
                //     children:[
                        
                //     ],
                //     img:'https://static.rongxintech.cn/oryx/tmp/20210305/16149522217375313.jpg'
                // },
            ]
        }
    },
    methods:{
        goToTop(){
            document.body.scrollTop = document.documentElement.scrollTop=0
        }
    }
}
</script>

<style lang="scss" scope>
.footerWrapper {
    position: relative;
    // width: 100vw;
    // top: 0;
    // right: 0;
    // left: 50%;
    // transform: translateX(-50vw);
    // background-color: #fff;
}
.footer-related {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    height: vwDecimal(324);
    background-image: url('https://static.rongxintech.cn/oryx/tmp/20210304/16148532843067620.png');
    background-size: vwDecimal(615) vwDecimal(257);
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: vwDecimal(66);
    background-color: #ffffff;
    .title {
        margin-top: vwDecimal(22) 0 vwDecimal(32) 0;
        // height: vwDecimal(100);
        font-size: vwDecimal(72);
        line-height: vwDecimal(100);
        color: #1A2C33;
    }
    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: vwDecimal(184);
        height: vwDecimal(40);
        border-radius: vwDecimal(6);
        background: linear-gradient(270deg, #3BCDFF 0%, #5794FF 100%);
        color: #FFFFFF;
        font-size: vwDecimal(16);
        line-height: vwDecimal(22);
        border-width: 0;
    }
    img {
        height: vwDecimal(258);
        object-fit: cover;
    }
}
.email { font-weight: bold; margin-top: 10px;}
.footer-cardList {
    width: 1200px;
    height: 580px;
    box-sizing: border-box;
    padding: 60px 0;
    display: flex;
    margin: 0 auto;
    .cardList {
        width: vwDecimal(920);
        display: flex;
        flex-wrap: wrap;
    }
    .card {
        margin-right: vwDecimal(60);
        margin-bottom: vwDecimal(60);
        .title{
            font-size: vwDecimal(18);
            font-weight: 500;
            color: #1A2C33;
            line-height: vwDecimal(25);
            color: #040921;
            text-shadow: 0px -6px 24px rgba(88, 208, 255, 0.1);
        }
        .card-header + .card-content {
            margin-top: 20px;
        }
        .card-content .content {
            .content-item {
                & + .content-item {
                    margin-top: vwDecimal(10);
                }
                .content-item_title {
                    font-size: vwDecimal(14);
                    color: #1A2C33;
                    line-height: vwDecimal(20);
                    text-shadow: 0px -6px 24px rgba(88, 208, 255, 0.1);
                    cursor: pointer;
                    & + .childrernWrapper {
                        margin-top: vwDecimal(10);
                    }
                }
                .content-item_content, .content-item_children_content {
                    line-height: vwDecimal(20);
                    font-size: vwDecimal(14);
                    color: #9A9A9A;
                    font-weight: 400;
                    cursor: pointer;
                    &:hover {
                        font-weight: bold;
                        color: #00A1E1;
                        line-height: vwDecimal(20);
                        font-size: vwDecimal(14);
                    }
                }
                .content-item_children {
                    margin-bottom: vwDecimal(10);
                    .content-item_children_title {
                        font-size: vwDecimal(14);
                        font-weight: 400;
                        color: #1A2C33;
                        line-height: vwDecimal(20);
                        text-shadow: 0px -6px 24px rgba(88, 208, 255, 0.1);
                        cursor: pointer;
                    }
                }
            }
        }
        &:nth-child(-n+2)  {
            .card-content .content {
                display: flex;
                .content-item {
                    & + .content-item {
                        margin-top: 0;
                    }
                    .content-item_title {
                        font-weight: 500;
                        cursor: unset;
                    }
                    & + .content-item {
                        margin-left: vwDecimal(40);
                    }
                }
            }
        }
        &:nth-child(2)  {
            margin-right: 0;
            .card-content .content .content-item:last-child .childrernWrapper {
                display: flex;
                width: vwDecimal(172);
                flex-wrap: wrap;
                .content-item_children {
                    margin-right: vwDecimal(20);
                }
                .content-item_children:nth-child(4n) {
                    margin-right: 0;
                }
            }
        }
    }
    .right {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: #9A9A9A;
        line-height: 20px;
        text-shadow: 0px -6px 24px rgba(88, 208, 255, 0.1);
        position: relative;
        &::after {
            content: '';
            display: block;
            width: 1px;
            height: 460px;
            background-color: #DADADA;
            position: absolute;
            left: -50px;
        }
        .logo {
            width: 208px;
            height: 66px;
        }
        .imgList {
            display: flex;
        }
        .imgInfo {
            width: 82px;
            text-align: justify;
            margin: 40px 0;
            & + .imgInfo { margin-left: 46px; }
            img { width: 82px; height: 82px; object-fit: cover; }
        }
        .tel { font-weight: bold; }
        .bottom {
            flex: 1;
                justify-content: flex-end;
    display: flex;
    flex-direction: column;
            .link + .copyRight { margin-top: 10px; }
            .companyName {
                margin-top: 20px;
            }
            .copyRight { 
                display: block; 
                &.aLink { cursor: pointer; }
            }
        }
    }
    .wxPublic {
        width: vwDecimal(102);
        height: vwDecimal(102);
    }
}
.footer-legal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: vwDecimal(12);
    height: vwDecimal(147);
    color: #fff;
    background: #00A1E1;
    box-shadow: 0px -2px 12px 0px rgba(0, 161, 225, 0.1);
    .link {
        height: vwDecimal(16);
        margin-top: vwDecimal(16);
        span {
            cursor: pointer;
        }
    }
}
@media screen and (max-width: 414px) {
    .footer-related {
        height: px2Mb(323);
        background-size: px2Mb(615) px2Mb(257);
        background-position-y: px2Mb(48);
        .title {
            font-size: px2Mb(72);
            font-weight: 400;
            color: #1A2C33;
            line-height: px2Mb(100);
        }
        .btn {
            font-size: px2Mb(16);
            font-weight: 400;
            color: #FFFFFF;
            line-height: px2Mb(22);
            width: px2Mb(184);
            height: px2Mb(40);
            background: linear-gradient(270deg, #3BCDFF 0%, #5794FF 100%);
            border-radius: px2Mb(6);
            margin-top: px2Mb(32);
        }
    }
    .footer-cardList {
        display: none;
    }
    .footer-legal {
        height: px2Mb(229);
        font-size: px2Mb(24);
        font-weight: 400;
        color: #FFFFFF;
        line-height: px2Mb(33);
        padding: px2Mb(40) 0;
        box-sizing: border-box;
        position: relative;
            text-align: center;
        .toTop {
            position: absolute;
            right: px2Mb(30);
            display: block;
            background-image: url('https://static.rongxintech.cn/oryx/tmp/20210302/16146564311517982.png');
            top: px2Mb(-58);
            width: px2Mb(98);
            height: px2Mb(82);
            background-size: contain;
        }
    }
    .link {
        margin-top: px2Mb(20);
    }
}
// .pc {
//     .cardList {
//         padding: 0 35px;
//         box-sizing: border-box;
//     }
// }
</style>